export const TELEGRAM = "https://t.me/go2nft_news";
export const INSTAGRAM = "https://www.instagram.com/go2nft/";
export const FACEBOOK = "https://www.facebook.com/Go2NFT";
export const YOUTUBE = "https://www.youtube.com/channel/UCcyUw8o0JQZmNL8M1GK3iuA";
export const MEDIUM = "https://medium.com/@go2nft";
export const TWITTER = "https://twitter.com/go2nft_official";

export const TELEGRAM_IMG = "/images/socials/telegram.svg";
export const INSTAGRAM_IMG = "/images/socials/instagram.svg";
export const FACEBOOK_IMG = "/images/socials/facebook.svg";
export const YOUTUBE_IMG = "/images/socials/youtube.svg";
export const MEDIUM_IMG = "/images/socials/medium.svg";
export const TWITTER_IMG = "/images/socials/twitter.svg";

export const socialButtons = [
  {
    url: TELEGRAM,
    img: TELEGRAM_IMG,
    name: "Telegram"
  },
  {
    url: INSTAGRAM,
    img: INSTAGRAM_IMG,
    name: "Instagram"
  },
  {
    url: FACEBOOK,
    img: FACEBOOK_IMG,
    name: "Facebook"
  },
  {
    url: YOUTUBE,
    img: YOUTUBE_IMG,
    name: "Youtube"
  },
  {
    url: MEDIUM,
    img: MEDIUM_IMG,
    name: "Medium"
  },
  {
    url: TWITTER,
    img: TWITTER_IMG,
    name: "Twitter"
  }
];
