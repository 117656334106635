import React from "react";

import { paths } from "@app/paths";
import { IChildren } from "@components/interfaces/IChildren";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import { clearLocalStorageItems, readLocalStorage, writeLocalStorage } from "@utils/storage";

interface IInitialConfigContext {
  defaultMainPath: string;
  entryAppPath: string;
}

const InitialConfigContext = React.createContext(null as any);

const INITIAL_PATH_USER_WANTED_TO_VISIT = "initial_path_user_wanted_to_visit";

export const InitialConfigProvider = ({ children }: IChildren) => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const { authState } = useAuth();

  const defaultMainPath = paths.dashboard;

  const initialPathUserWantedToVisit = JSON.parse(readLocalStorage(INITIAL_PATH_USER_WANTED_TO_VISIT) || "null");
  const entryAppPath = initialPathUserWantedToVisit ?? defaultMainPath;

  const saveInitialPath = (path: string) => {
    writeLocalStorage(INITIAL_PATH_USER_WANTED_TO_VISIT, path);
  };

  const savablePaths = [
    paths.profile,
    paths.order,
    paths.orderGetStarted,
    paths.orderHistory,
    paths.contact,
    paths.contact,
    paths.help,
    paths.generatorCreateCollectionView,
    paths.generatorEditCollectionView,
    paths.generator
  ] as string[]; //TODO - to extend

  React.useEffect(() => {
    if (authState === "loggedIn") {
      if (entryAppPath === defaultMainPath) return;

      navigate(entryAppPath);
      clearLocalStorageItems([INITIAL_PATH_USER_WANTED_TO_VISIT]);
    }
  }, [authState]);

  React.useEffect(() => {
    if (savablePaths.includes(pathname)) {
      saveInitialPath(pathname + search);
    }
  }, []);

  return (
    <InitialConfigContext.Provider
      value={{
        defaultMainPath,
        entryAppPath
      }}
    >
      {children}
    </InitialConfigContext.Provider>
  );
};

export const useInitialConfig = (): IInitialConfigContext => React.useContext(InitialConfigContext);
