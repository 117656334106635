import React from "react";

import { IChildren } from "@components/interfaces/IChildren";
import usePaymentMethod, { IPayment } from "@hooks/usePaymentMethod";

interface IDeliveryAndPaymentOptions {
  isValid: boolean;
  reset: () => void;
  payment: IPayment;
}

const PaymentOptionsContext = React.createContext(null as any);

export const PaymentOptionsProvider = ({ children }: IChildren) => {
  const {
    paymentMethods,
    updatePaymentMethod,
    paymentMethodError,
    toShowPaymentError,
    resetPaymentMethod,
    validatePaymentMethod,
    isPaymentMethodValid,
    pickedPaymentMethod
  } = usePaymentMethod();

  return (
    <PaymentOptionsContext.Provider
      value={{
        isValid: isPaymentMethodValid,
        reset: resetPaymentMethod,
        payment: {
          paymentMethods,
          updatePaymentMethod,
          paymentMethodError,
          toShowPaymentError,
          resetPaymentMethod,
          validatePaymentMethod,
          pickedPaymentMethod
        }
      }}
    >
      {children}
    </PaymentOptionsContext.Provider>
  );
};

export const usePaymentOptions = (): IDeliveryAndPaymentOptions => React.useContext(PaymentOptionsContext);
