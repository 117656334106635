import React from "react";

import { IChildren } from "@components/interfaces/IChildren";
import useRequest from "@hooks/useRequest";
import { UpdateUserDTO } from "@api/hubApi/dtos.types";
import { useCompanyProfile } from "../CompanyProfileProvider";
import { useTranslation } from "react-i18next";
import { updateUser } from "@api/hubApi/methods";
import { useSetUserDetails } from "./SetUserDetails.context";

interface IUpdateUserDetailsContext {
  triggerUpdateUserDetails: () => void;
  updateUserDetails: () => Promise<void>;
}

const UpdateUserDetailsContext = React.createContext(null as any);

export const UpdateUserDetailsProvider = ({ children }: IChildren) => {
  const { t } = useTranslation("profile");
  const { shippingDataForm, inputChanged } = useSetUserDetails();
  const { fullName, phoneNumber } = shippingDataForm.fields;

  const { runToastOnError, runToastOnStart, runToastOnSuccess } = useCompanyProfile();

  const {} = useSetUserDetails();

  const { requestTrigger: triggerUpdateUserDetails } = useRequest<UpdateUserDTO>({
    beforeStart: () => {
      runToastOnStart(t("userProfile.request.loading"));
      inputChanged.disableButton();
    },
    requestFn: () => updateUser({ fullName: fullName.value, phoneNumber: phoneNumber.value }),
    onSuccessFn: () => runToastOnSuccess(t("userProfile.request.success")),
    onError: () => runToastOnError(t("userProfile.request.error")),
    onFinally: inputChanged.resetButton
  });

  const updateUserDetails = async () => {
    shippingDataForm.validate();
    if (!shippingDataForm.isValid || (!fullName.value && !phoneNumber.value)) return;

    await triggerUpdateUserDetails();
  };

  return (
    <UpdateUserDetailsContext.Provider value={{ triggerUpdateUserDetails, updateUserDetails }}>
      {children}
    </UpdateUserDetailsContext.Provider>
  );
};

export const useUpdateUserDetails = (): IUpdateUserDetailsContext => React.useContext(UpdateUserDetailsContext);
