import { paths } from "@app/paths";

export default {
  path: paths.orderHistory,
  children: [],
  lazy: async () => {
    const { OrderHistory } = await import("./OrderHistory");

    return {
      element: <OrderHistory />
    };
  }
};
