import React from "react";

import { IChildren } from "@components/interfaces/IChildren";
import { useGetCompanyDetails } from "../CompanyDetailsContext/GetCompanyDetails.context";
import { useSetPartnerDetails } from "./SetPartnerDetails.context";

interface IEffectsPartnerDetailsContext {}

const EffectsPartnerDetailsContext = React.createContext(null as any);

export const EffectsPartnerDetailsProvider = ({ children }: IChildren) => {
  const { companyUserResponse } = useGetCompanyDetails();
  const { partnerForm, uploadPartnerTags, handleDefaultIsPublic, setPublic } = useSetPartnerDetails();

  React.useEffect(() => {
    if (!companyUserResponse) return;

    const { partner } = companyUserResponse.client;

    const { email, url, description } = partnerForm.fields;

    email.update(partner?.email ?? "");
    url.update(partner?.url ?? "");
    description.update(partner?.description ?? "");
    uploadPartnerTags(partner?.tags ?? []);

    handleDefaultIsPublic(partner?.verificationStatus || null);
    if (partner?.visible) setPublic();
  }, [companyUserResponse]);

  return <EffectsPartnerDetailsContext.Provider value={{}}>{children}</EffectsPartnerDetailsContext.Provider>;
};

export const useEffectsPartnerDetails = (): IEffectsPartnerDetailsContext =>
  React.useContext(EffectsPartnerDetailsContext);
