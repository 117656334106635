export const pathsAuth = "auth";
export const pathsOrder = "order-tags";
export const pathsGenerator = "collections";
export const pathsHelp = "help";

export const pathsHelpSlug = "question";
export const collectionIdSlug = "collectionId";
export const productIdSlug = "productId";
export const orderHistoryRowSearchParam = "rowId";

export const collectionsViewExtensions = "all";

export const generatorEditBasePath = `${pathsGenerator}/edit`;
export const generatorCreateBasePath = `${pathsGenerator}/create`;

export const paths = Object.freeze({
  app: "/",
  auth: `/${pathsAuth}`,
  authLogin: `/${pathsAuth}/login`,
  authRegister: `/${pathsAuth}/register`,
  authRecover: `/${pathsAuth}/recover`,
  authRecoverConfirm: `/${pathsAuth}/recover-confirm`,
  authRegisterConfirm: `/${pathsAuth}/register-confirm`,
  authAction: `/${pathsAuth}/action`,
  dashboard: "/dashboard",
  profile: "/profile-settings",
  order: `/${pathsOrder}`,
  orderGetStarted: `/${pathsOrder}/get-started`,
  orderCategory: `/${pathsOrder}/category`,
  orderNfc: `/${pathsOrder}/nfc`,
  orderPaymentDelivery: `/${pathsOrder}/payment-and-delivery`,
  orderSummary: `/${pathsOrder}/summary`,
  orderCongratulations: `/${pathsOrder}/congratulations`,
  orderHistory: `/order-history`,
  contact: "/contact",
  help: `/${pathsHelp}`,
  helpQuestionSlug: `/${pathsHelp}/:${pathsHelpSlug}`,
  generator: `/${pathsGenerator}`,
  generatorCollectionsView: `/${pathsGenerator}/${collectionsViewExtensions}`,
  generatorEditCollectionView: `/${generatorEditBasePath}/:${collectionIdSlug}`,
  generatorCreateCollectionView: `/${generatorCreateBasePath}`,
  notFound: "/*"
});
