import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { pathsHelpSlug } from "@app/paths";
import { faqQuestions } from "@constants/help/faqQuestions";

import { AccordionActiveState } from "../Accordion/Accordion.types";
import Accordion from "../Accordion/Accordion";

import "./Faq.scss";

const Faq = () => {
  const { t } = useTranslation("help");

  const questionSlug = useParams();
  const questionSlugValue = questionSlug[`${pathsHelpSlug}`];

  const withInitialOpen = faqQuestions.map((question) => ({
    ...question,
    initialVariant: (question.id === questionSlugValue ? "active" : "default") as AccordionActiveState
  }));

  return (
    <div className="help-faq">
      <h2 className="regular title mb-xs-5">{t("faq.title")}</h2>
      <Accordion questions={withInitialOpen} className="faq-accordion pt-xs-1 pb-xs-1" />
    </div>
  );
};

export default Faq;
