import React, { useState } from "react";
import { User } from "firebase/auth";

import { IChildren } from "@components/interfaces/IChildren";

interface IUserContext {
  user: User | null;
  handleUser: (user: User | null) => void;
  setUserVerified: () => void;
  updateUser: (user: User | null) => void;
}

const UserContext = React.createContext(null as any);

export const UserProvider = ({ children }: IChildren) => {
  const [user, setUser] = useState<User | null>(null);

  const updateUser = (user: User | null) => {
    setUser(user);
  };

  const setUserVerified = () => {
    if (!user) return;
    setUser((prevState) => {
      return { ...prevState!, emailVerified: true };
    });
  };

  return <UserContext.Provider value={{ user, updateUser, setUserVerified }}>{children}</UserContext.Provider>;
};

export const useUser = (): IUserContext => React.useContext(UserContext);
