import { paths } from "@app/paths";
import AuthLayout from "@components/layoutElements/Layouts/AuthLayout/AuthLayout";

export default {
  path: paths.authRegister,
  children: [],
  lazy: async () => {
    const { Register } = await import("./Register");

    return {
      element: (
        <AuthLayout>
          <Register />
        </AuthLayout>
      )
    };
  }
};
