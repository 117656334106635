import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FirebaseError } from "firebase/app";
import {
  createUserWithEmailAndPassword,
  // signInWithRedirect,
  signInWithEmailAndPassword,
  signOut,
  AuthProvider,
  UserCredential,
  sendEmailVerification,
  User,
  applyActionCode,
  verifyPasswordResetCode as baseVerifyRasswordResetCode,
  confirmPasswordReset,
  sendPasswordResetEmail,
  updatePassword,
  signInWithCustomToken,
  signInWithPopup
} from "firebase/auth";

import { LoginFieldsKeys, loginFormSchema } from "@constants/auth/loginFormSchema";
import { RegisterFieldsKeys, registerFormSchema } from "@constants/auth/registerFormSchema";
import { auth } from "../firebase-config";
import { paths } from "@app/paths";

import useForm from "@hooks/useForm";
import useBoolean from "@hooks/useBoolean";
import { UseFormResponse } from "@hooks/types/useForm.types";

import { useUser } from "./UserContext";
// import { useGlobalToastsV2 } from "./GlobalToastsV2Context";

import { IChildren } from "@components/interfaces/IChildren";

import { readLocalStorage, writeLocalStorage } from "src/utils/storage";
import { registerFirebaseUser } from "@api/hubApi/methods";
import { useGlobalToastsShortcut } from "./GlobalToastsV3Shortcut.context";

export const SHOW_LOADING = "showLoading";
export const ADMIN_IMPERSONATE_AUTH_CUSTOM_TOKEN = "token";

export type AuthState = "firstLogIn" | "logging" | "loggedIn";

interface AuthValues {
  logInWithLoginAndPassword: () => Promise<void>;
  logInWithProvider: (provider: AuthProvider) => Promise<void>;
  logOut: () => Promise<void>;
  register: () => Promise<void>;
  verifyEmail: (actionCode: string) => Promise<void>;
  verifyPasswordResetCode: (actionCode: string) => Promise<string>;
  resetPassword: (actionCode: string, newPassword: string) => Promise<void>;
  changePassword: (password: string) => Promise<void>;
  sendRecoveryRequest: (email: string) => Promise<void>;
  resendVerificationEmail: () => Promise<void>;
  registerForm: UseFormResponse<RegisterFieldsKeys>;
  loginForm: UseFormResponse<LoginFieldsKeys>;
  authState: AuthState;
  authError: string;
  isLoading: boolean;
}

const AuthContext = React.createContext(null as any);

export const AppAuthProvider = ({ children }: IChildren) => {
  const { t: commonT } = useTranslation("common");
  const { t: tFirebase } = useTranslation("firebase");

  const [searchParams] = useSearchParams();
  const authCustomToken = searchParams.get(ADMIN_IMPERSONATE_AUTH_CUSTOM_TOKEN);

  const { updateUser } = useUser();

  const wasLogged = readLocalStorage<string>(SHOW_LOADING) === "true";

  const [isLoading, startLoading, stopLoading, toggleLoading] = useBoolean(false);
  const [authError, setAuthError] = React.useState("");
  const _resetAuthError = () => setAuthError("");

  const [authState, setAuthState] = React.useState<AuthState>(wasLogged ? "logging" : "firstLogIn");

  const loginForm = useForm<LoginFieldsKeys>(loginFormSchema);
  const registerForm = useForm<RegisterFieldsKeys>(registerFormSchema);

  const { startToast, stopToastError, stopToastSuccess } = useGlobalToastsShortcut();
  const navigate = useNavigate();

  const sendRecoveryRequest = async (email: string) => {
    startLoading();
    try {
      await sendPasswordResetEmail(auth, email);
      stopToastSuccess(commonT("success"));
      return navigate(paths.authRecoverConfirm);
    } catch (e: unknown) {
      let error = "";
      if (e instanceof FirebaseError) {
        error = e.cause ? tFirebase(e.code as any) : commonT("errorMessage");
      } else if (e instanceof Error) {
        error = commonT("errorMessage");
      }
      stopToastError(error, 3000);
    } finally {
      stopLoading();
    }
  };

  const signInAction = async (action: () => Promise<UserCredential>, onSuccess?: (user: User) => void) => {
    startToast(commonT("processing"));
    writeLocalStorage(SHOW_LOADING, true);
    try {
      const userCredentials = await action();
      if (typeof onSuccess === "function") {
        onSuccess(userCredentials.user);
      }
      stopToastSuccess(commonT("successfully-signed-id"));
    } catch (e: unknown) {
      let error = "";
      if (e instanceof FirebaseError) {
        error = !!e?.code ? tFirebase(e.code as any) : commonT("errorMessage");
      } else if (e instanceof Error) {
        error = commonT("errorMessage");
      }
      stopToastError(error, 3000);
    }
  };

  const onRegisterSuccess = async (user: User) => {
    const returnedData = await registerFirebaseUser(user.email!);
    return returnedData;
  };

  const register = async () => {
    await signInAction(
      () => createUserWithEmailAndPassword(auth, registerForm.fields.email.value, registerForm.fields.password.value),
      onRegisterSuccess
    );
  };

  const logInWithLoginAndPassword = async () => {
    await signInAction(() =>
      signInWithEmailAndPassword(auth, loginForm.fields.email.value, loginForm.fields.password.value)
    );
  };

  const logInWithProvider = async (provider: AuthProvider) => {
    await signInAction(() => signInWithPopup(auth, provider));
  };

  const verifyEmail = async (actionCode: string) => {
    return await applyActionCode(auth, actionCode);
  };

  const verifyPasswordResetCode = async (actionCode: string) => {
    return await baseVerifyRasswordResetCode(auth, actionCode);
  };

  const resetPassword = async (actionCode: string, newPassword: string) => {
    return await confirmPasswordReset(auth, actionCode, newPassword);
  };

  const changePassword = async (password: string) => {
    return await updatePassword(auth.currentUser!, password);
  };

  const resendVerificationEmail = async () => {
    return await sendEmailVerification(auth.currentUser!);
  };

  const logOut = async () => {
    try {
      await signOut(auth);
    } catch (e: unknown) {
      if (e instanceof Error) console.log(e.message);
      else console.log(e);
    }
  };

  const adminImpersonateLogin = async (customToken: string) => {
    await signInAction(() => signInWithCustomToken(auth, customToken));
  };

  React.useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      updateUser(user);
      if (!!user) {
        await user.getIdToken();
        setAuthState("loggedIn");
        writeLocalStorage(SHOW_LOADING, true);
      } else {
        updateUser(null);
        writeLocalStorage(SHOW_LOADING, false);
        setAuthState("firstLogIn");
      }
    });

    return () => unsubscribe();
  }, []);

  React.useEffect(() => {
    if (!authCustomToken) return;

    adminImpersonateLogin(authCustomToken);
  }, [authCustomToken]);

  React.useEffect(() => {
    _resetAuthError();
  }, [location.pathname]);

  const returnValue: AuthValues = {
    logInWithLoginAndPassword,
    logInWithProvider,
    logOut,
    register,
    verifyEmail,
    verifyPasswordResetCode,
    resetPassword,
    changePassword,
    sendRecoveryRequest,
    resendVerificationEmail,
    loginForm,
    registerForm,
    authState,
    authError,
    isLoading
  };

  return <AuthContext.Provider value={returnValue}>{children}</AuthContext.Provider>;
};

export const useAuth = (): AuthValues => React.useContext(AuthContext);
