import React from "react";
import { useTranslation } from "react-i18next";

import { EXPLAINER_VIDEO_URL } from "@constants/help/explainer";

import "./Explainer.scss";

const Explainer = () => {
  const { t } = useTranslation("help");

  return (
    <div className="help-explainer">
      <h2 className="regular title mb-xs-5">{t("explainer.title")}</h2>

      <div className="help-explainer-video-box">
        <iframe
          width="100%"
          height="100%"
          src={EXPLAINER_VIDEO_URL}
          title="YouTube video player"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    </div>
  );
};

export default Explainer;
