import { useGlobalToastV3 } from "@contexts/GlobalToastV3.context";
import Portal from "@components/overlay/Portal/Portal";
import Group from "@components/arrangement/Group/Group";
import Spinner from "@design-system/Spinner/Spinner";

const ToastV3 = () => {
  const { toastContent, toastState } = useGlobalToastV3();

  const className = `ds-toast-v3 toast ${toastState} ${toastContent?.bg}`;

  return (
    <Portal>
      <div className={className}>
        <Group justifyContent="center" alignItems="center" fullSize colGap={16}>
          {toastContent?.isLoading && <Spinner spinnerImgSrc={"/images/loaders/tertiary-loader.png"} sizePx={24} />}
          <p className="p2 white-1 t-xs-c">{toastContent?.message}</p>
        </Group>
      </div>
    </Portal>
  );
};

export default ToastV3;
