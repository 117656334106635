import layoutEn from "@locales/en/layout.json";
import authEn from "@locales/en/auth.json";
import orderEn from "@locales/en/order.json";
import myNftsEn from "@locales/en/my_nfts.json";
import orderHistoryEn from "@locales/en/order_history.json";
import offerEn from "@locales/en/offer.json";
import contactEn from "@locales/en/contact.json";
import commonEn from "@locales/en/common.json";
import formErrorsEn from "@locales/en/form_errors.json";
import dashboardEn from "@locales/en/dashboard.json";
import orderCongratulationsEn from "@locales/en/order_congratulations.json";
import orderPaymentAndDeliveryEn from "@locales/en/order_payment_and_delivery.json";
import orderSummaryBoxEn from "@locales/en/order_summary_box.json";
import validationsEn from "@locales/en/validations.json";
import profileEn from "@locales/en/profile.json";
import helpEn from "@locales/en/help.json";
import faqEn from "@locales/en/faq.json";
import collectionsEn from "@locales/en/collections.json";
import firebaseEn from "@locales/en/firebase.json";
import apiErrorCodesEn from "@locales/en/apiErrorCodes.json";

import layoutPl from "@locales/pl/layout.json";
import authPl from "@locales/pl/auth.json";
import orderPl from "@locales/pl/order.json";
import myNftsPl from "@locales/pl/my_nfts.json";
import orderHistoryPl from "@locales/pl/order_history.json";
import offerPl from "@locales/pl/offer.json";
import contactPl from "@locales/pl/contact.json";
import commonPl from "@locales/pl/common.json";
import formErrorsPl from "@locales/pl/form_errors.json";
import dashboardPl from "@locales/pl/dashboard.json";
import orderCongratulationsPl from "@locales/pl/order_congratulations.json";
import orderPaymentAndDeliveryPl from "@locales/pl/order_payment_and_delivery.json";
import orderSummaryBoxPl from "@locales/pl/order_summary_box.json";
import validationsPl from "@locales/pl/validations.json";
import profilePl from "@locales/pl/profile.json";
import helpPl from "@locales/pl/help.json";
import faqPl from "@locales/pl/faq.json";
import collectionsPl from "@locales/pl/collections.json";
import firebasePl from "@locales/pl/firebase.json";
import apiErrorCodesPl from "@locales/pl/apiErrorCodes.json";

export const resources = {
  en: {
    layout: layoutEn,
    auth: authEn,
    order: orderEn,
    myNfts: myNftsEn,
    orderHistory: orderHistoryEn,
    offer: offerEn,
    contact: contactEn,
    help: helpEn,
    common: commonEn,
    formErrors: formErrorsEn,
    dashboard: dashboardEn,
    orderCongratulations: orderCongratulationsEn,
    orderPaymentAndDelivery: orderPaymentAndDeliveryEn,
    orderSummaryBox: orderSummaryBoxEn,
    validations: validationsEn,
    profile: profileEn,
    faq: faqEn,
    collections: collectionsEn,
    firebase: firebaseEn,
    apiErrorCodes: apiErrorCodesEn
  },
  pl: {
    layout: layoutPl,
    auth: authPl,
    order: orderPl,
    myNfts: myNftsPl,
    orderHistory: orderHistoryPl,
    offer: offerPl,
    contact: contactPl,
    help: helpPl,
    common: commonPl,
    formErrors: formErrorsPl,
    dashboard: dashboardPl,
    orderCongratulations: orderCongratulationsPl,
    orderPaymentAndDelivery: orderPaymentAndDeliveryPl,
    orderSummaryBox: orderSummaryBoxPl,
    validations: validationsPl,
    profile: profilePl,
    faq: faqPl,
    collections: collectionsPl,
    firebase: firebasePl,
    apiErrorCodes: apiErrorCodesPl
  }
} as const;
