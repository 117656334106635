import React from "react";
import { AxiosError, AxiosResponse } from "axios";

import { PaginationMeta } from "@api/hubApi/dtos.types";

interface HookArgs<P> {
  requestFn: (queryParams?: PaginationMeta) => Promise<AxiosResponse<P, any>>;
  beforeStart?: () => void;
  onSuccessFn?: () => void;
  onError?: (error: AxiosError) => void;
  onFinally?: () => void;
  setDataOnSuccess?: (responseData: P) => void;
}

interface HookReturnData<T> {
  response: T | null;
  axiosError: AxiosError | null;
  isLoading: boolean;
  requestTrigger: (queryParams?: PaginationMeta) => Promise<void>;
  refetch: (queryParams?: PaginationMeta) => Promise<void>;
}

function useRequest<T>({
  requestFn,
  setDataOnSuccess,
  onError,
  onSuccessFn,
  beforeStart,
  onFinally
}: HookArgs<T>): HookReturnData<T> {
  const [response, setResponse] = React.useState<T | null>(null);
  const [axiosError, setAxiosError] = React.useState<AxiosError | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const setLoading = () => setIsLoading(true);
  const resetLoading = () => setIsLoading(false);

  const requestTrigger = async (args?: any) => {
    setLoading();
    if (beforeStart) {
      beforeStart();
    }
    try {
      const response = await requestFn(args);
      setResponse(response.data);

      if (setDataOnSuccess) {
        setDataOnSuccess(response.data);
      }
      if (onSuccessFn) {
        onSuccessFn();
      }
    } catch (e: unknown) {
      if (!(e instanceof AxiosError)) throw e;

      setAxiosError(e);
      if (onError) {
        onError(e);
      }
    } finally {
      resetLoading();
      if (!!onFinally) {
        onFinally();
      }
    }
  };

  const refetch = async (args?: any) => {
    setResponse(null);
    setAxiosError(null);
    await requestTrigger(args);
  };

  return { requestTrigger, isLoading, response, axiosError, refetch };
}

export default useRequest;
