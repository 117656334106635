import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { Outlet } from "react-router";

import FormBlocker from "@components/common/FormBlocker/FormBlocker";

import { paths } from "../../paths";
import { DISABLE_CHECK_UNSAVED_CHANGES } from "src/config";

import AppLayout from "@components/layoutElements/Layouts/AppLayout/AppLayout";

import { CompanyDetailsProvider } from "@app/routing/Ordering/contexts/CompanyDetailsContext";
import { DeliveryDetailsProviderV2 } from "@app/routing/Ordering/contexts/DeliveryDetailsContextV2";
import NfcTagsV2Provider from "./Nfcs/contexts/NfcTagsV2.context";
import { useSetNfcTags } from "./Nfcs/contexts/SetNfcTags.context";
import { PlaceOrderProvider, usePlaceOrder } from "./contexts/PlaceOrderContext";
import { OrderNavigationProvider } from "./contexts/OrderNavigationContext";
import { CategoryProvider } from "./contexts/OrderCategoryContext";
import { DeliveryOptionsProvider } from "./contexts/DeliveryOptionsContext";
import { PaymentOptionsProvider } from "./contexts/PaymentOptionsContext";
import { PaymentAgreementsProvider } from "./contexts/PaymentAgreements";
import OrderFlowGuard from "./guards/OrderFlowGuard";

import GetStartedRoute from "./GetStarted/GetStartedRoute";
import CategoriesRoute from "./Categories/CategoriesRoute";
import NfcsRoute from "./Nfcs/NfcsRoute";
import PaymentDeliveryRoute from "./PaymentDelivery/PaymentDeliveryRoute";
import CongratulationsRoute from "./Congratulations/CongratulationsRoute";

import "./Ordering.scss";
import { DeliveriesProvider } from "@contexts/DeliveriesContext";

const protectedLinks = [
  paths.orderCategory,
  paths.orderNfc,
  paths.orderPaymentDelivery,
  paths.orderCongratulations
] as (typeof paths)[keyof typeof paths][];

export const Ordering = () => {
  const { pathname } = useLocation();
  const { resetOrderTagsProcess } = usePlaceOrder();
  const { feNfcTags } = useSetNfcTags();

  const hasUnsavedChanges = useMemo(() => {
    switch (pathname) {
      case paths.orderNfc:
        return feNfcTags?.filter((tag) => tag.touched).length! > 0;
      case paths.orderPaymentDelivery:
        return true;
      default:
        return false;
    }
  }, [pathname, feNfcTags]);

  React.useEffect(() => {
    return () => resetOrderTagsProcess();
  }, []);

  React.useEffect(() => {
    if (pathname === paths.orderGetStarted) return resetOrderTagsProcess();
  }, [pathname]);

  return (
    <AppLayout>
      <Outlet />
      <FormBlocker hasUnsavedChanges={!DISABLE_CHECK_UNSAVED_CHANGES && hasUnsavedChanges} links={protectedLinks} />
    </AppLayout>
  );
};

export default {
  path: paths.order,
  element: (
    <OrderNavigationProvider>
      <CategoryProvider>
        {/* <NfcTagsProvider> */}
        <NfcTagsV2Provider>
          <CompanyDetailsProvider>
            <DeliveriesProvider>
              <DeliveryDetailsProviderV2>
                <DeliveryOptionsProvider>
                  <PaymentOptionsProvider>
                    <PaymentAgreementsProvider>
                      <PlaceOrderProvider>
                        <OrderFlowGuard>
                          <Ordering />
                        </OrderFlowGuard>
                      </PlaceOrderProvider>
                    </PaymentAgreementsProvider>
                  </PaymentOptionsProvider>
                </DeliveryOptionsProvider>
              </DeliveryDetailsProviderV2>
            </DeliveriesProvider>
          </CompanyDetailsProvider>
        </NfcTagsV2Provider>
        {/* </NfcTagsProvider> */}
      </CategoryProvider>
    </OrderNavigationProvider>
  ),
  children: [GetStartedRoute, CategoriesRoute, NfcsRoute, PaymentDeliveryRoute, CongratulationsRoute]
};
