import { useEffect } from "react";

const useWriteSessionStorage = <T,>(key: string, value: T | null) => {
  useEffect(() => {
    if (!value) return;

    try {
      sessionStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.log(`Error saving value for key ${key} to sessionStorage`);
    }
  }, [key, value]);
};

export default useWriteSessionStorage;
