import { Outlet } from "react-router";
import { Helmet, HelmetProvider } from "react-helmet-async";

import { paths } from "./paths";
import i18n from "../translations/i18n";

import MobileGuard from "@guards/MobileGuard";
import AuthGuard from "@guards/AuthGuard";

import { UserProvider } from "@contexts/UserContext";
import { AppAuthProvider } from "@contexts/AuthContext";
import { GlobalModalsProvider } from "@contexts/GlobalModalsContext";
import { OrderHistoryProvider } from "@contexts/OrderHistoryDataContext";
import { InitialConfigProvider } from "@contexts/InitialConfigContext";
import { GlobalToastsV2Provider } from "@contexts/GlobalToastsV2Context";
import { CountriesProvider } from "@contexts/CountriesContext";
import CompanyProfileProvider from "./routing/ProfileSettings/contexts/CompanyProfileProvider";
import { GlobalToastsV3ShortcutProvider } from "@contexts/GlobalToastsV3Shortcut.context";
import { GlobalToastV3Provider } from "@contexts/GlobalToastV3.context";
import { ColorsProvider } from "@contexts/ColorsContext";

import GlobalModals from "@components/modals/GlobalModals";
import GlobalToastsV2 from "@components/overlay/Toast/GlobalToastsV2";
import ToastV3 from "@design-system/Toast/ToastV3";

import Auth from "./routing/Auth/Auth";
import Help from "./routing/Help/Help";
import Generator from "./routing/Generator/Generator";
import NotFound from "./routing/NotFound/NotFound";

import DashboardRoute from "./routing/Dashboard/DashboardRoute";
import ProfileRoute from "./routing/ProfileSettings/ProfileRoute";
import OrderHistoryRoute from "./routing/OrderHistory/OrderHistoryRoute";
import Ordering from "./routing/Ordering/Ordering";

import "../translations/i18n";
import "@scss/utils.scss";
import "@scss/base.scss";
import "@scss/lib.scss";

import "@scss/grid.scss";
import "@scss/responsive.scss";

import "../design-system/design-system.scss";
import "./App.scss";

const App = () => {
  return <Outlet />;
};

export default {
  path: paths.app,
  element: (
    <HelmetProvider>
      <Helmet htmlAttributes={{ lang: i18n.language }} />

      <MobileGuard>
        <ColorsProvider>
          <GlobalToastsV2Provider>
            <GlobalToastV3Provider>
              <GlobalToastsV3ShortcutProvider>
                <UserProvider>
                  <AppAuthProvider>
                    <InitialConfigProvider>
                      <AuthGuard>
                        <CountriesProvider>
                          <CompanyProfileProvider>
                            <GlobalModalsProvider>
                              <OrderHistoryProvider>
                                <App />
                                <GlobalModals />
                                <GlobalToastsV2 />
                                <ToastV3 />
                              </OrderHistoryProvider>
                            </GlobalModalsProvider>
                          </CompanyProfileProvider>
                        </CountriesProvider>
                      </AuthGuard>
                    </InitialConfigProvider>
                  </AppAuthProvider>
                </UserProvider>
              </GlobalToastsV3ShortcutProvider>
            </GlobalToastV3Provider>
          </GlobalToastsV2Provider>
        </ColorsProvider>
      </MobileGuard>
    </HelmetProvider>
  ),
  children: [
    Auth,
    DashboardRoute,
    ProfileRoute,
    Ordering,
    // Contact,
    Help,
    OrderHistoryRoute,
    Generator,
    NotFound
  ]
};
