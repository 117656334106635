export const COMPANY_NAME_LENGTH = 60;
export const COMPANY_TAX_NUMBER_LENGTH = 20;
export const COMPANY_CITY_LENGTH = 60;
export const COMPANY_POST_CODE_LENGTH = 10;
export const COMPANY_ADDRESS_LENGTH = 60;

export const COMPANY_EMAIL_LENGTH = 100;
export const COMPANY_URL_MAX_LENGTH = 512;

export const FULL_NAME_LENGTH = 50;
export const PHONE_NUMBER_MIN_LENGTH = 6;
export const PHONE_NUMBER_MAX_LENGTH = 22;
