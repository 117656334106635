import { useState } from "react";

export interface IInputChange {
  disableButton: () => void;
  onInputChange: (value: string, onChange: (value: string) => void) => void;
  onInputChange_withoutEffects: (value: string, onChange: (value: string) => void) => void;
  resetButton: () => void;
  buttonDisabled: boolean;
  hasInputsChanged: boolean;
  setInputChanged: () => void;
}

export const useInputChange = () => {
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const [hasInputsChanged, setHasInputsChanged] = useState<boolean>(false);

  const resetButton = () => {
    setButtonDisabled(false);
    setHasInputsChanged(false);
  };

  const disableButton = () => setButtonDisabled(true);
  const setInputChanged = () => setHasInputsChanged(true);

  const onInputChange = (value: string, onChange: (value: string) => void) => {
    if (!hasInputsChanged) setHasInputsChanged(true);
    onChange(value);
  };

  const onInputChange_withoutEffects = (value: string, onChange: (value: string) => void) => {
    onChange(value);
  };

  return {
    disableButton,
    onInputChange,
    resetButton,
    buttonDisabled,
    hasInputsChanged,
    setInputChanged,
    onInputChange_withoutEffects
  };
};
