import React from "react";
import { useTranslation } from "react-i18next";

import { GO2NFT_MOBILE_APP_APP_STORE, GO2NFT_MOBILE_APP_GOOGLE_PLAY } from "@constants/constants";

import Group from "@components/arrangement/Group/Group";
import Stack from "@components/arrangement/Stack/Stack";

import "./MobileApp.scss";

const MobileApp = () => {
  const { t } = useTranslation("help");

  return (
    <div className="help-mobile-app">
      <h2 className="regular title mb-xs-5">{t("mobileApp.title")}</h2>

      <Group colGap={30} fullWidth>
        <img src="/images/help/help-mobile-app.png" alt="Go2Nft mobile app" width={253} height={521} />

        <Stack rowGap={24} className="help-mobile-app-download">
          <Stack rowGap={16}>
            <h3>{t("mobileApp.content.title")}</h3>
            <p className="p3">{t("mobileApp.content.description")}</p>
          </Stack>

          <img src="/images/help/download-mobile-app-qr.png" alt="download mobile app" width={148} height={148} />

          <Group colGap={20}>
            <a href={GO2NFT_MOBILE_APP_APP_STORE} target="_blank">
              <img src="/images/help/app-store.svg" alt="download go2nft from the app store" width={135} height={40} />
            </a>

            <a href={GO2NFT_MOBILE_APP_GOOGLE_PLAY} target="_blank">
              <img
                src="/images/help/google-play.svg"
                alt="download go2nft from the google play"
                width={135}
                height={40}
              />
            </a>
          </Group>
        </Stack>
      </Group>
    </div>
  );
};

export default MobileApp;
