import React, { useTransition } from "react";
import { AxiosError } from "axios";

import { Inventories, InventoriesDTO, ItemsWithPaginations } from "@api/hubApi/dtos.types";
import { getAvailableTags } from "@api/hubApi/methods";

import { IChildren } from "@components/interfaces/IChildren";
import useRequest from "@hooks/useRequest";
import { useGlobalToastV3 } from "@contexts/GlobalToastV3.context";
import { useTranslation } from "react-i18next";

interface IGeneratorContext {
  inventories: Inventories[];
  isAvailableTagsLoading: boolean;
  isNoTags: boolean;

  triggerGetAvailableTags: () => void;

  onStartReq: () => void;
  onReqPass: () => void;
  onReqFail: (error?: AxiosError<unknown, any>) => void;
}

const GeneratorContext = React.createContext(null as any);

const translateTags = (tags: InventoriesDTO[]): Inventories[] => {
  return tags.map((tag) => ({
    availableItems: tag.available,
    tagId: tag.type.id,
    name: tag.type.product.name,
    description: tag.type.product.description,
    imgUrl: tag.type.product.productImages.filter((img) => img.main)[0].fileUrl,
    paidAmount: tag.paid
  }));
};

export const GeneratorProvider = ({ children }: IChildren) => {
  const { t } = useTranslation("common");
  const { updateToast } = useGlobalToastV3();

  const onStartRequest = () => {
    updateToast({ bg: "black", isLoading: true, message: t("processing") });
  };

  const onRequestPass = () => {
    updateToast({ bg: "success", isLoading: false, message: t("success"), closeAfterMs: 2000 });
  };

  const onRequestFail = (error?: AxiosError<unknown, any>) => {
    updateToast({
      bg: "danger",
      isLoading: false,
      message:
        (error?.response?.data as any)["detail"] ||
        (error?.response?.data as any)["messages"]?.join(", ") ||
        error?.message ||
        t("somethingWenWrong"),
      closeAfterMs: 2000
    });
  };

  const {
    requestTrigger: triggerGetAvailableTags,
    isLoading: isAvailableTagsLoading,
    response: fetchedAvailableTags
  } = useRequest<ItemsWithPaginations<InventoriesDTO>>({ requestFn: getAvailableTags });

  const inventories =
    !fetchedAvailableTags || fetchedAvailableTags?.data.length === 0 ? [] : translateTags(fetchedAvailableTags.data);
  const isNoTags = (fetchedAvailableTags?.data || []).length === 0;

  React.useEffect(() => {
    triggerGetAvailableTags();
  }, []);

  return (
    <GeneratorContext.Provider
      value={{
        inventories,
        isNoTags,
        isAvailableTagsLoading,
        triggerGetAvailableTags,

        onStartReq: onStartRequest,
        onReqPass: onRequestPass,
        onReqFail: onRequestFail
      }}
    >
      {children}
    </GeneratorContext.Provider>
  );
};

export const useGenerator = (): IGeneratorContext => React.useContext(GeneratorContext);
