import { Outlet } from "react-router-dom";

import { paths } from "@app/paths";

import AppLayout from "@components/layoutElements/Layouts/AppLayout/AppLayout";

import { GeneratorProvider } from "./contexts/Generator.context";

import CollectionsViewRoute from "./routes/collections-view/CollectionsViewRoute";
import EditCollectionViewRoute from "./routes/edit-collection-view/EditCollectionViewRoute";
import CreateCollectionViewRoute from "./routes/create-collection-view/CreateCollectionViewRoute";
import CollectionsProviderComp from "./contexts/CollectionsContext/CollectionsProviderComp";

import "./Generator.scss";

const Generator = () => {
  return (
    <GeneratorProvider>
      <CollectionsProviderComp>
        <Outlet />
      </CollectionsProviderComp>
    </GeneratorProvider>
  );
};

export default {
  path: paths.generator,
  element: (
    <AppLayout additionalClass="generator">
      <Generator />
    </AppLayout>
  ),
  children: [CollectionsViewRoute, EditCollectionViewRoute, CreateCollectionViewRoute]
};
