import React from "react";

import { IChildren } from "@components/interfaces/IChildren";
import useForm from "@hooks/useForm";
import { ChangePasswordKeys, changePasswordFormSchema } from "@constants/profile/changePasswordFormSchema";
import { UseFormResponse } from "@hooks/types/useForm.types";
import { IInputChange, useInputChange } from "@hooks/useInputChange";

interface ISetPasswordDetailsContext {
  changePasswordForm: UseFormResponse<ChangePasswordKeys>;
  passwordInputChanged: IInputChange;
}

const SetPasswordDetailsContext = React.createContext(null as any);

export const SetPasswordDetailsProvider = ({ children }: IChildren) => {
  const changePasswordForm = useForm<ChangePasswordKeys>(changePasswordFormSchema);
  const passwordInputChanged = useInputChange();

  return (
    <SetPasswordDetailsContext.Provider value={{ changePasswordForm, passwordInputChanged }}>
      {children}
    </SetPasswordDetailsContext.Provider>
  );
};

export const useSetPasswordDetails = (): ISetPasswordDetailsContext => React.useContext(SetPasswordDetailsContext);
