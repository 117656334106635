import React from "react";

interface Props {
  persistPolicy: "persist-between-refresh" | "always-fresh-state";
}

export interface ValidationFields {
  errors: string[];
  toShowError: boolean;
  touched: boolean;
}

export type UseValidationReturn = {
  validationFields: ValidationFields;
  validateOnlyErrors: (errors: string[]) => void;
  onlyShowErrors: () => void;
  validateAndShowErrors: (errors: string[]) => void;
  resetToInitial: () => void;
};

const initialFields: ValidationFields = {
  errors: [],
  toShowError: false,
  touched: false
};

const useValidation = ({ persistPolicy }: Props): UseValidationReturn => {
  const validationId = React.useId();
  const initialState = React.useMemo(
    () =>
      persistPolicy === "always-fresh-state"
        ? initialFields
        : persistPolicy === "persist-between-refresh"
        ? JSON.parse(sessionStorage.getItem(validationId) || "null")
        : initialFields,
    []
  );

  const [validationFields, setValidationFields] = React.useState<ValidationFields>(initialState);

  const validateOnlyErrors = React.useCallback((errors: string[]) => {
    setValidationFields((prev) => ({
      ...prev,
      errors,
      touched: true,
      toShowError: errors.length === 0 ? false : prev.toShowError
    }));
  }, []);

  const onlyShowErrors = React.useCallback(() => {
    setValidationFields((prev) => ({ ...prev, toShowError: prev.errors.length > 0 }));
  }, []);

  const validateAndShowErrors = React.useCallback((errors: string[]) => {
    setValidationFields((prev) => ({
      ...prev,
      errors,
      touched: true,
      toShowError: errors.length > 0
    }));
  }, []);

  const resetToInitial = React.useCallback(() => {
    setValidationFields(initialFields);
  }, []);

  React.useEffect(() => {
    if (persistPolicy === "always-fresh-state") return;

    sessionStorage.setItem(validationId, JSON.stringify(validationFields));
  }, [validationFields]);

  return { validationFields, validateOnlyErrors, onlyShowErrors, validateAndShowErrors, resetToInitial };
};

export default useValidation;
