import React from "react";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";

import { ICompanyDetails, useCompanyDetails } from "@app/routing/Ordering/contexts/CompanyDetailsContext";

import { useGlobalToastsV2 } from "@contexts/GlobalToastsV2Context";

import { IFormField } from "@hooks/useValidatedInput";
import useRequest from "@hooks/useRequest";
import useWriteSessionStorage from "@hooks/useWriteSessionStorage";

import { AddressDTO, CreateOrderDTO, OrderDTO } from "@api/hubApi/dtos.types";
import { createOrder } from "@api/hubApi/methods";

import { readSessionStorage } from "@utils/storage";

import { IChildren } from "@components/interfaces/IChildren";
import { useDeliveryDetailsV2 } from "./DeliveryDetailsContextV2";
import { FormFieldsWithMethods } from "@hooks/types/useForm.types";
import { IShippingDetails } from "../../../../contexts/shippingDetails.schema";
import { IFeNfcTag, useSetNfcTags } from "@app/routing/Ordering/Nfcs/contexts/SetNfcTags.context";
import { useGetNfcTags } from "@app/routing/Ordering/Nfcs/contexts/GetNfcTags.context";
import { ICategories, useCategory } from "./OrderCategoryContext";
import { useOrderNavigation } from "./OrderNavigationContext";
import { useDeliveryOptions } from "./DeliveryOptionsContext";
import { usePaymentOptions } from "./PaymentOptionsContext";
import { usePaymentAgreements } from "./PaymentAgreements";
import { ORDERING_ERROR_KEY } from "@constants/ordering-paymentDelivery";
import { scrollToClassName } from "@utils/scrollToId";

interface IPlaceOrderContext {
  placeOrder: () => void;
  isRequestPending: boolean;
  resetOrderTagsProcess: () => void;
  orderResponse: OrderDTO | null;
  canGoNextFromPaymentDelivery: boolean;
  finalCompanyDetails: AddressDTO;
  finalDeliveryDetails: AddressDTO;
}

const PlaceOrderContext = React.createContext(null as any);

const PLACE_ORDER_RESPONSE_KEY = "place-order-response-key";

const getRequestData = (
  tags: IFeNfcTag[],
  productCategory: ICategories,
  companyDetails: ICompanyDetails,
  shippingDetails: FormFieldsWithMethods<IShippingDetails>,
  finalCompanyDetails: AddressDTO,
  finalDeliveryDetails: AddressDTO,
  deliveryId: string
): CreateOrderDTO => {
  const payload: CreateOrderDTO = {
    category: productCategory as string,
    taxNumber: companyDetails.companyTaxNumber.value,
    deliveryId,
    companyAddress: {
      countryId: finalCompanyDetails.country?.id ?? "",
      addressLine: finalCompanyDetails.addressLine,
      city: finalCompanyDetails.city,
      companyName: finalCompanyDetails.companyName,
      postCode: finalCompanyDetails.postCode
    },
    deliveryAddress: {
      countryId: finalDeliveryDetails.country?.id ?? "",
      addressLine: finalDeliveryDetails.addressLine,
      city: finalDeliveryDetails.city,
      companyName: finalDeliveryDetails.companyName,
      postCode: finalDeliveryDetails.postCode
    },
    fullName: shippingDetails.nameAndSurname.value,
    phoneNumber: shippingDetails.phoneNumber.value,
    orderProducts:
      !!tags && tags.length > 0
        ? tags
            .filter((tag) => Number(tag.amount) >= tag.min)
            .map((tag) => ({ quantity: Number(tag.amount), productId: tag.id }))
        : []
  };
  return payload;
};

export const PlaceOrderProvider = ({ children }: IChildren) => {
  const { onNext } = useOrderNavigation();
  const { category: productCategory, resetCategory } = useCategory();
  const { fetchAllNfcs } = useGetNfcTags();
  const { feNfcTags, resetFeNfcTags, resetFeCache } = useSetNfcTags();
  const { updateCompanyDetail } = useCompanyDetails();

  const { t } = useTranslation("validations");

  const {
    isDeliveryDifferentThanCompany,
    validateCompanyDetails,
    areCompanyDetailsValid,
    companyDetails,
    resetCompanyDetails,
    finalCompanyDetails
  } = useCompanyDetails();

  const {
    areDeliveryDetailsValid,
    areShippingDetailsValid,
    shippingDetails,
    finalDeliveryDetails,
    resetFields: resetDeliveryFields,
    validateFields: validateDeliveryFields
  } = useDeliveryDetailsV2();

  const {
    currentlyActive: currentlyActiveDeliveryOption,
    isValid: isDeliveryMethodValid,
    resetDeliveryOptions,
    validate: validateDeliveryMethods
  } = useDeliveryOptions();

  const { payment, isValid: isPaymentMethodValid, reset: resetPaymentMethods } = usePaymentOptions();
  const { validateAgreements, areAgreementsValid, resetAgreements } = usePaymentAgreements();

  const { startToast } = useGlobalToastsV2();

  const canGoNextFromPaymentDelivery =
    areCompanyDetailsValid &&
    areShippingDetailsValid &&
    (isDeliveryDifferentThanCompany ? areDeliveryDetailsValid : true) &&
    isPaymentMethodValid &&
    isDeliveryMethodValid &&
    areAgreementsValid;

  const validate = () => {
    validateCompanyDetails();
    validateDeliveryFields();
    validateDeliveryMethods();
    payment.validatePaymentMethod();
    validateAgreements();
    validateDeliveryMethods();
  };

  const resetOrderTagsProcess = () => {
    resetCategory();
    resetCompanyDetails();
    resetFeCache();
    resetFeNfcTags();
    resetDeliveryFields();
    resetPaymentMethods();
    resetDeliveryOptions();
    resetAgreements();
  };

  const requestData = getRequestData(
    feNfcTags!,
    productCategory,
    companyDetails,
    shippingDetails,
    finalCompanyDetails,
    finalDeliveryDetails,
    currentlyActiveDeliveryOption?.id || "-1"
  );

  const onSuccess = () => {
    onNext();
    fetchAllNfcs();
  };

  const onFail = (error: AxiosError) => {
    startToast({
      message:
        (error.response?.data as any)["messages"]?.join(", ") ||
        (error.response?.data as any)["detail"] ||
        error.message ||
        "Couldn't create order",
      withLoading: false,
      variant: "error",
      duration: 6000
    });

    if ((error.response?.data as any)["error"] === "InvalidTaxNumberException") {
      const payload = { toShowError: true, error: t("invalidTaxNumber") } as Partial<IFormField>;
      updateCompanyDetail("companyTaxNumber", payload);
    }
  };

  const storedResponse = JSON.parse(readSessionStorage(PLACE_ORDER_RESPONSE_KEY) || "null");

  const {
    requestTrigger,
    isLoading: isRequestPending,
    response,
    axiosError
  } = useRequest<OrderDTO>({
    requestFn: () => createOrder(requestData),
    onSuccessFn: onSuccess,
    onError: onFail
  });

  const finalResponse = (response || storedResponse) as OrderDTO | null;

  useWriteSessionStorage<OrderDTO>(PLACE_ORDER_RESPONSE_KEY, finalResponse);

  const handlePlaceOrder = () => {
    if (isRequestPending) return;

    !canGoNextFromPaymentDelivery ? validate() : requestTrigger();
  };

  React.useEffect(() => {
    scrollToClassName(ORDERING_ERROR_KEY);
  });

  return (
    <PlaceOrderContext.Provider
      value={{
        placeOrder: handlePlaceOrder,
        isRequestPending,
        resetOrderTagsProcess,
        orderResponse: finalResponse,
        canGoNextFromPaymentDelivery,
        finalDeliveryDetails
      }}
    >
      {children}
    </PlaceOrderContext.Provider>
  );
};

export const usePlaceOrder = (): IPlaceOrderContext => React.useContext(PlaceOrderContext);
