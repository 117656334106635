import React from "react";

import { IChildren } from "@components/interfaces/IChildren";
import { useCompanyProfile } from "../CompanyProfileProvider";
import { useSetPasswordDetails } from "./SetPasswordDetails.context";
import { useAuth } from "@contexts/AuthContext";
import { useTranslation } from "react-i18next";
import { FirebaseErrorKeys } from "@constants/auth/firebaseAuthErrorMessages";
import { useRulesPasswordDetails } from "./RulesPasswordDetails.context";

interface IUpdatePasswordDetailsContext {
  updateUserPassword: () => Promise<void>;
}

const UpdatePasswordDetailsContext = React.createContext(null as any);

export const UpdatePasswordDetailsProvider = ({ children }: IChildren) => {
  const { t } = useTranslation("profile");
  const { t: tFirebase } = useTranslation("firebase");

  const { changePassword } = useAuth();

  const { runToastOnError, runToastOnStart, runToastOnSuccess } = useCompanyProfile();

  const { changePasswordForm, passwordInputChanged } = useSetPasswordDetails();
  const { password } = changePasswordForm.fields;
  const { arePasswordsEqual } = useRulesPasswordDetails();

  const handleFirebaseError = (error: FirebaseErrorKeys) => tFirebase(error);

  const updateUserPassword = async () => {
    changePasswordForm.validate();
    if (!changePasswordForm.isValid || !arePasswordsEqual) return;

    passwordInputChanged.disableButton();
    runToastOnStart(t("resetPassword.request.loading"));

    try {
      await changePassword(password.value);
      runToastOnSuccess(t("resetPassword.request.success"));
    } catch (e: any) {
      const error = e.message as FirebaseErrorKeys;
      runToastOnError(handleFirebaseError(error));
    } finally {
      changePasswordForm.reset();
      passwordInputChanged.resetButton();
    }
  };

  return (
    <UpdatePasswordDetailsContext.Provider value={{ updateUserPassword }}>
      {children}
    </UpdatePasswordDetailsContext.Provider>
  );
};

export const useUpdatePasswordDetails = (): IUpdatePasswordDetailsContext =>
  React.useContext(UpdatePasswordDetailsContext);
