import useBoolean from "@hooks/useBoolean";
import React from "react";

export interface ColorPickerReturn {
  brandLogoRef: React.MutableRefObject<any>;
  isHovered: boolean;
  previewBg: string;
  bg: string;
  hover: () => void;
  leave: () => void;
  updateBg: (color: any) => void;
  uploadInitialColorHex: (colorHex: string) => void;
  updatePreview: (color: any) => void;

  isColorChanged: boolean;
  resetIsColorChanged: () => void;
}

const useColorPicker = (): ColorPickerReturn => {
  const [isColorChanged, setIsColorChanged, resetIsColorChanged] = useBoolean(false);

  const brandLogoRef = React.useRef(null as any);
  const [bg, setBg] = React.useState("#D9D9D8");
  const [previewBg, setPreviewBg] = React.useState("#ffffff");
  const [isHovered, hover, leave] = useBoolean(false);

  const uploadInitialColorHex = (colorHex: string | null) => {
    if (!colorHex) return;

    setBg(colorHex);
  };
  const updateBg = (color: any) => {
    setIsColorChanged();
    setBg(color.hex);
  };
  const updatePreview = (color: any) => setPreviewBg(color.hex);

  return {
    brandLogoRef,
    isHovered,
    previewBg,
    bg,
    hover,
    leave,
    updateBg,
    uploadInitialColorHex,
    updatePreview,

    isColorChanged,
    resetIsColorChanged
  };
};

export default useColorPicker;
