import React from "react";
import Skeleton from "./Skeleton";

interface CircularSkeletonProps {
  sizePx: number;
  className?: string;
}

const CircularSkeleton = ({ sizePx, className }: CircularSkeletonProps) => {
  return <Skeleton type={{ circular: { sizePx } }} className={className} />;
};

export default CircularSkeleton;
