import { useEffect, useCallback, useRef } from "react";
import { useBeforeUnload, unstable_useBlocker as useBlocker } from "react-router-dom";
import { useTranslation } from "react-i18next";
import type { Location } from "history";

import { paths } from "@app/paths";

interface FormBlockerProps {
  hasUnsavedChanges: boolean;
  links: string[];
  message?: string;
}

const usePrompt = (
  onLocationChange: ({ nextLocation }: { nextLocation: Location }) => boolean,
  hasUnsavedChanges: boolean
) => {
  const blocker = useBlocker(hasUnsavedChanges ? onLocationChange : false);
  const prevState = useRef(blocker.state);

  useEffect(() => {
    if (blocker.state === "blocked") {
      blocker.reset();
    }

    prevState.current = blocker.state;
  }, [blocker, hasUnsavedChanges]);
};

const FormBlocker = ({ hasUnsavedChanges, links, message }: FormBlockerProps) => {
  const { t: commonT } = useTranslation("common");

  const onLocationChange = useCallback(
    ({ nextLocation }: { nextLocation: Location }) => {
      if (!links.includes(nextLocation.pathname) && hasUnsavedChanges) {
        return !window.confirm(message || commonT("unsavedChangesWarning"));
      }

      return false;
    },
    [hasUnsavedChanges]
  );

  usePrompt(onLocationChange, hasUnsavedChanges);

  useBeforeUnload(
    useCallback(
      (event) => {
        if (hasUnsavedChanges) {
          event.preventDefault();
          event.returnValue = "";
        }
      },

      [hasUnsavedChanges]
    ),

    { capture: true }
  );

  return null;
};

export default FormBlocker;
