import React from "react";

import { IChildren } from "@components/interfaces/IChildren";
import { SetUserDetailsProvider } from "./SetUserDetails.context";
import { UpdateUserDetailsProvider } from "./UpdateUserDetails.context";
import { EffectsUserDetailsProvider } from "./EffectsUserDetails.context";

interface IUserDetailsContext {}

const UserDetailsContext = React.createContext(null as any);

export const UserDetailsProvider = ({ children }: IChildren) => {
  return (
    <UserDetailsContext.Provider value={{}}>
      <SetUserDetailsProvider>
        <UpdateUserDetailsProvider>
          <EffectsUserDetailsProvider>{children}</EffectsUserDetailsProvider>
        </UpdateUserDetailsProvider>
      </SetUserDetailsProvider>
    </UserDetailsContext.Provider>
  );
};

export const useUserDetails = (): IUserDetailsContext => React.useContext(UserDetailsContext);
