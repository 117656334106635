import Stack from "@components/arrangement/Stack/Stack";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Group from "@components/arrangement/Group/Group";
import G2nButton from "@components/common/Buttons/Button";

import { paths } from "@app/paths";

import { useInitialConfig } from "@contexts/InitialConfigContext";

import "./NotFound.scss";

const NotFound = () => {
  const { t } = useTranslation("layout");
  const navigate = useNavigate();

  const { defaultMainPath } = useInitialConfig();

  return (
    <Stack fullWidth justifyContent="center" alignItems="center" className="not-found" rowGap={100}>
      <img src="/images/logos/go2nft-company-logo.svg" />

      <Stack alignItems="center" rowGap={24}>
        <Group colGap={4}>
          <span className="p4 fs-3">{t("404.errorCode")}</span>
          <span className="p4 fs-3 black-2">{t("404.errorTitle")}</span>
        </Group>

        <p className="fs-6 black-2 text-center description">{t("404.errorDescription")}</p>

        <G2nButton variant="secondary-btn" className="nav-button" action={() => navigate(defaultMainPath)}>
          {t("404.goBack")}
        </G2nButton>
      </Stack>
    </Stack>
  );
};

export default {
  path: paths.notFound,
  element: <NotFound />,
  children: []
};
