import Stack from "@components/arrangement/Stack/Stack";

interface IAuthError {
  title: string;
  error: string;
  className?: string;
}

const AuthError = ({ title, error, className }: IAuthError) => {
  return (
    <Stack rowGap={16} alignItems="center" justifyContent="center" fullSize className={className}>
      <img src="/images/auth/verify-email-error.svg" alt="error verify account" width={200} height={200} />

      <h2 className="mt-xs-4 regular text-center error-title">{title}</h2>
      <p className="p2">{error}</p>
    </Stack>
  );
};

export default AuthError;
