export function readSessionStorage(key: string): string | null {
  const data = window.sessionStorage.getItem(key);
  return data;
}

export function writeSessionStorage(key: string, value: any): void {
  window.sessionStorage.setItem(key, JSON.stringify(value));
}

export function clearSessionStorage() {
  window.sessionStorage.clear();
}

export const clearSessionStorageKey = (key: string) => {
  window.sessionStorage.removeItem(key);
};

export const clearSessionStorageItems = (keys: string[]) => {
  keys.forEach((key) => window.sessionStorage.removeItem(key));
};

export function readLocalStorage<R>(key: string): R {
  return window.localStorage.getItem(key) as R;
}

export function writeLocalStorage(key: string, value: any): void {
  window.localStorage.setItem(key, JSON.stringify(value));
}

export const clearLocalStorage = () => {
  window.localStorage.clear();
};

export const clearLocalStorageItems = (keys: string[]) => {
  keys.forEach((key) => window.localStorage.removeItem(key));
};
