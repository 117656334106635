import ImageWithCropper from "@contexts/ImageWithCropper/ImageWithCropper";
import React from "react";
import { HocCropperProvider } from "./HocCropper.context";

interface Props {
  children: React.ReactNode;
}

const HocCropperComp = ({ children }: Props) => {
  return (
    <ImageWithCropper>
      <HocCropperProvider>{children}</HocCropperProvider>
    </ImageWithCropper>
  );
};

export default HocCropperComp;
