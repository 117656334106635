import { UseFormFields } from "@hooks/types/useForm.types";

export type ChangePasswordKeys = "password" | "confirmPassword";

export const changePasswordFormSchema: Readonly<UseFormFields<ChangePasswordKeys>> = {
  password: {
    initialValue: "",
    validationPattern: {
      isRequired: false,
      minLength: 6
    }
  },
  confirmPassword: {
    initialValue: "",
    validationPattern: {
      isRequired: false,
      minLength: 6
    }
  }
};
