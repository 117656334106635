import { useTranslation } from "react-i18next";

import AuthLayout from "@components/layoutElements/Layouts/AuthLayout/AuthLayout";
import Stack from "@components/arrangement/Stack/Stack";
import G2nButton from "@components/common/Buttons/Button";
import Input from "@components/dataEntry/Input/Input";

import { paths } from "src/app/paths";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@contexts/AuthContext";
import useForm from "@hooks/useForm";
import { RecoverFormFields, recoverFormSchema } from "@constants/auth/recoverFormSchema";
import useCleanup from "@hooks/useCleanup";
import "./Recover.scss";

const Recover = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("auth");

  const { sendRecoveryRequest, isLoading } = useAuth();

  const { fields, isValid, validate, reset: resetRecoverFields } = useForm<RecoverFormFields>(recoverFormSchema);
  const { error, toShowError, update, value: emailValue } = fields.email;

  const handleRecoveryRequest = () => {
    if (isLoading) {
      return () => {};
    } else if (isValid) {
      return sendRecoveryRequest(emailValue);
    } else {
      return validate();
    }
  };

  useCleanup(resetRecoverFields);

  return (
    <Stack rowGap={24} className="recover-password">
      <img src="/images/logos/go2nft-company-logo.svg" alt="go2nft company logo" />
      <Stack rowGap={16}>
        <h1 className="title">{t("recover.title")}</h1>
        <p className="p3 black-1">{t("recover.description")}</p>
      </Stack>
      <Input
        type="email"
        value={emailValue}
        onChange={(e) => update(e.currentTarget.value)}
        error={toShowError ? error : ""}
        label={t("recover.inputs.email.label")}
        placeholder={t("recover.inputs.email.placeholder")}
        fullSize
        required={true}
        className="recover-input"
        onEnter={handleRecoveryRequest}
      />
      <G2nButton action={handleRecoveryRequest} variant="primary-btn" fullSize disabled={isLoading}>
        {t("recover.buttons.send")}
      </G2nButton>
      <G2nButton action={() => navigate(paths.app)} variant="secondary-btn" fullSize>
        {t("recover.buttons.back")}
      </G2nButton>
    </Stack>
  );
};

export default {
  path: paths.authRecover,
  element: (
    <AuthLayout>
      <Recover />
    </AuthLayout>
  ),
  children: []
};
