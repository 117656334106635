import { paths } from "@app/paths";
import AuthLayout from "@components/layoutElements/Layouts/AuthLayout/AuthLayout";

export default {
  path: paths.authLogin,
  lazy: async () => {
    const { Login } = await import("./Login");

    return {
      element: (
        <AuthLayout>
          <Login />
        </AuthLayout>
      )
    };
  },
  children: []
};
