import { paths } from "@app/paths";
import AppLayout from "@components/layoutElements/Layouts/AppLayout/AppLayout";

export default {
  path: paths.dashboard,
  children: [],
  lazy: async () => {
    const { Dashboard } = await import("./Dashboard");

    return {
      element: (
        <AppLayout>
          <Dashboard />
        </AppLayout>
      )
    };
  }
};
