import { UseFormFields } from "@hooks/types/useForm.types";

export type ResetPasswordKeys = "password";

export const resetPasswordSchema: Readonly<UseFormFields<ResetPasswordKeys>> = {
  password: {
    initialValue: "",
    validationPattern: {
      isRequired: true,
      minLength: 6
    }
  }
};
