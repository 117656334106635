import { AxiosResponse } from "axios";

import { clearEmptyKeys } from "@utils/clearEmptyKeys";
import { apiClient } from "./client.instance";
import {
  ItemsWithPaginations,
  CountryDTO,
  CreateOrderDTO,
  OrderDTO,
  PaginationMeta,
  ProductWithImagesDTO,
  FileDTO,
  MintDetailDTO,
  UpdateUserDTO,
  UserDTO,
  PaginatedDeliveryDTO,
  UpdateCompanyDTO,
  PaginatedCollectionsDTO,
  CollectionDTO,
  SetDTO,
  UpsertCollectionsDTO,
  InventoriesDTO,
  CreateCreateFeV2,
  CreateNullFeV2,
  UpdateCreateFeV2,
  UpdateUpdateFeV2,
  UpdateNullFeV2,
  CreateCreateBeV2,
  CreateNullBeV2,
  UpdateCreateBeV2,
  UpdateUpdateBeV2,
  UpdateNullBeV2,
  CreateSetBeV2,
  UpdateSetBeV2,
  PublishSetFeV2,
  PublishSetsBeV2,
  PublishSetBeV2,
  UpdateBrandsFe,
  UpdatePartnerProfileFeDTO,
  UpdateBrandsBe,
  FileCategories,
  FeImageDTO,
  ImageWithCrop
} from "@api/hubApi/dtos.types";
import { getImageId, getUpsertImages } from "./utils/getUpsertImages";

//AUTH ---------------------------------------------------
export const getUser = async (): Promise<AxiosResponse<UserDTO, any>> => {
  const url = `/v1/auth/me`;
  const method = "GET";

  return await apiClient.sendRequest<UserDTO>({
    method,
    url
  });
};

export const updateUser = async (user: UpdateUserDTO): Promise<AxiosResponse<UserDTO, any>> => {
  const url = `/v1/auth/me`;
  const method = "PUT";

  return await apiClient.sendRequest<UserDTO>({
    method,
    url,
    data: clearEmptyKeys(user)
  });
};

export const updateCompany = async ({
  company,
  partner
}: {
  company: UpdateCompanyDTO;
  partner?: UpdatePartnerProfileFeDTO;
}): Promise<AxiosResponse<UserDTO, any>> => {
  const url = `/v1/auth/me`;
  const method = "PUT";

  const requestData: UpdateUserDTO = {
    client: {
      company,
      partner
    }
  };

  return await apiClient.sendRequest<UserDTO>({
    method,
    url,
    data: requestData
  });
};

export const updateBrands = async ({ partner }: { partner: UpdateBrandsFe }): Promise<AxiosResponse<UserDTO, any>> => {
  const url = `/v1/auth/partnerImages`;
  const method = "PUT";

  let uploadedTransparentLogoId = "";
  let uploadedBgImageId = "";

  if (!!partner?.logoBackgroundFile?.original.file) {
    uploadedBgImageId = (await uploadImage(partner.logoBackgroundFile, "partners")).data.id;
  }

  if (partner?.logoTransparentFile) {
    uploadedTransparentLogoId = partner?.logoTransparentFile?.file
      ? (await uploadImage({ original: partner.logoTransparentFile }, "partners_logo")).data.id
      : "";
  }

  const requestData: UpdateBrandsBe = clearEmptyKeys({
    logoTransparentId: uploadedTransparentLogoId,
    logoBackgroundId: uploadedBgImageId,
    logoBgColor: partner?.logoBgColor
  });

  return await apiClient.sendRequest<UserDTO>({
    method,
    url,
    data: requestData
  });
};

export const registerFirebaseUser = async (email: string) => {
  const url = `/v1/auth/register`;
  const method = "POST";

  return await apiClient.sendRequest({ method, url, data: { email } });
};
//AUTH ---------------------------------------------------

//SHOP ---------------------------------------------------
export const createOrder = async (data: CreateOrderDTO) => {
  const url = `/v1/shop/orders`;
  const method = "POST";

  return await apiClient.sendRequest<OrderDTO>({ url, method, data });
};

export const getOrderDetails = async (orderId: string) => {
  const url = `/v1/shop/orders/${orderId}`;
  const method = "GET";

  return await apiClient.sendRequest<OrderDTO>({ url, method });
};

export const getOrdersHistory = async (queryParams?: Partial<PaginationMeta>) => {
  const url = `/v1/shop/orders`;
  const method = "GET";
  const params = queryParams || { page: 1, perPage: 50, orderBy: "createdAt", order: "desc" };

  return await apiClient.sendRequest<ItemsWithPaginations<OrderDTO>>({
    method,
    url,
    params
  });
};

export const getSupportedCountries = async () => {
  const url = `/v1/shop/countries`;
  const method = "GET";

  return await apiClient.sendRequest<CountryDTO[]>({ url, method });
};

export const getListOfAllNFCs = async () => {
  const url = `/v1/shop/products`;
  const method = "GET";
  const params: PaginationMeta = { order: "asc", orderBy: "priority" };

  return await apiClient.sendRequest<ItemsWithPaginations<ProductWithImagesDTO>>({
    method,
    url,
    params
  });
};

export const getOrderInvoicePDF = async (orderId: string) => {
  const url = `/v1/shop/orders/${orderId}/invoice`;
  const method = "GET";

  return await apiClient.sendRequest<Blob>({
    method,
    url,
    responseType: "blob"
  });
};

export const cancelOrder = async (orderId: string) => {
  const url = `/v1/shop/orders/${orderId}/cancel`;
  const method = "DELETE";

  return await apiClient.sendRequest<OrderDTO>({
    method,
    url
  });
};

export const setMintDetail = async (orderId: string, data: MintDetailDTO) => {
  const url = `/v1/shop/orders/${orderId}/mintDetail`;
  const method = "POST";

  return await apiClient.sendRequest<OrderDTO>({
    method,
    url,
    data
  });
};

export const getDeliveries = async (countryCode?: string) => {
  const toShowQuestionMark = !!countryCode;
  const countryCodeSearchParam = `${countryCode ? `countryCode=${countryCode}` : ""}`;

  const url = `/v1/shop/deliveries${toShowQuestionMark ? "?" : ""}${countryCodeSearchParam}`;
  const method = "GET";
  const params = { perPage: 1000 };

  return await apiClient.sendRequest<PaginatedDeliveryDTO>({ method, url, params });
};
//SHOP ---------------------------------------------------

//EMAIL --------------------------------------------------
//EMAIL --------------------------------------------------

//FILES --------------------------------------------------

export const uploadImage = async (imageWithCrop: ImageWithCrop, category: FileCategories) => {
  const method = "POST";
  const url = `/v1/files/upload/image`;
  const params = { category };

  const formData = new FormData();

  formData.append("files", imageWithCrop.original.file!);

  if (imageWithCrop.cropped) {
    formData.append("files", imageWithCrop.cropped);
  } else {
    formData.append("files", imageWithCrop.original.file!);
  }

  return await apiClient.sendRequest<FileDTO>({
    method,
    url,
    headers: {
      "Content-Type": "multipart/form-data"
    },
    data: formData,
    params
  });
};

export const uploadMultipleImages = async (files: FeImageDTO[], category: FileCategories) => {
  const method = "POST";
  const url = `/v1/files/uploadMultipleImages?visible=true`;
  const params = { category };

  const formData = new FormData();

  if (files.length > 0) {
    for (const file of files) {
      const feImageWrapper = Object.values(file);
      if (!!feImageWrapper[0]) formData.append("files", feImageWrapper[0]);
    }
  }

  return await apiClient.sendRequest<FileDTO[]>({
    method,
    url,
    headers: {
      "Content-Type": "multipart/form-data"
    },
    data: formData,
    params
  });
};

export const uploadFile = async (
  file: File,
  options: { category: FileCategories; visible?: boolean; uploadToIpfs?: boolean }
) => {
  const method = "POST";
  const url = `/v1/files/upload`;
  const params = clearEmptyKeys({ visible: options?.visible, uploadToIpfs: options?.uploadToIpfs });

  const formData = new FormData();

  formData.append("file", file);

  return await apiClient.sendRequest<FileDTO>({
    method,
    url,
    headers: {
      "Content-Type": "multipart/form-data"
    },
    data: formData,
    params
  });
};

export const uploadMultipleFiles = async (
  files: File[],
  options: { category: FileCategories; visible?: boolean; uploadToIpfs?: boolean }
) => {
  const method = "POST";
  const url = `/v1/files/uploadMultipleFiles`;
  const params = clearEmptyKeys({ visible: options?.visible, uploadToIpfs: options?.uploadToIpfs });

  const formData = new FormData();

  for (const file of files) {
    formData.append("files", file);
  }

  return await apiClient.sendRequest<FileDTO[]>({
    method,
    url,
    headers: {
      "Content-Type": "multipart/form-data"
    },
    data: formData,
    params
  });
};

//FILES --------------------------------------------------

//TOKENS--------------------------------------------------
export const getCollections = async (argParams?: PaginationMeta) => {
  const method = "GET";
  const url = `v1/tokens/collections`;
  const params: PaginationMeta = argParams || {};

  const response = await apiClient.sendRequest<PaginatedCollectionsDTO>({
    method,
    url,
    params
  });

  return response;
};

export const getCollection = async (collectionId: string) => {
  const method = "GET";
  const url = `v1/tokens/collections/${collectionId}`;
  const meta: PaginationMeta = {};

  const response = await apiClient.sendRequest<CollectionDTO>({ method, url, ...meta });
  return response;
};

export const getAvailableTags = async () => {
  const method = "GET";
  const url = "v1/tokens/inventories";
  const meta: PaginationMeta = { orderBy: "updatedAt" };

  const response = await apiClient.sendRequest<ItemsWithPaginations<InventoriesDTO>>({ method, url, ...meta });
  return response;
};

export const getProduct = async (setId: string) => {
  const method = "GET";
  const url = `v1/tokens/sets/${setId}`;

  const response = await apiClient.sendRequest<SetDTO>({ method, url });
  return response;
};

export const createCollectionCreateProduct = async (createCreate: CreateCreateFeV2) => {
  const method = "PUT";
  const url = `v1/tokens`;

  const { name, shortDescription, description, isPublic, theme, images: mainImages, set } = createCreate;

  const collectionImageId = await getImageId("collections", mainImages);

  const { images: restImages, pickedTagId, ...setRest } = set;

  const setImages = await getUpsertImages("sets", restImages.main, restImages.rest);

  const setBe: CreateSetBeV2 = {
    ...setRest,
    amount: Number(set.amount),
    groups: [],
    status: "draft",
    tagTypeId: pickedTagId,
    setImages
  };

  const collection: CreateCreateBeV2 = {
    name,
    shortDescription,
    description,
    public: isPublic,
    imageBrightness: theme,
    imageId: collectionImageId!,
    sets: [setBe]
  };

  const data: UpsertCollectionsDTO<CreateCreateBeV2[]> = {
    collections: [collection]
  };

  const response = await apiClient.sendRequest<CollectionDTO[]>({ method, url, data });
  return response;
};

export const createCollectionNullProduct = async (createNull: CreateNullFeV2) => {
  const method = "PUT";
  const url = `v1/tokens`;

  const { name, shortDescription, description, isPublic, theme, images } = createNull;

  const collectionImageId = await getImageId("collections", images);

  const collection: CreateNullBeV2 = {
    name,
    shortDescription,
    description,
    public: isPublic,
    imageBrightness: theme,
    imageId: collectionImageId!
  };

  const data: UpsertCollectionsDTO<CreateNullBeV2[]> = {
    collections: [collection]
  };

  const response = await apiClient.sendRequest<CollectionDTO[]>({ method, url, data });
  return response;
};

export const updateCollectionCreateProduct = async (updateCreate: UpdateCreateFeV2) => {
  const method = "PUT";
  const url = `v1/tokens`;

  const { name, shortDescription, description, isPublic, theme, images: image, set } = updateCreate;

  const collectionImageId = await getImageId("collections", image!);

  const { images, pickedTagId, ...setRest } = set;

  const setImages = await getUpsertImages("sets", images.main, images.rest);

  const setBe: CreateSetBeV2 = {
    ...setRest,
    setImages,
    groups: [],
    amount: Number(setRest.amount),
    status: "draft",
    tagTypeId: pickedTagId
  };

  const collection: UpdateCreateBeV2 = {
    id: updateCreate.collectionId,
    ...clearEmptyKeys({
      name,
      shortDescription,
      description,
      public: isPublic,
      imageBrightness: theme,
      imageId: collectionImageId
    }),
    sets: [setBe]
  };

  const data: UpsertCollectionsDTO<UpdateCreateBeV2[]> = {
    collections: [collection]
  };

  const response = await apiClient.sendRequest<CollectionDTO[]>({ method, url, data });
  return response;
};

export const updateCollectionUpdateProduct = async (updateUpdate: UpdateUpdateFeV2) => {
  const method = "PUT";
  const url = `v1/tokens`;

  const { name, shortDescription, description, isPublic, theme, images: image, set } = updateUpdate;

  const collectionImageId = await getImageId("collections", image!);

  const { images, pickedTagId, setId, ...setRest } = set;

  const setImages = await getUpsertImages("sets", images?.main, images?.rest);

  const setBe: UpdateSetBeV2 = {
    ...clearEmptyKeys({
      ...setRest,
      id: setId,
      tagTypeId: pickedTagId,
      setImages
    }),

    id: set.setId
  };

  const collection: UpdateUpdateBeV2 = {
    id: updateUpdate.collectionId,
    ...clearEmptyKeys({
      name,
      shortDescription,
      description,
      public: isPublic,
      imageBrightness: theme,
      imageId: collectionImageId
    }),
    sets: [setBe]
  };

  const data: UpsertCollectionsDTO<UpdateUpdateBeV2[]> = {
    collections: [collection]
  };

  const response = await apiClient.sendRequest<CollectionDTO[]>({ method, url, data });
  return response;
};

export const updateCollectionNullProduct = async (updateNull: UpdateNullFeV2) => {
  const method = "PUT";
  const url = `v1/tokens`;

  const { name, shortDescription, description, isPublic, theme, images: image } = updateNull;

  const collectionImageId = await getImageId("collections", image!);

  const collection: UpdateNullBeV2 = {
    id: updateNull.collectionId,
    ...clearEmptyKeys({
      name,
      shortDescription,
      description,
      public: isPublic,
      imageBrightness: theme,
      imageId: collectionImageId
    })
  };

  const data: UpsertCollectionsDTO<UpdateNullBeV2[]> = {
    collections: [collection]
  };

  const response = await apiClient.sendRequest<CollectionDTO[]>({ method, url, data });
  return response;
};

export const publishSet = async (publishProps: PublishSetFeV2) => {
  const method = "PUT";
  const url = `v1/tokens`;

  const setToPublish: PublishSetBeV2 = {
    id: publishProps.setId,
    status: "ready"
  };

  const collection: PublishSetsBeV2 = {
    id: publishProps.collectionId,
    sets: [setToPublish]
  };

  const data: UpsertCollectionsDTO<PublishSetsBeV2[]> = {
    collections: [collection]
  };

  const response = await apiClient.sendRequest<CollectionDTO[]>({ method, url, data });
  return response;
};

export const deleteCollection = async (collectionId: string) => {
  const method = "DELETE";
  const url = `v1/tokens/collections/${collectionId}`;

  const response = await apiClient.sendRequest<string>({ method, url });
  return response;
};

export const deleteSet = async (setId: string) => {
  const method = "DELETE";
  const url = `v1/tokens/sets/${setId}`;

  const response = await apiClient.sendRequest({ method, url });
  return response;
};

export const getCollectionRaport = async (collectionId: string, setIds: string[]) => {
  const method = "GET";
  const url = `v1/tokens/collections/${collectionId}/report`;

  const params = { setIds };

  return await apiClient.sendRequest<Blob>({ method, url, responseType: "arraybuffer", params });
};
//TOKENS--------------------------------------------------

//DEFAULT ------------------------------------------------
//DEFAULT ------------------------------------------------

//TAGS ---------------------------------------------------
//TAGS ---------------------------------------------------
