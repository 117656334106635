import { useState } from "react";
import { useTranslation } from "react-i18next";

import { OrderDTO } from "@api/hubApi/dtos.types";
import { cancelOrder } from "@api/hubApi/methods";

import Portal from "@components/overlay/Portal/Portal";
import Backdrop from "@components/ux/Backdrop/Backdrop";
import Modal from "@components/overlay/Modal/Modal";
import Stack from "@components/arrangement/Stack/Stack";
import Group from "@components/arrangement/Group/Group";
import Button from "@components/common/Buttons/Button";

import "./CancelOrder.scss";
import { useModals } from "@contexts/GlobalModalsContext";

interface CancelOrderProps {
  order: OrderDTO;
}

const CancelOrder = ({ order }: CancelOrderProps) => {
  const [submitting, setSubmitting] = useState<boolean>(false);

  const { closeModal } = useModals();

  const { t } = useTranslation("orderHistory");

  const handleCancelOrder = () => {
    setSubmitting(true);

    cancelOrder(order.id)
      .then((res) => {
        if (res.status === 200) closeModal();
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <Portal>
      <Backdrop isOpen>
        <Modal closeAction={closeModal}>
          <Stack className="cancel-order-modal" alignItems="center">
            <h3 className="t-xs-c">{t("cancelOrderModal.title")}</h3>
            {/* <p className="p3 mt-xs-3 t-xs-c">{t("cancelOrderModal.description")}</p> */}

            <Group colGap={24} fullWidth className="mt-xs-8" justifyContent="center">
              <Button action={handleCancelOrder} disabled={submitting}>
                {t("cancelOrderModal.primary")}
              </Button>
              <Button variant="secondary-btn" action={closeModal}>
                {t("cancelOrderModal.secondary")}
              </Button>
            </Group>
          </Stack>
        </Modal>
      </Backdrop>
    </Portal>
  );
};

export default CancelOrder;
