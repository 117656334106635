import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import { paths } from "src/app/paths";

import Group from "@components/arrangement/Group/Group";
import NavLinks from "@components/project-specific/NavLinks/NavLinks";
import ProfileOptions from "@components/project-specific/ProfileOptions/ProfileOptions";

import { useInitialConfig } from "@contexts/InitialConfigContext";
import "./Navbar.scss";

interface NavbarProps {
  withoutLinks?: boolean;
  withoutProfile?: boolean;
}

const Navbar = ({ withoutLinks = false, withoutProfile = false }: NavbarProps) => {
  const { t } = useTranslation("layout");
  const { pathname } = useLocation();
  const { defaultMainPath } = useInitialConfig();

  const isOrderTagsActive = pathname.includes(paths.order);

  return (
    <div className="navbar">
      <Group fullSize justifyContent="space-between">
        <Link to={defaultMainPath} className="clickable-logo">
          <img src="/images/logos/go2nft-navbar-logo.svg" />
        </Link>
        <Group colGap={24}>
          {!withoutLinks && (
            <>
              <Link
                to={paths.orderGetStarted}
                className={`order-tags uppercase p3 ${isOrderTagsActive ? "active" : ""}`}
                data-testid="nav-order-tags"
              >
                <img src="/images/logos/go2nft-signet.svg" />
                <p className="p3">{t("navLinks.orderTags")}</p>
              </Link>
              <NavLinks />
            </>
          )}
          {!withoutProfile && <ProfileOptions />}
        </Group>
      </Group>
    </div>
  );
};

export default Navbar;
