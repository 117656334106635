import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FirebaseError } from "firebase/app";
import { useTranslation } from "react-i18next";

import useBoolean from "@hooks/useBoolean";
import { useAuth } from "@contexts/AuthContext";
import { useUser } from "@contexts/UserContext";
import { useInitialConfig } from "@contexts/InitialConfigContext";

import Stack from "@components/arrangement/Stack/Stack";
import AuthLogging from "@components/common/LoadingPages/AuthLogging";
import Button from "@components/common/Buttons/Button";
import AuthError from "../AuthError";

import { AuthActionProps } from "../AuthAction.types";

import "./VerifyEmail.scss";

const VerifyEmail = ({ oobCode }: AuthActionProps) => {
  const [isLoading, startLoading, stopLoading] = useBoolean(false);
  const [error, setError] = useState<string>("");

  const navigate = useNavigate();
  const { t } = useTranslation("auth");
  const { t: commonT } = useTranslation("common");
  const { t: tFirebase } = useTranslation("firebase");
  const { defaultMainPath } = useInitialConfig();

  const { verifyEmail } = useAuth();
  const { setUserVerified } = useUser();

  const goToPanel = () => {
    setUserVerified();
    navigate(defaultMainPath);
  };

  const handleError = (error: unknown) => {
    if (error instanceof FirebaseError) {
      const errMessage = tFirebase(error.code as any) || commonT("errorMessage");

      setError(errMessage);
    } else {
      console.error(error);
    }
  };

  useEffect(() => {
    startLoading();
    setError("");

    if (!oobCode) return;

    verifyEmail(oobCode).catch(handleError).finally(stopLoading);
  }, [oobCode]);

  if (isLoading) return <AuthLogging />;

  return (
    <div className="verify-email">
      {!isLoading && !error ? (
        <Stack rowGap={16} alignItems="center" justifyContent="center" fullSize>
          <img src="/images/auth/verify-email-success.svg" alt="success verify account" width={200} height={200} />

          <h2 className="mt-xs-4 regular text-center">{t("verifyEmail.success.title")}</h2>
          <p className="p2">{t("verifyEmail.success.description")}</p>

          <Button action={goToPanel} className="mt-xs-4">
            {t("verifyEmail.success.button")}
          </Button>
        </Stack>
      ) : (
        <AuthError title={t("verifyEmail.error.title")} error={error} />
      )}
    </div>
  );
};

export default VerifyEmail;
