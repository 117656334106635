import React, { useState } from "react";

import { OrderDTO } from "@api/hubApi/dtos.types";
import { IChildren } from "@components/interfaces/IChildren";
import { AxiosError } from "axios";

interface OrderModal {
  type: "cancelOrder";
  object: OrderDTO;
  action: undefined;
}

interface ModalWithError {
  type: "brokenRequest";
  object: AxiosError;
  action: () => void;
}

interface WithoutObjectModal {
  type: "someModal";
  object: null;
  action: undefined;
}

type Modal = OrderModal | WithoutObjectModal | ModalWithError;

interface ContextValue {
  activeModal: Modal | null;
  openModal: <T extends Modal>(type: T["type"], object?: T["object"], action?: T["action"]) => void;
  closeModal: () => void;
}

const GlobalModalsContext = React.createContext(null as any);

export const GlobalModalsProvider = ({ children }: IChildren) => {
  const [activeModal, setActiveModal] = useState<Modal | null>(null);

  const openModal = <T extends Modal>(type: T["type"], object?: T["object"], action?: T["action"]) =>
    setActiveModal({ type, object: object ?? null, action } as Modal);

  const closeModal = () => setActiveModal(null);

  const contextValue: ContextValue = {
    activeModal,
    openModal,
    closeModal
  };

  return <GlobalModalsContext.Provider value={contextValue}>{children}</GlobalModalsContext.Provider>;
};

export const useModals = (): ContextValue => React.useContext(GlobalModalsContext);
