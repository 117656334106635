import React from "react";
import { translateImgFileIntoDropzoneImage, useDropzoneV3 } from "@design-system/Dropzone/DropzoneV3.context";
import { useViewImages } from "../../../../../contexts/ImageWithCropper/ViewImages.context";
import { useGetCompanyDetails } from "../CompanyDetailsContext/GetCompanyDetails.context";
import { ACCEPTED_MIME_TYPES, ACCEPTED_MIME_TYPES_FOR_HUMAN, MAX_SIZE_IMAGE_FILE } from "@constants/constants";
import { useTranslation } from "react-i18next";
import { kbToMb } from "@utils/mbCalculator";
import { useHocCropper } from "@app/routing/Generator/contexts/HocCropperContext/HocCropper.context";
import {
  BRAND_LOGO_IMG_MIN_HEIGHT,
  BRAND_LOGO_IMG_MIN_WIDTH,
  MIN_IMG_HEIGHT,
  MIN_IMG_WIDTH
} from "@app/routing/Generator/constants/constants";
import { useSetBrandsDetails } from "./SetBrandsDetails.context";
import { useRulesBrandsDetails } from "./RulesBrandsDetails.context";

interface Props {
  children: React.ReactNode;
}

const EffectsImagesBrands = ({ children }: Props) => {
  const { t } = useTranslation("validations");
  const { companyUserResponse, isCompanyUserLoading } = useGetCompanyDetails();

  const { localValidation, file } = useSetBrandsDetails().brandLogoDropzone;

  const { mainImage, uplaodInitialMainImage, validateAndShowErrors, imageValidation, deleteMainImage } =
    useDropzoneV3();

  const { croppedImage, uploadCropperImage } = useHocCropper().cropper;

  const { modifyViewState } = useViewImages();

  React.useEffect(() => {
    if (!isCompanyUserLoading && !companyUserResponse?.client.partner.logoBackgroundUrl) {
      modifyViewState("placeholder");
    }
  }, [companyUserResponse?.client.partner.logoBackgroundUrl]);

  React.useEffect(() => {
    if (!mainImage?.file) return;

    const finalErrors: string[] = [];

    const maxSizeError =
      mainImage.file.size > MAX_SIZE_IMAGE_FILE
        ? t("imageTooBig", { maxSize: `${kbToMb(MAX_SIZE_IMAGE_FILE)}MB` })
        : "";
    const wrongMimeType = !ACCEPTED_MIME_TYPES.includes(mainImage.file.type)
      ? t("invalidMimeType", { mimeTypes: `${ACCEPTED_MIME_TYPES_FOR_HUMAN.join(", ")}` })
      : "";

    finalErrors.push(maxSizeError, wrongMimeType);

    const reader = new FileReader();
    const img = new Image();

    reader.onload = function (e) {
      img.src = e.target!.result as string;

      img.onload = function () {
        if (img.width < MIN_IMG_WIDTH) {
          finalErrors.push(t("widthTooSmall", { minWidth: MIN_IMG_WIDTH }));
        }
        if (img.height < MIN_IMG_HEIGHT) {
          finalErrors.push(t("heightToSmall", { minHeight: MIN_IMG_HEIGHT }));
        }
        validateAndShowErrors(finalErrors.filter((err) => !!err));
      };
    };

    reader.readAsDataURL(mainImage.file);
  }, [mainImage?.file]);

  React.useEffect(() => {
    const isImgUrl = typeof file === "string";
    if (!file || isImgUrl) return;
    localValidation.resetToInitial();

    const finalErrors: string[] = [];
    const reader = new FileReader();
    const img = new Image();

    reader.onload = function (e) {
      img.src = e.target!.result as string;

      img.onload = function () {
        if (img.width < BRAND_LOGO_IMG_MIN_WIDTH) {
          finalErrors.push(t("widthTooSmall", { minWidth: BRAND_LOGO_IMG_MIN_WIDTH }));
        }
        if (img.height < BRAND_LOGO_IMG_MIN_HEIGHT) {
          finalErrors.push(t("heightToSmall", { minHeight: BRAND_LOGO_IMG_MIN_HEIGHT }));
        }
        localValidation.validateAndShowErrors(finalErrors);
      };
    };

    reader.readAsDataURL(file);
  }, [file]);

  React.useEffect(() => {
    if (imageValidation.errors.length > 0) {
      deleteMainImage();
      modifyViewState("placeholder");
    }
  }, [imageValidation.errors]);

  React.useEffect(() => {
    if (!mainImage?.file) return;

    uploadCropperImage(mainImage.file);

    modifyViewState("cropper");
  }, [mainImage?.file]);

  React.useEffect(() => {
    if (!croppedImage) return;

    modifyViewState("image");
  }, [croppedImage]);

  React.useEffect(() => {
    if (!!companyUserResponse && !isCompanyUserLoading && !companyUserResponse.client.partner.logoBackgroundUrl) {
      modifyViewState("placeholder");
    }
  }, [companyUserResponse, isCompanyUserLoading, companyUserResponse?.client.partner.logoBackgroundUrl]);

  //UPLOAD IMAGE RIGHT AFTER GET
  React.useEffect(() => {
    if (
      !!companyUserResponse?.client.partner.logoBackgroundUrl &&
      !mainImage?.file &&
      !croppedImage &&
      !!companyUserResponse?.id
    ) {
      const translatedImage = translateImgFileIntoDropzoneImage({
        fileUrl: companyUserResponse.client.partner.logoBackgroundUrl
      });

      uplaodInitialMainImage(translatedImage);
      modifyViewState("image");
    }
  }, [mainImage?.file, croppedImage, companyUserResponse?.id, companyUserResponse?.client.partner.logoBackgroundUrl]);

  return <>{children}</>;
};

export default EffectsImagesBrands;
