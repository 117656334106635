import React from "react";

import { IChildren } from "@components/interfaces/IChildren";
import { useCompanyProfile } from "../CompanyProfileProvider";
import useRequest from "@hooks/useRequest";
import { UserDTO } from "@api/hubApi/dtos.types";
import { updateCompany } from "@api/hubApi/methods";
import { clearEmptyKeys } from "@utils/clearEmptyKeys";
import { useSetPartnerDetails } from "../PartnerDetailsContext/SetPartnerDetails.context";
import { useCountries } from "@contexts/CountriesContext";
import { useSetCompanyDetails } from "./SetCompanyDetails.context";
import { useTranslation } from "react-i18next";

interface IUpdateCompanyDetailsContext {
  handleUpdateCompanyDetails: () => Promise<void>;
}

const UpdateCompanyDetailsContext = React.createContext(null as any);

export const UpdateCompanyDetailsProvider = ({ children }: IChildren) => {
  const { t } = useTranslation("profile");

  const { countries } = useCountries();
  const { runToastOnError, runToastOnStart, runToastOnSuccess, companyPartnerInputChanged } = useCompanyProfile();

  const { companyDataForm } = useSetCompanyDetails();
  const {
    address: companyAddress,
    city: companyCity,
    country: companyCountry,
    discount: companyDiscount,
    name: companyName,
    nip: companyNip,
    postCode: companyPostCode
  } = companyDataForm.fields;

  const { isPublic, partnerForm, partnerTags, handleDefaultIsPublic } = useSetPartnerDetails();
  const { fields, validate } = partnerForm;
  const { description: partnerDescription, email: partnerEmail, tag: partnerTag, url: partnerUrl } = fields;

  const prepareUpdateObject = () => {
    const savedAddress = clearEmptyKeys({
      companyName: companyName.value,
      city: companyCity.value,
      postCode: companyPostCode.value,
      addressLine: companyAddress.value,
      countryId: countries?.find((item) => item.name === companyCountry.value)?.id
    });
    const company = clearEmptyKeys({ taxNumber: companyNip.value, savedAddress });
    if (!isPublic) return { company };
    return {
      company,
      partner: clearEmptyKeys({
        name: companyName.value,
        email: partnerEmail.value,
        url: partnerUrl.value,
        tags: partnerTags,
        description: partnerDescription.value
      })
    };
  };

  const { requestTrigger: updateCompanyRequest, response: companyUser } = useRequest<UserDTO>({
    requestFn: () => updateCompany(prepareUpdateObject()),
    beforeStart: () => {
      runToastOnStart();
      companyPartnerInputChanged.disableButton();
    },
    onSuccessFn: () => {
      runToastOnSuccess(isPublic ? "Dziękujemy. Twoje dane zostały przesłane do weryfikacji" : "Dane zostały zapisane");
      handleDefaultIsPublic("in_progress");
    }, //TODO text

    onError: () => runToastOnError(),
    onFinally: companyPartnerInputChanged.resetButton
  });

  const handleUpdateCompanyDetails = async () => {
    companyDataForm.validate();
    if (isPublic) partnerForm.validate();
    if (isPublic && !partnerTags.length) {
      partnerTag.setError(t("partnerProfile.inputs.tags.error"));
      return companyPartnerInputChanged.resetButton();
    }
    if (isPublic && !partnerForm.isValid) return;
    if (!companyDataForm.isValid) return;

    await updateCompanyRequest();
  };

  return (
    <UpdateCompanyDetailsContext.Provider value={{ handleUpdateCompanyDetails }}>
      {children}
    </UpdateCompanyDetailsContext.Provider>
  );
};

export const useUpdateCompanyDetails = (): IUpdateCompanyDetailsContext =>
  React.useContext(UpdateCompanyDetailsContext);
