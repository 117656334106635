import { EMAIL_REGEXP, PHONE_REGEXP, URL_REGEXP } from "@constants/regexps";
import { parseNumber } from "./parseNumber";

export interface ValidationPattern {
  isRequired?: boolean;
  minLength?: number;
  maxLength?: number;
  isInteger?: boolean;
  isPureString?: boolean;
  isClassicString?: boolean;
  isPositive?: boolean;
  isNumber?: boolean;
  minNumber?: number;
  maxNumber?: number;
  isEmail?: boolean;
  isUrl?: boolean;
  isPhone?: boolean;
  regexp?: RegExp;
}

export const validateInput = (newValue: string, validationSchema: ValidationPattern, t: Function) => {
  const {
    regexp,
    isInteger,
    isPureString,
    isClassicString,
    isPositive,
    isNumber,
    maxLength,
    maxNumber,
    minLength,
    minNumber,
    isRequired,
    isEmail,
    isUrl,
    isPhone
  } = validationSchema;
  const errors = [];

  if (typeof isRequired === "boolean" && isRequired === false && !newValue) return "";

  if (typeof isRequired === "boolean" && isRequired === true) {
    if (newValue.length === 0) {
      errors.push(t("required"));
    }
  }
  if (typeof maxLength === "number" && typeof minLength === "number" && maxLength < minLength) {
    throw new Error("Min length cannot be greater than Max length");
  }

  if (typeof minLength === "number" && minLength >= 0) {
    if (newValue.trim().length < minLength) {
      errors.push(`${t("minLength")} ${minLength}`);
    }
  }

  if (typeof maxLength === "number" && maxLength >= 0) {
    if (newValue.trim().length > maxLength) {
      errors.push(`${t("maxLength")} ${maxLength}`);
    }
  }

  if (typeof isNumber === "boolean" && isNumber === true) {
    const parsed = Number(newValue.trim());
    if (isNaN(parsed)) {
      errors.push(t("notANumber"));
    } else {
      if (typeof isPositive === "boolean" && isPositive === true && parsed <= 0) {
        errors.push(t("notANumber"));
      }
    }
  }

  if (typeof isClassicString === "boolean" && isClassicString === true) {
    if (typeof newValue !== "string") {
      errors.push(t("shouldBeString"));
    }
  }

  if (typeof isPureString === "boolean" && isPureString === true) {
    if (/\d/.test(newValue)) {
      errors.push(t("pureString"));
    }
  }

  if (typeof isInteger === "boolean" && isInteger === true) {
    const parsed = Number(newValue.trim());
    if (isNaN(parsed)) {
      errors.push(t("notANumber"));
    }
    if (!Number.isInteger(parsed)) {
      errors.push(t("numberMustBeInteger"));
    }
  }

  // if (typeof minNumber === "number" && typeof maxNumber === "number" && maxNumber < minNumber) {
  //   throw new Error("Min number cannot be greater or equal to max number");
  // }

  if (typeof minNumber === "number") {
    const parsed = parseNumber(newValue.trim());
    if (parsed < minNumber) {
      errors.push(`${t("minNumber")} ${minNumber}`);
    }
  }

  if (typeof maxNumber === "number") {
    const parsed = parseNumber(newValue.trim());
    if (parsed > maxNumber) {
      errors.push(`${t("maxNumber")} ${maxNumber}`);
    }
  }

  if (typeof isEmail === "boolean" && isEmail === true) {
    if (!EMAIL_REGEXP.test(newValue)) {
      errors.push(t("emailNotCorrect"));
    }
  }

  if (typeof isUrl === "boolean" && isUrl === true) {
    if (!URL_REGEXP.test(newValue)) {
      errors.push(t("urlNotCorrect"));
    }
  }

  if (typeof isPhone === "boolean" && isPhone === true) {
    if (!PHONE_REGEXP.test(newValue)) {
      errors.push(t("phoneNotCorrect"));
    }
  }

  if (regexp instanceof RegExp) {
    if (!regexp.test(newValue)) {
      errors.push(t("fieldNotValid"));
    }
  }

  return errors.join(", ");
};
