import React from "react";

import { IChildren } from "@components/interfaces/IChildren";
import { updateBrands } from "@api/hubApi/methods";
import useRequestV2 from "@hooks/useRequestV2";
import { useCompanyProfile } from "../CompanyProfileProvider";
import { useSetBrandsDetails } from "./SetBrandsDetails.context";
import { UpdateBrandsFe } from "@api/hubApi/dtos.types";
import { useHocCropper } from "@app/routing/Generator/contexts/HocCropperContext/HocCropper.context";
import { useRulesBrandsDetails } from "./RulesBrandsDetails.context";

interface IUpdateBrandDetailsContext {
  triggerUpdateBrands: () => void;
  isUpdatingBrands: boolean;
  handleUpdateBrands: () => void;
}

const UpdateBrandDetailsContext = React.createContext(null as any);

export const UpdateBrandDetailsProvider = ({ children }: IChildren) => {
  const { runToastOnError, runToastOnStart, runToastOnSuccess } = useCompanyProfile();
  const { colorPicker, bgImageDropzone, brandLogoDropzone } = useSetBrandsDetails();

  const { cropper, onlyShowErrors } = useHocCropper();

  const { areBgImageErrors, isCropperError } = useRulesBrandsDetails();

  const brands: UpdateBrandsFe = {
    logoTransparentFile: {
      file: typeof brandLogoDropzone.file === "string" ? null : brandLogoDropzone.file,
      fileId: null,
      fileName: null,
      fileUrl: ""
    },
    logoBackgroundFile: {
      original: bgImageDropzone.mainImage!,
      cropped: cropper.croppedImage!
    },
    logoBgColor: colorPicker.bg
  };

  const [triggerUpdateBrands, isUpdatingBrands, updatedBrands, updatedBrandsError] = useRequestV2({
    beforeStart: runToastOnStart,
    requestFn: () => updateBrands({ partner: brands }),
    onSuccessFn: () => {
      colorPicker.resetIsColorChanged();
      brandLogoDropzone.resetIsImageChanged();
      bgImageDropzone.resetIsImageChanged();
      runToastOnSuccess();
    },
    onError: () => runToastOnError()
  });

  const _validate = () => {
    onlyShowErrors();
    bgImageDropzone.onlyShowErrors();
  };

  const handleUpdateBrands = () => {
    areBgImageErrors || isCropperError ? _validate() : triggerUpdateBrands({});
  };

  return (
    <UpdateBrandDetailsContext.Provider value={{ triggerUpdateBrands, isUpdatingBrands, handleUpdateBrands }}>
      {children}
    </UpdateBrandDetailsContext.Provider>
  );
};

export const useUpdateBrandDetails = (): IUpdateBrandDetailsContext => React.useContext(UpdateBrandDetailsContext);
