import Grid from "@components/arrangement/Grid/Grid";
import GridCol from "@components/arrangement/Grid/GridCol";
import { IChildren } from "@components/interfaces/IChildren";
import Footer from "@components/layoutElements/Footer/Footer";
import "./AuthLayout.scss";

const AuthLayout = ({ children }: IChildren) => {
  return (
    <div className="auth-layout">
      <main className="content">
        <Grid>
          <GridCol className={"xs-12 md-5 justify-center"}>{children}</GridCol>
          <GridCol className={"d-xs-n xs-0 md-6 m-md-a"}>
            <img src="/images/auth/auth-image.png" />
          </GridCol>
        </Grid>
      </main>
      <footer className="auth-footer">
        <Footer />
      </footer>
    </div>
  );
};

export default AuthLayout;
