import { UseFormFields } from "@hooks/types/useForm.types";

export type ProfilePartnerKeys = "email" | "url" | "tag" | "description";

export const partnerFormSchema: Readonly<UseFormFields<ProfilePartnerKeys>> = {
  email: {
    initialValue: "",
    validationPattern: {
      isEmail: true,
      isRequired: true
    }
  },
  tag: {
    initialValue: "",
    validationPattern: {
      minLength: 2,
      isRequired: false
    }
  },
  description: {
    initialValue: "",
    validationPattern: {
      minLength: 2,
      isRequired: true
    }
  },
  url: {
    initialValue: "",
    validationPattern: {
      isUrl: true,
      isRequired: true
    }
  }
};
