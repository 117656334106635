import { paths } from "@app/paths";

export default {
  path: paths.orderCongratulations,
  children: [],
  lazy: async () => {
    const { Congratulations } = await import("./Congratulations");

    return {
      element: <Congratulations />
    };
  }
};
