import React from "react";

import { IChildren } from "@components/interfaces/IChildren";
import { useSetPasswordDetails } from "./SetPasswordDetails.context";

interface IRulesPasswordDetailsContext {
  arePasswordsEqual: boolean;
}

const RulesPasswordDetailsContext = React.createContext(null as any);

export const RulesPasswordDetailsProvider = ({ children }: IChildren) => {
  const { changePasswordForm } = useSetPasswordDetails();
  const { confirmPassword, password } = changePasswordForm.fields;

  const arePasswordsEqual = password.value === confirmPassword.value;

  return (
    <RulesPasswordDetailsContext.Provider value={{ arePasswordsEqual }}>
      {children}
    </RulesPasswordDetailsContext.Provider>
  );
};

export const useRulesPasswordDetails = (): IRulesPasswordDetailsContext =>
  React.useContext(RulesPasswordDetailsContext);
