import { ToastState } from "@contexts/GlobalToastsV2Context";

import Portal from "@components/overlay/Portal/Portal";
import Group from "@components/arrangement/Group/Group";
import Spinner from "@design-system/Spinner/Spinner";

const ToastV2 = ({
  hideToast,
  id,
  message,
  toastState,
  variant,
  withLoading
}: ToastState & { hideToast: (id: string) => void }) => {
  const innerClassName = `ds-toast ds-toast-${variant} ds-toast-${toastState}`;

  const messageContent = <p className="p1 white-1">{message}</p>;

  return (
    <Portal>
      <div className={innerClassName}>
        {withLoading ? (
          <Group colGap={16} fullSize>
            <Spinner spinnerImgSrc={"/images/loaders/tertiary-loader.png"} sizePx={24} />
            {messageContent}
          </Group>
        ) : (
          messageContent
        )}
      </div>
    </Portal>
  );
};

export default ToastV2;
