import React, { SetStateAction } from "react";

import { clearSessionStorageItems, readSessionStorage, writeSessionStorage } from "@utils/storage";

import { IChildren } from "@components/interfaces/IChildren";

interface UserCategoryValue {
  category: ICategories;
  setCategory: React.Dispatch<SetStateAction<ICategories>>;
  resetCategory: () => void;
  canGoNextFromCategory: boolean;
}

const OrderDataContext = React.createContext(null as any);

export type ICategories = "Fashion" | "Sport and Lifestyle" | "Beauty" | null;

export const CATEGORIES_SESSION_STORAGE_KEY = "category_key";

export const CategoryProvider = ({ children }: IChildren) => {
  const initialCategory = JSON.parse(readSessionStorage(CATEGORIES_SESSION_STORAGE_KEY) || "null") as ICategories;
  const [category, setCategory] = React.useState<ICategories>(initialCategory);

  const resetCategory = () => {
    setCategory(null);
    clearSessionStorageItems([CATEGORIES_SESSION_STORAGE_KEY]);
  };

  const canGoNextFromCategory = !!category;

  //handle for session storage
  React.useEffect(() => {
    if (!!category) {
      writeSessionStorage(CATEGORIES_SESSION_STORAGE_KEY, category);
    }
  }, [category]);

  return (
    <OrderDataContext.Provider
      value={{
        category,
        setCategory,
        resetCategory,
        canGoNextFromCategory
      }}
    >
      {children}
    </OrderDataContext.Provider>
  );
};

export const useCategory = (): UserCategoryValue => React.useContext(OrderDataContext);
