import React from "react";

import { IChildren } from "@components/interfaces/IChildren";
import { IFeNfcTag, useSetNfcTags } from "./SetNfcTags.context";

interface IViewNfcTagsContext {
  areTagsPresent: boolean;
  areErrorsPresent: boolean;
  atLeastOneTagAmountIsBiggerThanMinQuantityAndBoxIsChecked: boolean;
  canGoNextFromOrderNFCTags: boolean;
  onlyValidTags: IFeNfcTag[];
}

const ViewNfcTagsContext = React.createContext(null as any);

export const RulesNfcTagsProvider = ({ children }: IChildren) => {
  const { feNfcTags } = useSetNfcTags();

  const areTagsPresent = !!feNfcTags && feNfcTags?.length > 0;

  const areErrorsPresent = feNfcTags?.some((tag) => tag.errorMsg.length > 0);
  const atLeastOneTagAmountIsBiggerThanMinQuantityAndBoxIsChecked = feNfcTags?.some(
    (tag) => Number(tag.amount) >= tag.min
  );

  const onlyValidTags = feNfcTags?.filter((feTag) => Number(feTag.amount) > 0 && feTag.errorMsg.length === 0);

  const canGoNextFromOrderNFCTags =
    areTagsPresent && atLeastOneTagAmountIsBiggerThanMinQuantityAndBoxIsChecked && !areErrorsPresent;
  return (
    <ViewNfcTagsContext.Provider
      value={{
        areTagsPresent,
        areErrorsPresent,
        atLeastOneTagAmountIsBiggerThanMinQuantityAndBoxIsChecked,
        canGoNextFromOrderNFCTags,
        onlyValidTags
      }}
    >
      {children}
    </ViewNfcTagsContext.Provider>
  );
};

export const useRulesNfcTags = (): IViewNfcTagsContext => React.useContext(ViewNfcTagsContext);
