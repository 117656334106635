import { Outlet } from "react-router-dom";
import { paths } from "../../paths";

import Recover from "./Recover/Recover";
import RegisterConfirm from "./RegisterConfirm/RegisterConfirm";
import AuthAction from "./AuthAction/AuthAction";
import RecoverEmailConfirmation from "./Recover/RecoverEmailConfirmation";
import LoginRoute from "./Login/LoginRoute";
import RegisterRoute from "./Register/RegisterRoute";

const Auth = () => {
  return <Outlet />;
};

export default {
  path: paths.auth,
  element: <Auth />,
  children: [LoginRoute, RegisterRoute, Recover, RegisterConfirm, AuthAction, RecoverEmailConfirmation]
};
