import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { IChildren } from "@components/interfaces/IChildren";
import { paths } from "src/app/paths";

interface OrderFlowElement {
  previous: (typeof paths)[keyof typeof paths] | "-1";
  path: (typeof paths)[keyof typeof paths];
  next: (typeof paths)[keyof typeof paths];
}

export const getStarted: OrderFlowElement = {
  previous: "-1",
  path: paths.orderGetStarted,
  next: paths.orderCategory
};
export const categories: OrderFlowElement = {
  previous: paths.orderGetStarted,
  path: paths.orderCategory,
  next: paths.orderNfc
};
export const orderNFC: OrderFlowElement = {
  previous: paths.orderCategory,
  path: paths.orderNfc,
  next: paths.orderPaymentDelivery
};
export const paymentAndDelivery: OrderFlowElement = {
  previous: paths.orderNfc,
  path: paths.orderPaymentDelivery,
  next: paths.orderCongratulations
};
export const congratulations: OrderFlowElement = {
  previous: paths.orderPaymentDelivery,
  path: paths.orderCongratulations,
  next: paths.orderHistory
};

const orderForward = (locationPathname: string) => {
  switch (locationPathname) {
    case getStarted.path:
      return getStarted.next;
    case categories.path:
      return categories.next;
    case orderNFC.path:
      return orderNFC.next;
    case paymentAndDelivery.path:
      return paymentAndDelivery.next;
    case congratulations.path:
      return congratulations.next;
    default:
      return paths.orderGetStarted;
  }
};

const orderBackward = (locationPathname: string) => {
  switch (locationPathname) {
    case getStarted.path:
      return getStarted.previous;
    case categories.path:
      return categories.previous;
    case orderNFC.path:
      return orderNFC.previous;
    case paymentAndDelivery.path:
      return paymentAndDelivery.previous;
    case congratulations.path:
      return congratulations.previous;
    default:
      return "-1";
  }
};

interface OrderFlowValue {
  onNext: () => void;
  onPrev: () => void;
  navigateToTarget: (pathname: string) => void;
}

const OrderFlowContext = React.createContext(null as any);

export const OrderNavigationProvider = ({ children }: IChildren) => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentLocation = location.pathname;

  const onNext = () => navigate(orderForward(currentLocation));
  const onPrev = () => navigate(orderBackward(currentLocation));

  const navigateToTarget = (pathname: string) => navigate(pathname);

  return <OrderFlowContext.Provider value={{ onNext, onPrev, navigateToTarget }}>{children}</OrderFlowContext.Provider>;
};

export const useOrderNavigation = (): OrderFlowValue => React.useContext(OrderFlowContext);
