import React from "react";

import { IChildren } from "@components/interfaces/IChildren";

const GlobalToastV3Context = React.createContext(null as any);

type ToastState = "active" | "inactive" | "default";

type TColor = "success" | "danger" | "black" | "info" | "warning";

export type ToastContent = {
  bg: TColor;
  message: string;
  isLoading: boolean;
  closeAfterMs?: number;
};

const initial: ToastContent = {
  bg: "black",
  message: "",
  isLoading: false
};

interface IGlobalToastV3Context {
  toastState: ToastState;
  toastContent: ToastContent;
  updateToast: (data: ToastContent) => void;
  closeToast: () => void;
}

export const GlobalToastV3Provider = ({ children }: IChildren) => {
  const [toast, setToast] = React.useState<ToastContent>(initial);
  const [toastState, setToastState] = React.useState<ToastState>("default");

  const timeoutRef = React.useRef<NodeJS.Timeout | null>(null);

  const cleanupTimer = () => {
    clearTimeout(timeoutRef.current!);
    timeoutRef.current = null;
  };

  const updateToast = (data: ToastContent) => {
    setToast(data);
    setToastState("active");

    if (!!data.closeAfterMs) {
      timeoutRef.current = setTimeout(() => {
        closeToast();
        clearTimeout(timeoutRef.current!);
      }, data.closeAfterMs);
    } else {
      cleanupTimer();
    }
  };

  const closeToast = () => setToastState("inactive");

  React.useEffect(() => {
    if (toastState === "inactive") {
      timeoutRef.current = setTimeout(() => {
        setToastState("default");
        clearTimeout(timeoutRef.current!);
      }, 800);
    } else if (timeoutRef.current) {
      cleanupTimer();
    }
  }, [toastState]);

  return (
    <GlobalToastV3Context.Provider value={{ toastState, toastContent: toast, updateToast, closeToast }}>
      {children}
    </GlobalToastV3Context.Provider>
  );
};

export const useGlobalToastV3 = (): IGlobalToastV3Context => React.useContext(GlobalToastV3Context);
