import Group from "@components/arrangement/Group/Group";
import { socialButtons } from "src/constants/socials";
import "./SocialButtons.scss";

interface ISocialButton {
  url: string;
  img: string;
  name: string;
}

const SocialButton = ({ img, url, name }: ISocialButton) => {
  return (
    <a className="social-button" rel="nofollow noreferrer noopener" title={name} href={url} target="_blank">
      <img src={img} alt={`Social media - ${name}`} width="30" height="30" />
    </a>
  );
};

const SocialButtons = () => {
  return (
    <Group className="social-buttons" colGap={24}>
      {socialButtons.map((socialButton, index) => (
        <SocialButton {...socialButton} key={index} />
      ))}
    </Group>
  );
};

export default SocialButtons;
