import React from "react";

import { IChildren } from "@components/interfaces/IChildren";
import { useUser } from "@contexts/UserContext";

interface IViewCompanyProfileContext {
  avatar: string;
  email: string;
}

const ViewCompanyProfileContext = React.createContext(null as any);

export const ViewCompanyProfileProvider = ({ children }: IChildren) => {
  const { user } = useUser();

  const emptyAvatar = "/images/dev-avatar.svg";
  const OAuthAvatar = user?.photoURL;
  const destinationAvatarUrl = OAuthAvatar || emptyAvatar;
  const companyEmail = user?.email || ""; //change to company email from db

  return (
    <ViewCompanyProfileContext.Provider value={{ avatar: destinationAvatarUrl, email: companyEmail }}>
      {children}
    </ViewCompanyProfileContext.Provider>
  );
};

export const useViewCompanyProfile = (): IViewCompanyProfileContext => React.useContext(ViewCompanyProfileContext);
