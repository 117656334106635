import { IChildren } from "@components/interfaces/IChildren";
import "./Group.scss";
import { BaseProps } from "@components/interfaces/StyledComponent";

export interface IGroup extends BaseProps {
  colGap?: number;
  rowGap?: number;
  justifyContent?: "flex-start" | "center" | "flex-end" | "space-around" | "space-between" | "space-evenly";
  alignItems?: "flex-start" | "center" | "flex-end";
  className?: string;
  grid?: boolean;
  fullSize?: boolean;
  fullWidth?: boolean;
  testid?: string;
  onMouseEnter?(): void;
  onMouseLeave?(): void;
  style?: React.CSSProperties;
  reff?: React.RefObject<HTMLDivElement>;
}

const Group = ({
  colGap,
  rowGap,
  children,
  justifyContent = "flex-start",
  alignItems = "center",
  grid = false,
  fullSize = false,
  fullWidth = false,
  testid,
  className = "",
  onMouseEnter,
  onMouseLeave,
  style,
  reff,
  ...rest
}: IGroup & IChildren) => {
  const classes = `${className ? ` ${className}` : ""}${grid ? " grid full-size" : ""}${
    fullSize && !grid ? " full-size" : ""
  }${fullWidth ? " full-width" : ""} group`;
  return (
    <div
      className={classes}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={rest.onClick}
      data-testid={testid}
      style={{
        gap: `${rowGap ? rowGap : `0`}px ${colGap ? colGap : "0"}px`,
        justifyContent,
        alignItems,
        ...style
      }}
      ref={reff}
      {...rest}
    >
      {children}
    </div>
  );
};

export default Group;
