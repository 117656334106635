import { useTranslation } from "react-i18next";

import { AccordionProps, AccordionTabProps } from "./Accordion.types";
import AccordionTab from "./AccordionTab/AccordionTab";

import "./Accordion.scss";

const Accordion = ({ questions, className = "" }: AccordionProps) => {
  const { t } = useTranslation("faq");
  const classes = `accordion ${className}`;

  return (
    <div className={classes}>
      {questions.map((accordionItem: AccordionTabProps, index) => {
        const question = t(accordionItem.question as any);
        const answer = t(accordionItem.answer as any);

        return <AccordionTab key={index} {...accordionItem} answer={answer} question={question} />;
      })}
    </div>
  );
};

export default Accordion;
