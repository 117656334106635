import React from "react";

import { IChildren } from "@components/interfaces/IChildren";
import useForm from "@hooks/useForm";
import { ShippingKeys, shippingFormSchema } from "@constants/profile/userDataFormSchema";
import { UseFormResponse } from "@hooks/types/useForm.types";
import { IInputChange, useInputChange } from "@hooks/useInputChange";

interface ISetUserDetailsContext {
  shippingDataForm: UseFormResponse<ShippingKeys>;
  inputChanged: IInputChange;
}

const SetUserDetailsContext = React.createContext(null as any);

export const ORDER_SHIPPING_DATA = "order_shipping_data";

export const SetUserDetailsProvider = ({ children }: IChildren) => {
  const inputChanged = useInputChange();

  const shippingDataForm = useForm<ShippingKeys>(shippingFormSchema, {
    doStore: true,
    storageKey: ORDER_SHIPPING_DATA,
    storageType: "session-storage"
  });

  return (
    <SetUserDetailsContext.Provider value={{ shippingDataForm, inputChanged }}>
      {children}
    </SetUserDetailsContext.Provider>
  );
};

export const useSetUserDetails = (): ISetUserDetailsContext => React.useContext(SetUserDetailsContext);
