import { paths } from "@app/paths";

export default {
  path: paths.generatorCollectionsView,
  children: [],
  lazy: async () => {
    const { CollectionsView } = await import("./CollectionsView");

    return {
      element: <CollectionsView />
    };
  }
};
