import React from "react";

import { IChildren } from "@components/interfaces/IChildren";
import { useSetCompanyDetails } from "./SetCompanyDetails.context";
import { useGetCompanyDetails } from "./GetCompanyDetails.context";
import { DEFAULT_DISCOUNT } from "@constants/company";

interface IEffectsCompanyDetailsContext {}

const EffectsCompanyDetailsContext = React.createContext(null as any);

export const EffectsCompanyDetailsProvider = ({ children }: IChildren) => {
  const { companyUserResponse } = useGetCompanyDetails();
  const { companyDataForm } = useSetCompanyDetails();

  React.useEffect(() => {
    if (!companyUserResponse) return;

    const { company } = companyUserResponse.client;
    const deliveryRequirements = company.savedAddress;

    const { address, city, country, discount, name, nip, postCode } = companyDataForm.fields;

    name.update(deliveryRequirements?.companyName ?? "");
    nip.update(company?.taxNumber ?? "");
    city.update(deliveryRequirements?.city ?? "");
    postCode.update(deliveryRequirements?.postCode ?? "");
    address.update(deliveryRequirements?.addressLine ?? "");
    country.update(deliveryRequirements?.country?.name ?? "");
    discount.update(company?.discount ?? DEFAULT_DISCOUNT);
  }, [companyUserResponse]);

  return <EffectsCompanyDetailsContext.Provider value={{}}>{children}</EffectsCompanyDetailsContext.Provider>;
};

export const useEffectsCompanyDetails = (): IEffectsCompanyDetailsContext =>
  React.useContext(EffectsCompanyDetailsContext);
