import React from "react";

import { ACCEPTED_MIME_TYPES, ACCEPTED_MIME_TYPES_FOR_HUMAN, MAX_SIZE_IMAGE_FILE } from "@constants/constants";

import useBoolean from "@hooks/useBoolean";
import useCleanup from "@hooks/useCleanup";
import { FeImageDTO, SetImageDTO } from "@api/hubApi/dtos.types";
import createImgUrl from "@utils/createImgUrl";
import { useTranslation } from "react-i18next";
import useValidation, { ValidationFields } from "@hooks/useValidation";
import { kbToMb } from "@utils/mbCalculator";

export type DropzoneImage = {
  fileUrl: string;
  fileName: string;
  id: string;
};

interface Args {
  file?: File;
  fileId?: string;
  fileUrl?: string;
  fileName?: string;
}

export const translateProductImageIntoMainImage = (args: SetImageDTO): FeImageDTO => {
  return {
    file: null,
    fileId: args.fileId || "",
    fileName: args.fileName || null,
    fileUrl: args.fileUrl
  };
};

export const translateProductImagesIntoRestImages = (args: SetImageDTO[]): FeImageDTO[] => {
  return args.map((arg) => translateProductImageIntoMainImage(arg));
};

export const translateImgFileIntoDropzoneImage = (args: Args): FeImageDTO => {
  const finalFileUrl = !!args.file && !args.fileUrl ? createImgUrl(args.file) : !!args.fileUrl ? args.fileUrl : "";
  const finalFileName = args.file && !args.fileName ? args.file.name : !!args.fileName ? args.fileName : "";

  const obj = { file: args.file || null, fileId: args.fileId || "", fileName: finalFileName, fileUrl: finalFileUrl };

  return obj;
};

export const translateImgFilesIntoRestImages = (setImages: SetImageDTO[]): FeImageDTO[] => {
  return setImages
    .filter((setImage) => !setImage.main)
    .map((setImage) => translateImgFileIntoDropzoneImage({ ...setImage }));
};
type FileDestination = "main" | "regular";

export interface IDropzoneV3 {
  mainImage: FeImageDTO | null;
  uploadMainImage: (file: File) => void;
  uplaodInitialMainImage: (feDropzoneImage: FeImageDTO | null) => void;
  regularImages: FeImageDTO[] | null;
  uploadInitialRegularImages: (feDropzoneImages: FeImageDTO[] | null) => void;

  inputRef: React.MutableRefObject<HTMLInputElement>;
  fileDestination: FileDestination;

  deleteMainImage: () => void;
  deleteRegularImage: (imageIndex: number) => void;

  canUploadMultiple: boolean;
  enableUploadMultiple: () => void;
  disableUploadMultiple: () => void;

  openInput: () => void;
  onChangeFiles: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onDropFiles: (e: React.DragEvent<HTMLInputElement>) => void;

  imagesLimit: number;
  totalImagesCount: number;

  isImageChanged: boolean;
  resetIsImageChanged: () => void;

  clearDropzone: () => void;

  imageValidation: ValidationFields;
  onlyShowErrors: () => void;
  validateOnlyErrors: (errors: string[]) => void;
  validateAndShowErrors: (errors: string[]) => void;

  validateAndShowErrorsRegularImages: (errors: string[]) => void;
  regularImagesValidationFields: ValidationFields;
}

export const DropzoneContextV3 = React.createContext(null as any);

interface ImagesValidationRules {
  eachImageMaxSize?: {
    value: number;
    error: string;
  };
  acceptedMimeTypes?: {
    value: string[];
    error: string;
  };
  isImageRequired?: {
    value: boolean;
    error: string;
  };
}

interface IDropzoneProviderProps {
  imagesLimit?: number;
  initialCanUploadMultiple: boolean;
  initialImage: FeImageDTO | null;
  initialRestImages: FeImageDTO[] | null;
  validation?: ImagesValidationRules;
  children: React.ReactNode;
}

const cutToLimit = (uploadedFiles: File[], currentAmount: number, maxAmount: number): File[] => {
  const filesLength = uploadedFiles.length;
  const amountDifference = maxAmount - (currentAmount + filesLength);
  const isTooMuchFiles = amountDifference < 0;

  if (isTooMuchFiles) return uploadedFiles.slice(0, filesLength + amountDifference);

  return uploadedFiles;
};

export const isFileNotTooHeavy = (file: File) => {
  return file.size < MAX_SIZE_IMAGE_FILE;
};

export const validateDropzone = (image: File | null, validation: ImagesValidationRules): string[] => {
  const errors: string[] = [];
  const { acceptedMimeTypes, eachImageMaxSize, isImageRequired } = validation;

  if (!!isImageRequired?.value) {
    if (!image) {
      errors.push(isImageRequired.error);
      return errors;
    }
  }

  if (!!acceptedMimeTypes?.value && acceptedMimeTypes.value.length > 0 && !!image) {
    if (!acceptedMimeTypes.value.includes(image?.type || "")) {
      errors.push(acceptedMimeTypes.error);
    }
  }

  if (!!eachImageMaxSize?.value && !!image) {
    if ((image?.size || 0) > eachImageMaxSize.value) {
      errors.push(eachImageMaxSize.error);
    }
  }

  return errors;
};

export const DropzoneProviderV3 = ({
  imagesLimit = 1,
  initialCanUploadMultiple,
  children,
  initialImage,
  initialRestImages,
  validation
}: IDropzoneProviderProps) => {
  const { t } = useTranslation("validations");
  const [isImageChanged, setIsImageChanged, resetIsImageChanged] = useBoolean(false);
  const [canUploadMultiple, enableUploadMultiple, disableUploadMultiple] = useBoolean(initialCanUploadMultiple);

  const [mainImage, setMainImage] = React.useState<FeImageDTO | null>(initialImage);
  const [regularImages, setRegularImages] = React.useState<FeImageDTO[] | null>(initialRestImages);
  const [fileDestination, setFileDestination] = React.useState("main");

  const regularImagesCount = regularImages?.length || 0;
  const totalImagesCount = regularImagesCount + (mainImage ? 1 : 0);

  const inputRef = React.useRef<HTMLInputElement>(null as any);

  const { validationFields, validateAndShowErrors, onlyShowErrors, validateOnlyErrors, resetToInitial } = useValidation(
    {
      persistPolicy: "always-fresh-state"
    }
  );

  const {
    validateAndShowErrors: validateAndShowErrorsRegularImages,
    validationFields: regularImagesValidationFields,
    resetToInitial: resetRegularImagesFields
  } = useValidation({ persistPolicy: "always-fresh-state" });

  const uplaodInitialMainImage = (feDropzoneImage: FeImageDTO | null) => setMainImage(feDropzoneImage);
  const uploadInitialRegularImages = (feRegularImages: FeImageDTO[] | null) => setRegularImages(feRegularImages);

  const uploadMainImage = (file: File) => {
    setMainImage(
      translateImgFileIntoDropzoneImage({
        file
      })
    );
    setIsImageChanged();
  };

  const _upsertRegularImagesFiles = (images: File[]) => {
    const limitedFiles = cutToLimit(images, totalImagesCount, imagesLimit);

    const image = limitedFiles[0];

    const maxSizeError =
      image.size > MAX_SIZE_IMAGE_FILE ? t("imageTooBig", { maxSize: `${kbToMb(MAX_SIZE_IMAGE_FILE)}MB` }) : "";
    const wrongMimeType = !ACCEPTED_MIME_TYPES.includes(image!.type)
      ? t("invalidMimeType", { mimeTypes: `${ACCEPTED_MIME_TYPES_FOR_HUMAN.join(", ")}` })
      : "";

    const finalErrors = [wrongMimeType, maxSizeError].filter((err) => !!err);

    if (finalErrors.length === 0) {
      setRegularImages((prev) => {
        if (!prev) return limitedFiles.map((limitedFile) => translateImgFileIntoDropzoneImage({ file: limitedFile }));

        return [
          ...prev,
          ...limitedFiles.map((limitedFile) => translateImgFileIntoDropzoneImage({ file: limitedFile }))
        ];
      });
    } else {
      validateAndShowErrorsRegularImages(finalErrors);
      const tId = setTimeout(() => {
        resetRegularImagesFields();
        clearTimeout(tId);
      }, 4000);
    }
  };

  const cleanFilesFromInput = () => {
    if (!inputRef?.current) return;
    inputRef.current.files = null;
    inputRef.current.value = "";
  };

  const deleteMainImage = () => {
    setMainImage(null);
    cleanFilesFromInput();
  };

  const deleteRegularImage = (imageIndex: number) => {
    cleanFilesFromInput();
    setRegularImages((prev) => (prev || []).filter((prevImg, index) => index !== imageIndex));
  };

  const openInput = () => {
    if (!inputRef?.current) return;

    inputRef.current.click();
    setFileDestination("regular");
  };

  const resetInputFiles = () => {
    cleanFilesFromInput();
    setFileDestination("main");
  };

  const parseFileListToFiles = (files: FileList | null): File[] | void => {
    if (!files) return;

    const arrayFiles: File[] | undefined = Object.values(files);
    return arrayFiles;
  };

  const basicValidation = (files: File[]) => {
    if (!!validation) {
      const totalErrors: string[] = [];

      files.forEach((file) => {
        const errors = validateDropzone(file, validation);
        if (errors.length > 0) {
          totalErrors.push(...errors);
        }
      });

      validateAndShowErrors(totalErrors);
    }
  };

  const uploadFiles = (files: File[]) => {
    if (fileDestination === "main") {
      basicValidation(files);
      uploadMainImage(files[0]);
    }
    if (fileDestination === "regular") {
      _upsertRegularImagesFiles(files);
    }
  };

  const onChangeFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (fileList?.length === 0) return;

    const prepared = parseFileListToFiles(fileList);
    if (!prepared) return;

    uploadFiles(prepared);
  };

  const onDropFiles = (e: React.DragEvent<HTMLInputElement>) => {
    const fileList = e.dataTransfer.files;
    if (fileList?.length === 0) return;

    const prepared = parseFileListToFiles(e.dataTransfer.files);
    if (!prepared) return;

    uploadFiles(prepared);
  };

  const clearDropzone = () => {
    setMainImage(null);
    setRegularImages(null);
    cleanFilesFromInput();
    resetToInitial();
    resetRegularImagesFields();
  };

  useCleanup(resetInputFiles, mainImage, regularImages, regularImagesValidationFields);
  useCleanup(resetIsImageChanged);

  return (
    <DropzoneContextV3.Provider
      value={{
        mainImage,
        regularImages,

        fileDestination,

        inputRef,
        deleteMainImage,
        deleteRegularImage,

        canUploadMultiple,
        enableUploadMultiple,
        disableUploadMultiple,
        openInput,
        onChangeFiles,
        onDropFiles,
        uploadMainImage,
        uplaodInitialMainImage,
        uploadInitialRegularImages,

        imagesLimit,
        totalImagesCount,

        isImageChanged,
        resetIsImageChanged,

        clearDropzone,

        imageValidation: validationFields,
        onlyShowErrors,
        validateOnlyErrors,
        validateAndShowErrors,

        validateAndShowErrorsRegularImages,
        regularImagesValidationFields
      }}
    >
      {children}
    </DropzoneContextV3.Provider>
  );
};

export const useDropzoneV3 = (): IDropzoneV3 => React.useContext(DropzoneContextV3);
