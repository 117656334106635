interface IAvatar {
  img: string | React.ReactNode;
  sizePx: number;
  className?: string;
}

const Avatar = ({ img, className, sizePx }: IAvatar) => {
  return (
    <>
      {typeof img === "string" ? (
        <img src={img} className={`ds-avatar${` ${className}` || ""}`} width={sizePx} />
      ) : (
        { img }
      )}
    </>
  );
};

export default Avatar;
