import { UseFormFields } from "@hooks/types/useForm.types";

export type ShippingKeys = "fullName" | "phoneNumber";

export const shippingFormSchema: Readonly<UseFormFields<ShippingKeys>> = {
  fullName: {
    initialValue: "",
    validationPattern: {
      isRequired: false,
      minLength: 3
    }
  },
  phoneNumber: {
    initialValue: "",
    validationPattern: {
      isRequired: false,
      isPhone: true
    }
  }
};
