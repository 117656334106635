import { Navigate, useLocation } from "react-router-dom";

import { IChildren } from "@components/interfaces/IChildren";

import { paths } from "@app/paths";
import { useRulesNfcTags } from "@app/routing/Ordering/Nfcs/contexts/RulesNfcTags.context";
import { useCategory } from "../contexts/OrderCategoryContext";
import { usePlaceOrder } from "../contexts/PlaceOrderContext";
import { congratulations, orderNFC, paymentAndDelivery } from "../contexts/OrderNavigationContext";

const OrderFlowGuard = ({ children }: IChildren) => {
  const { pathname } = useLocation();

  const { canGoNextFromCategory } = useCategory();
  const { canGoNextFromOrderNFCTags } = useRulesNfcTags();
  const { canGoNextFromPaymentDelivery } = usePlaceOrder();

  const entryPointsForCategories = [paths.orderGetStarted, paths.orderCategory] as string[];
  const entryPointsForNFCs = [...entryPointsForCategories, paths.orderNfc] as string[];
  const entryPointsForPaymentDelivery = [...entryPointsForNFCs, paths.orderPaymentDelivery] as string[];

  const canBeOnNFCs = canGoNextFromCategory;
  const canBeOnPaymentAndDelivery = canGoNextFromOrderNFCTags;
  const canBeOnCongratulations = canGoNextFromPaymentDelivery;

  if (!canBeOnCongratulations && !entryPointsForPaymentDelivery.includes(pathname))
    return <Navigate to={congratulations.previous} />;

  if (!canBeOnPaymentAndDelivery && !entryPointsForNFCs.includes(pathname))
    return <Navigate to={paymentAndDelivery.previous} />;

  if (!canBeOnNFCs && !entryPointsForCategories.includes(pathname)) return <Navigate to={orderNFC.previous} />;

  return <>{children}</>;
};

export default OrderFlowGuard;
