import { FULL_NAME_LENGTH, PHONE_NUMBER_MAX_LENGTH, PHONE_NUMBER_MIN_LENGTH } from "@constants/inputsMaxLength";
import { PHONE_REGEXP } from "@constants/regexps";
import { UseFormFields } from "@hooks/types/useForm.types";

export type IShippingDetails = "nameAndSurname" | "phoneNumber";
type InitialShippingDetails = Record<IShippingDetails, InitialFormField>;

interface InitialFormField {
  initialValue: string;
  initialError: string;
  initialToShowError: boolean;
}

export const shippingDetailsSchema = ({
  nameAndSurname,
  phoneNumber
}: InitialShippingDetails): UseFormFields<IShippingDetails> => {
  return {
    nameAndSurname: {
      initialValue: nameAndSurname.initialValue,
      initialError: nameAndSurname.initialError,
      initialToShowError: nameAndSurname.initialToShowError,
      validationPattern: {
        isRequired: true,
        maxLength: FULL_NAME_LENGTH,
        isPureString: true
      }
    },
    phoneNumber: {
      initialValue: phoneNumber.initialValue,
      initialError: phoneNumber.initialError,
      initialToShowError: phoneNumber.initialToShowError,
      validationPattern: {
        isRequired: true,
        regexp: PHONE_REGEXP,
        minLength: PHONE_NUMBER_MIN_LENGTH,
        maxLength: PHONE_NUMBER_MAX_LENGTH
      }
    }
  };
};
