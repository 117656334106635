import { UseFormFields } from "@hooks/types/useForm.types";

export type RecoverFormFields = "email";

export const recoverFormSchema: Readonly<UseFormFields<RecoverFormFields>> = {
  email: {
    initialValue: "",
    validationPattern: {
      isEmail: true,
      maxLength: 150,
      isRequired: true
    }
  }
};
