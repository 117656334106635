import React from "react";
import { ViewImagesProvider } from "./ViewImages.context";
import { CropperProvider } from "./Cropper.context";

interface Props {
  children: React.ReactNode;
}

const ImageWithCropper = ({ children }: Props) => {
  return (
    <CropperProvider>
      <ViewImagesProvider>{children}</ViewImagesProvider>
    </CropperProvider>
  );
};

export default ImageWithCropper;
