import "./AuthLogging.scss";

const AuthLogging = () => {
  return (
    <div className="auth-logging">
      <img src="/images/logos/go2nft-pure-logo.svg" className="logo-animation" width={80} />
    </div>
  );
};

export default AuthLogging;
