import React from "react";

import { EPaymentMethod, IPaymentMethod, paymentMethods } from "@constants/paymentMethods";
import { clearSessionStorageItems, readSessionStorage, writeSessionStorage } from "@utils/storage";

export type IPaymentMethods = (IPaymentMethod & { isActive: boolean })[];

export interface IPayment {
  pickedPaymentMethod: IPaymentMethod & { isActive: boolean };
  paymentMethods: IPaymentMethods;
  updatePaymentMethod: (methodName: EPaymentMethod) => void;
  paymentMethodError: string;
  toShowPaymentError: boolean;
  resetPaymentMethod: () => void;
  validatePaymentMethod: () => void;
  isPaymentMethodValid: boolean;
}

export interface IPaymentStorage {
  paymentMethods: IPaymentMethods;
  toShowPaymentError: boolean;
  paymentMethodError: string;
}

export const PAYMENT_METHOD_STORAGE_KEY = "payment_method_storage_key";

export const onlyEnabledPaymentMethods = paymentMethods.filter((method) => method.enabled);

export const initialPaymentMethods = onlyEnabledPaymentMethods.map((method) => ({
  ...method,
  isActive: onlyEnabledPaymentMethods.length === 1
}));

const initialToShowError = false;
const initialError = "You need to choose payment method";

const initialPaymentState: IPaymentStorage = {
  paymentMethods: initialPaymentMethods,
  toShowPaymentError: initialToShowError,
  paymentMethodError: initialError
};

const usePaymentMethod = () => {
  const initialPaymentMethodStorage =
    JSON.parse(readSessionStorage(PAYMENT_METHOD_STORAGE_KEY) || "null") ?? initialPaymentState;

  const [paymentMethods, setPaymentMethods] = React.useState<IPaymentMethods>(
    initialPaymentMethodStorage.paymentMethods
  );
  const [toShowPaymentError, setToShowPaymentError] = React.useState(initialPaymentMethodStorage.toShowPaymentError);
  const [paymentMethodError, setPaymentMethodError] = React.useState(initialPaymentMethodStorage.paymentMethodError);

  const updatePaymentMethod = (methodName: EPaymentMethod) => {
    setPaymentMethods((prev) => prev.map((method) => ({ ...method, isActive: method.name === methodName })));
    setPaymentMethodError("");
    setToShowPaymentError(false);
  };

  const isPaymentMethodValid = paymentMethods.filter((method) => method.isActive).length > 0;

  const resetPaymentMethod = () => {
    clearSessionStorageItems([PAYMENT_METHOD_STORAGE_KEY]);
    setPaymentMethods(initialPaymentMethods);
    setToShowPaymentError(initialToShowError);
    setPaymentMethodError(initialError);
  };

  const validatePaymentMethod = () => {
    if (!isPaymentMethodValid) {
      setToShowPaymentError(true);
    }
  };

  const pickedPaymentMethod = paymentMethods.find((method) => method.isActive);

  React.useEffect(() => {
    writeSessionStorage(PAYMENT_METHOD_STORAGE_KEY, { paymentMethods, toShowPaymentError, paymentMethodError });
  }, [paymentMethods, toShowPaymentError, paymentMethodError]);

  return {
    pickedPaymentMethod,
    paymentMethods,
    updatePaymentMethod,
    paymentMethodError,
    toShowPaymentError,
    resetPaymentMethod,
    validatePaymentMethod,
    isPaymentMethodValid
  };
};

export default usePaymentMethod;
