import React from "react";

import { IChildren } from "@components/interfaces/IChildren";
import { SetPartnerDetailsProvider } from "./SetPartnerDetails.context";
import { EffectsPartnerDetailsProvider } from "./EffectsPartnerDetails.context";

interface IPartnerDetailsContext {}

const PartnerDetailsContext = React.createContext(null as any);

export const PartnerDetailsProvider = ({ children }: IChildren) => {
  return (
    <PartnerDetailsContext.Provider value={{}}>
      <SetPartnerDetailsProvider>
        <EffectsPartnerDetailsProvider>{children}</EffectsPartnerDetailsProvider>
      </SetPartnerDetailsProvider>
    </PartnerDetailsContext.Provider>
  );
};

export const usePartnerDetails = (): IPartnerDetailsContext => React.useContext(PartnerDetailsContext);
