import { paths } from "@app/paths";
import Stack from "@components/arrangement/Stack/Stack";
import AppLayout from "@components/layoutElements/Layouts/AppLayout/AppLayout";
import { useTranslation } from "react-i18next";

const RecoverEmailConfirmation = () => {
  const { t } = useTranslation("auth");
  return (
    <Stack rowGap={16} alignItems="center" justifyContent="center">
      <img src="/images/auth/register-confirm.svg" alt="verify account" width={200} height={200} />

      <h2 className="regular">{t("resetPasswordConfirm.title")}</h2>
      <p className="p2">{t("resetPasswordConfirm.description")}</p>
    </Stack>
  );
};

export default {
  path: paths.authRecoverConfirm,
  element: (
    <AppLayout withoutLinks withoutProfile>
      <RecoverEmailConfirmation />
    </AppLayout>
  ),
  children: []
};
