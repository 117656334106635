export enum EPaymentMethod {
  PAYPAL = "paypal",
  CREDIT_CARD = "credit card",
  BANK_TRANSFER = "Bank transfer"
}

export interface IPaymentMethod {
  enabled: boolean;
  name: EPaymentMethod;
  acceptedCardsLogo: string[];
}

export const paymentMethods: IPaymentMethod[] = [
  {
    acceptedCardsLogo: ["/images/paypal.svg"],
    enabled: false,
    name: EPaymentMethod.PAYPAL
  },
  {
    acceptedCardsLogo: ["/images/visa.svg", "/images/master-card.svg", "/images/american-express.svg"],
    enabled: false,
    name: EPaymentMethod.CREDIT_CARD
  },
  {
    acceptedCardsLogo: [],
    enabled: true,
    name: EPaymentMethod.BANK_TRANSFER
  }
];
