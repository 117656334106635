import { BaseProps } from "@components/interfaces/StyledComponent";

import "./Backdrop.scss";

type IBackdrop = {
  isOpen: boolean;
  children: React.ReactNode;
} & BaseProps;

const Backdrop = ({ children, isOpen, onClick }: IBackdrop) => {
  return (
    <>
      {isOpen ? (
        <div className={`backdrop-wrapper open`}>
          <div
            className="backdrop"
            onClick={(e) => {
              e.stopPropagation();
              onClick && onClick(e);
            }}
          ></div>
          {children}
        </div>
      ) : null}
    </>
  );
};

export default Backdrop;
