import { paths } from "@app/paths";

export default {
  path: paths.orderPaymentDelivery,
  children: [],
  lazy: async () => {
    const { PaymentAndDelivery } = await import("./PaymentDelivery");

    return {
      element: <PaymentAndDelivery />
    };
  }
};
