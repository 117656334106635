import React from "react";

import { IChildren } from "@components/interfaces/IChildren";
import { useGenerator } from "../Generator.context";
import useRequestV2 from "@hooks/useRequestV2";
import { deleteCollection, getCollectionRaport } from "@api/hubApi/methods";
import { useGetCollections } from "./GetCollections.context";
import { useNavigate } from "react-router-dom";
import { paths } from "@app/paths";
import { createFileFromBlob } from "@utils/createFileFromBlob";
import { saveFile } from "@utils/saveFile";
import { CollectionDTO } from "@api/hubApi/dtos.types";

interface IUpdateCollectionsContext {
  triggerDeleteCollection: (args?: string) => void;
  isDeletingCollection: boolean;
  deletedCollection: CollectionDTO;
  triggerGenerateRaport: (args?: { collectionId: string; setIds: string[] }) => void;
  isGeneratingRaport: boolean;
}

const UpdateCollectionsContext = React.createContext(null as any);

export const UpdateCollectionsProvider = ({ children }: IChildren) => {
  const navigate = useNavigate();

  const { refetchCollections } = useGetCollections();
  const { onReqFail, onReqPass, onStartReq } = useGenerator();

  const [triggerDeleteCollection, isDeletingCollection, deletedCollection] = useRequestV2<string, any>({
    requestFn: (collectionId?: string) => deleteCollection(collectionId || ""),
    beforeStart: onStartReq,
    onSuccessFn: () => {
      onReqPass();
      refetchCollections();
      navigate(paths.generatorCollectionsView);
    },
    onError: onReqFail
  });

  const [triggerGenerateRaport, isGeneratingRaport, generatedRaport, generatingReportError, generatingReportHeaders] =
    useRequestV2<{ collectionId: string; setIds: string[] }, Blob>({
      requestFn: (args) => getCollectionRaport(args?.collectionId || "", args?.setIds || []),
      beforeStart: onStartReq,
      onSuccessFn: () => {
        onReqPass();
      },
      onError: onReqFail
    });

  React.useEffect(() => {
    if (generatedRaport && generatingReportHeaders) {
      const fileName = decodeURI(generatingReportHeaders["content-disposition"].split(/"/)[1]);
      const file = createFileFromBlob(generatedRaport, fileName);
      saveFile(file);
    }
  }, [generatedRaport, generatingReportHeaders]);

  return (
    <UpdateCollectionsContext.Provider
      value={{
        triggerDeleteCollection,
        isDeletingCollection,
        deletedCollection,
        triggerGenerateRaport,
        isGeneratingRaport
      }}
    >
      {children}
    </UpdateCollectionsContext.Provider>
  );
};

export const useUpdateCollections = (): IUpdateCollectionsContext => React.useContext(UpdateCollectionsContext);
