import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import useDropdown from "@design-system/Dropdown/hooks/useDropdown";
import { useAuth } from "@contexts/AuthContext";

import Group from "@components/arrangement/Group/Group";
import Dropdown from "@design-system/Dropdown/Dropdown";
import Avatar from "@design-system/Avatar/Avatar";
import Stack from "@components/arrangement/Stack/Stack";
import G2nButton from "@components/common/Buttons/Button";

import { paths } from "@app/paths";

import { useSetBrandsDetails } from "@app/routing/ProfileSettings/contexts/BrandsDetailsContext/SetBrandsDetails.context";
import { useViewCompanyProfile } from "@app/routing/ProfileSettings/contexts/ViewCompanyProfile.context";
import { useRulesBrandsDetails } from "@app/routing/ProfileSettings/contexts/BrandsDetailsContext/RulesBrandsDetails.context";
import { useGetCompanyDetails } from "@app/routing/ProfileSettings/contexts/CompanyDetailsContext/GetCompanyDetails.context";

import CustomAvatar from "@components/media/CustomAvatar/CustomAvatar";
import CircularSkeleton from "@design-system/Skeleton/CircularSkeleton";

import "./ProfileOptions.scss";

const AVATAR_SIZE = 32;

const ProfileOptions = () => {
  const { pathname } = useLocation();
  const { isCompanyUserLoading } = useGetCompanyDetails();
  const { brandLogoDropzone, colorPicker } = useSetBrandsDetails();
  const { avatar, email } = useViewCompanyProfile();
  const { userHasSetOwnAvatar } = useRulesBrandsDetails();

  const [isOpen, closeList, toggle] = useDropdown(false);

  const navigate = useNavigate();
  const navigateToProfile = () => navigate(paths.profile);

  const { logOut } = useAuth();
  const { t } = useTranslation("layout");

  const handleDropdownAction = (cb: Function) => {
    cb();
    closeList();
  };

  const customAvatar = userHasSetOwnAvatar ? (
    <CustomAvatar sizePx={AVATAR_SIZE} bgColorHex={colorPicker.bg} file={brandLogoDropzone.file!} />
  ) : (
    <Avatar img={avatar} sizePx={AVATAR_SIZE} />
  );

  const isPageActive = pathname.includes(paths.profile);

  return (
    <Dropdown
      close={closeList}
      isOpen={isOpen}
      toggle={toggle}
      testid="settings"
      list={
        <Stack alignItems="center">
          <G2nButton
            variant="link-btn"
            className="sign-out-btn"
            uppercase={false}
            action={() => handleDropdownAction(navigateToProfile)}
          >
            {t("navbar.profileSettings")}
          </G2nButton>

          <G2nButton
            variant="link-btn"
            className="sign-out-btn"
            uppercase={false}
            action={() => handleDropdownAction(logOut)}
            testid="logOut"
          >
            {t("navbar.signOut")}
          </G2nButton>
        </Stack>
      }
    >
      <Group colGap={8} alignItems="center" fullWidth className={isPageActive ? "active-link" : ""}>
        {isCompanyUserLoading ? <CircularSkeleton sizePx={AVATAR_SIZE} /> : customAvatar}
        {/* <p className="p3 black-1">{email}</p>
        <img src="/images/common/full-arrow-down.svg" alt="arrow down" /> */}
      </Group>
    </Dropdown>
  );
};

export default ProfileOptions;
