import React from "react";

import { IChildren } from "@components/interfaces/IChildren";
import useForm from "@hooks/useForm";
import { IShippingDetails, shippingDetailsSchema } from "../../../../contexts/shippingDetails.schema";
import { IDeliveryDetails, deliveryDetailsSchema } from "../../../../contexts/deliveryDetails.schema";
import { useCompanyDetails } from "./CompanyDetailsContext";
import { AddressDTO } from "@api/hubApi/dtos.types";
import useWriteSessionStorage from "@hooks/useWriteSessionStorage";
import { clearSessionStorageItems, readSessionStorage } from "@utils/storage";
import { FormFieldsWithMethods } from "@hooks/types/useForm.types";
import { useCountries } from "../../../../contexts/CountriesContext";
import { useSetUserDetails } from "@app/routing/ProfileSettings/contexts/UserDetailsContext/SetUserDetails.context";

interface IDeliveryDetailsContextV2 {
  areDeliveryDetailsValid: boolean;
  areShippingDetailsValid: boolean;
  resetFields: () => void;
  validateFields: () => void;
  deliveryAddress: AddressDTO;
  finalDeliveryDetails: AddressDTO;
  deliveryDetails: FormFieldsWithMethods<IDeliveryDetails>;
  shippingDetails: FormFieldsWithMethods<IShippingDetails>;
}

const DeliveryDetailsContextV2 = React.createContext(null as any);

export const DELIVERY_DETAILS_KEY_V2 = "delivery_details_key_v2";
export const SHIPPING_DETAILS_KEY_V2 = "shipping_details_key_v2";

export const DeliveryDetailsProviderV2 = ({ children }: IChildren) => {
  const { isDeliveryDifferentThanCompany, finalCompanyDetails } = useCompanyDetails();
  const { shippingDataForm } = useSetUserDetails();

  const { phoneNumber, fullName } = shippingDataForm?.fields || {};

  const { countries } = useCountries();

  const initDelDet = JSON.parse(
    readSessionStorage(DELIVERY_DETAILS_KEY_V2) ?? "null"
  ) as FormFieldsWithMethods<IDeliveryDetails> | null;

  const initShipDet = JSON.parse(
    readSessionStorage(SHIPPING_DETAILS_KEY_V2) ?? "null"
  ) as FormFieldsWithMethods<IShippingDetails> | null;

  const {
    fields: shippingDetails,
    isValid: areShippingDetailsValid,
    reset: resetShippingDetails,
    validate: validateShippingDetails
  } = useForm<IShippingDetails>(
    shippingDetailsSchema({
      nameAndSurname: {
        initialValue: (initShipDet?.nameAndSurname.value || fullName.value) ?? "",
        initialError: initShipDet?.nameAndSurname.error ?? "",
        initialToShowError: initShipDet?.nameAndSurname.toShowError ?? false
      },
      phoneNumber: {
        initialValue: (initShipDet?.phoneNumber.value || phoneNumber.value) ?? "",
        initialError: initShipDet?.phoneNumber.error ?? "",
        initialToShowError: initShipDet?.phoneNumber.toShowError ?? false
      }
    })
  );

  const {
    fields: deliveryDetails,
    isValid: areDeliveryDetailsValid,
    reset: resetDeliveryDetails,
    validate: validateDeliveryDetails
  } = useForm<IDeliveryDetails>(
    deliveryDetailsSchema({
      companyName: {
        initialValue: initDelDet?.companyName.value ?? "",
        initialError: initDelDet?.companyName.error ?? "",
        initialToShowError: initDelDet?.companyName.toShowError ?? false
      },
      address: {
        initialValue: initDelDet?.address.value ?? "",
        initialError: initDelDet?.address.error ?? "",
        initialToShowError: initDelDet?.address.toShowError ?? false
      },
      city: {
        initialValue: initDelDet?.city.value ?? "",
        initialError: initDelDet?.city.error ?? "",
        initialToShowError: initDelDet?.city.toShowError ?? false
      },
      country: {
        initialValue: initDelDet?.country.value ?? "",
        initialError: initDelDet?.country.error ?? "",
        initialToShowError: initDelDet?.country.toShowError ?? false
      },
      postCode: {
        initialValue: initDelDet?.postCode.value ?? "",
        initialError: initDelDet?.postCode.error ?? "",
        initialToShowError: initDelDet?.postCode.toShowError ?? false
      }
    })
  );

  const resetFields = () => {
    clearSessionStorageItems([DELIVERY_DETAILS_KEY_V2, SHIPPING_DETAILS_KEY_V2]);
    resetShippingDetails();
    resetDeliveryDetails();
  };

  const validateFields = () => {
    validateShippingDetails();
    isDeliveryDifferentThanCompany && validateDeliveryDetails();
  };

  const country = countries?.find((country) => country.name === deliveryDetails.country.value);

  const deliveryAddress: AddressDTO = {
    companyName: deliveryDetails.companyName.value,
    country: country,
    city: deliveryDetails.city.value,
    postCode: deliveryDetails.postCode.value,
    addressLine: deliveryDetails.address.value
  };

  const finalDeliveryDetails = isDeliveryDifferentThanCompany ? deliveryAddress : finalCompanyDetails;

  useWriteSessionStorage(DELIVERY_DETAILS_KEY_V2, deliveryDetails);
  useWriteSessionStorage(SHIPPING_DETAILS_KEY_V2, shippingDetails);

  return (
    <DeliveryDetailsContextV2.Provider
      value={{
        areShippingDetailsValid,
        areDeliveryDetailsValid,
        resetFields,
        validateFields,
        deliveryAddress,
        finalDeliveryDetails,
        shippingDetails,
        deliveryDetails
      }}
    >
      {children}
    </DeliveryDetailsContextV2.Provider>
  );
};

export const useDeliveryDetailsV2 = (): IDeliveryDetailsContextV2 => React.useContext(DeliveryDetailsContextV2);
