import { ACCEPTED_PNG_MIME_FOR_HUMAN } from "@constants/constants";
import useBoolean from "@hooks/useBoolean";
import useValidation, { UseValidationReturn } from "@hooks/useValidation";
import { kbToMb } from "@utils/mbCalculator";
import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  validation?: {
    maxFileSizeB?: number;
    expectedMimeTypes?: string[];
  };
}

export interface UseDropzoneV4Return {
  ref: React.MutableRefObject<HTMLInputElement>;
  file: File | string | null;
  onDrop: (e: React.DragEvent<HTMLInputElement>) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  openInput: () => void;
  uploadInitialImage: (imgUrl: string | null) => void;
  resetImage: (withChange: boolean) => void;
  isImageChanged: boolean;
  resetIsImageChanged: () => void;

  localValidation: UseValidationReturn;
}

const useDropzoneV4 = ({ validation }: Props): UseDropzoneV4Return => {
  const { t } = useTranslation("validations");
  const [file, setFile] = React.useState<File | string | null>(null);
  const ref = React.useRef(null as any);

  const [isImageChanged, setIsImageChanged, resetIsImageChanged] = useBoolean(false);
  const localValidation = useValidation({ persistPolicy: "always-fresh-state" });

  const _isFileTooHeavy = (file: File) => {
    return file.size > (validation?.maxFileSizeB || 0);
  };

  const _clearInput = () => {
    if (!ref?.current) return;
    ref.current.files = null;
    ref.current.value = "";
  };

  const _validate = (file: File): string[] => {
    const errors: string[] = [];
    if (!validation) return errors;

    if (!!validation.maxFileSizeB && _isFileTooHeavy(file)) {
      errors.push(t("imageTooBig", { maxSize: `${kbToMb(validation.maxFileSizeB)}MB` }));
    }
    if (!!validation.expectedMimeTypes) {
      if (!validation.expectedMimeTypes.includes(file.type)) {
        errors.push(t("invalidMimeType", { mimeTypes: ACCEPTED_PNG_MIME_FOR_HUMAN }));
      }
    }

    return errors;
  };

  const _parseFileListToFiles = (files: FileList | null): File | undefined => {
    if (!files) return;

    const arrayFiles: File | undefined = Object.values(files)[0];
    return arrayFiles;
  };

  const _uploadImage = (image: File) => {
    const errors = _validate(image);

    if (errors.length > 0) {
      localValidation.validateAndShowErrors(errors);
      resetImage(false);
    } else {
      setIsImageChanged();
      setFile(image);
      localValidation.resetToInitial();
    }
  };

  const openInput = () => {
    if (!ref?.current) return;

    ref.current.click();
  };

  const uploadInitialImage = (imgUrl: string | null) => setFile(imgUrl);

  const onDrop = (e: React.DragEvent<HTMLInputElement>) => {
    const fileList = e.dataTransfer.files;
    if (fileList?.length === 0) return;

    const prepared = _parseFileListToFiles(e.dataTransfer.files);
    if (!prepared) return;

    _uploadImage(prepared);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (fileList?.length === 0) return;

    const prepared = _parseFileListToFiles(fileList);
    if (!prepared) return;

    _uploadImage(prepared);
  };

  const resetImage = (withChange: boolean) => {
    setFile(null);
    _clearInput();
    withChange ? setIsImageChanged() : resetIsImageChanged();
  };

  return {
    ref,
    file,
    openInput,
    onDrop,
    onChange,
    uploadInitialImage,
    resetImage,
    isImageChanged,
    resetIsImageChanged,
    localValidation
  };
};

export default useDropzoneV4;
