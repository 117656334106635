import React from "react";

import { IChildren } from "@components/interfaces/IChildren";
import { UpdateCompanyDetailsProvider } from "./UpdateCompanyDetails.context";
import { SetCompanyDetailsProvider } from "./SetCompanyDetails.context";
import { EffectsCompanyDetailsProvider } from "./EffectsCompanyDetails.context";

interface ICompanyDetailsContext {}

const CompanyDetailsContext = React.createContext(null as any);

export const CompanyDetailsProvider = ({ children }: IChildren) => {
  return (
    <CompanyDetailsContext.Provider value={{}}>
      <SetCompanyDetailsProvider>
        <UpdateCompanyDetailsProvider>
          <EffectsCompanyDetailsProvider>{children}</EffectsCompanyDetailsProvider>
        </UpdateCompanyDetailsProvider>
      </SetCompanyDetailsProvider>
    </CompanyDetailsContext.Provider>
  );
};

export const useCompanyDetails = (): ICompanyDetailsContext => React.useContext(CompanyDetailsContext);
