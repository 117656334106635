import { paths } from "@app/paths";

export default {
  path: paths.generatorCreateCollectionView,
  children: [],
  lazy: async () => {
    const { CreateCollectionView } = await import("./CreateCollectionView");

    return {
      element: <CreateCollectionView />
    };
  }
};
