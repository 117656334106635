import { useTranslation } from "react-i18next";

import { paths } from "@app/paths";
import { useAuth } from "@contexts/AuthContext";

import AppLayout from "@components/layoutElements/Layouts/AppLayout/AppLayout";
import Stack from "@components/arrangement/Stack/Stack";
import Button from "@components/common/Buttons/Button";

import { useGlobalToastsV2 } from "@contexts/GlobalToastsV2Context";
import useBoolean from "@hooks/useBoolean";

import { FirebaseError } from "firebase/app";

import "./RegisterConfirm.scss";

const RegisterConfirm = () => {
  const [isLoading, startLoading, stopLoading] = useBoolean(false);

  const { resendVerificationEmail } = useAuth();
  const { t } = useTranslation("auth");
  const { t: tFirebase } = useTranslation("firebase");
  const { startToast } = useGlobalToastsV2();

  const handleResendVerificationEmail = () => {
    startLoading();
    resendVerificationEmail()
      .then(() =>
        startToast({
          message: t("registerConfirm.emailResent"),
          withLoading: false,
          variant: "success",
          duration: 2000
        })
      )
      .catch((e: FirebaseError) => {
        const error = e.code ? tFirebase(e.code as any) : t("registerConfirm.emailResendError");
        startToast({
          message: error,
          withLoading: false,
          variant: "error",
          duration: 2000
        });
      })
      .finally(stopLoading);
  };

  return (
    <div className="register-confirm">
      <Stack rowGap={16} alignItems="center" justifyContent="center" fullSize>
        <img src="/images/auth/register-confirm.svg" alt="verify account" width={200} height={200} />

        <h2 className="regular" style={{ whiteSpace: "pre-wrap" }}>
          {t("registerConfirm.title")}
        </h2>
        <p className="p2" style={{ whiteSpace: "pre-wrap" }}>
          {t("registerConfirm.description")}
        </p>
        <Button action={handleResendVerificationEmail} disabled={isLoading} className="mt-xs-3">
          {t("registerConfirm.button")}
        </Button>
      </Stack>
    </div>
  );
};

export default {
  path: paths.authRegisterConfirm,
  element: (
    <AppLayout withoutLinks>
      <RegisterConfirm />
    </AppLayout>
  ),
  children: []
};
