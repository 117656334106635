import { Outlet } from "react-router-dom";

import QuestionSlug from "./QuestionSlug/QuestionSlug";

import AppLayout from "@components/layoutElements/Layouts/AppLayout/AppLayout";

import { paths } from "@app/paths";

import Explainer from "./components/Explainer/Explainer";
import HelpVideos from "./components/HelpVideos/HelpVideos";
import Faq from "./components/Faq/Faq";
import MobileApp from "./components/MobileApp/MobileApp";

import "./Help.scss";

const Help = () => {
  return (
    <>
      <div className="help rg-xs-14">
        <Explainer />
        {/* <HelpVideos /> */}
        <Faq />
        <MobileApp />
      </div>
      <Outlet />
    </>
  );
};

export default {
  path: paths.help,
  element: (
    <AppLayout>
      <Help />
    </AppLayout>
  ),
  children: [QuestionSlug]
};
