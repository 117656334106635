import React from "react";

import { useClickOutside } from "@hooks/useClickOutside";

interface DropdownProps {
  children: React.ReactNode;
  list: React.ReactNode;
  isOpen: boolean;
  close: () => void;
  toggle: () => void;
  testid?: string;
  className?: string;
}

const Dropdown = ({ children, list, isOpen, close, toggle, testid = "", className }: DropdownProps) => {
  const dropdownRef = React.useRef<HTMLDivElement>(null);
  useClickOutside(dropdownRef, close);

  return (
    <div className={`ds-dropdown${className ? ` ${className}` : ""}`} ref={dropdownRef} data-testid={testid}>
      <div role="button" onClick={toggle} className="ds-dropdown-button">
        {children}
      </div>
      <div
        className={`ds-dropdown-list list-roundness list-x list-y list-bg list-shadow list-padding${
          isOpen ? " open" : ""
        }`}
      >
        {list}
      </div>
    </div>
  );
};

export default Dropdown;
