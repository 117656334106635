import React from "react";

const HorizontalLine: React.FC<{ width?: number; height?: number; className?: string }> = ({
  width = 24,
  height = 24,
  className = ""
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M24 12L8.9407e-07 12" stroke="#120E2F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default HorizontalLine;
