import React from "react";

import { IChildren } from "@components/interfaces/IChildren";
import useForm from "@hooks/useForm";
import { CompanyDataKeys, companyFormSchema } from "@constants/profile/userCompanyDataFormSchema";
import { UserDTO } from "@api/hubApi/dtos.types";
import { UseFormResponse } from "@hooks/types/useForm.types";
import { IInputChange, useInputChange } from "@hooks/useInputChange";

interface ISetCompanyDetailsContext {
  companyDataForm: UseFormResponse<CompanyDataKeys>;
  updateCompanyPartner: (user: UserDTO) => void;
  companyInputChanged: IInputChange;
}

const SetCompanyDetailsContext = React.createContext(null as any);

export const COMPANY_PROFILE_DATA = "company_profile_data";

export const SetCompanyDetailsProvider = ({ children }: IChildren) => {
  const companyDataForm = useForm<CompanyDataKeys>(companyFormSchema, {
    doStore: true,
    storageKey: COMPANY_PROFILE_DATA,
    storageType: "session-storage"
  });

  return <SetCompanyDetailsContext.Provider value={{ companyDataForm }}>{children}</SetCompanyDetailsContext.Provider>;
};

export const useSetCompanyDetails = (): ISetCompanyDetailsContext => React.useContext(SetCompanyDetailsContext);
