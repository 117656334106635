import React from "react";

import { useGlobalToastsV2 } from "@contexts/GlobalToastsV2Context";

import ToastV2 from "@design-system/Toast/ToastV2";

const GlobalToastsV2 = () => {
  const { toasts, hideToast } = useGlobalToastsV2();
  const closestToast = toasts[0];

  if (!closestToast) return <></>;

  return <ToastV2 {...closestToast} hideToast={hideToast} />;
};

export default GlobalToastsV2;
