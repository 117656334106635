import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";

import { paths } from "src/app/paths";
import { useAuth } from "@contexts/AuthContext";
import useForm from "@hooks/useForm";

import Stack from "@components/arrangement/Stack/Stack";
import Input from "@components/dataEntry/Input/Input";
import G2nButton from "@components/common/Buttons/Button";
import AuthError from "../AuthError";

import { AuthActionProps } from "../AuthAction.types";
import { useGlobalToastsV2 } from "@contexts/GlobalToastsV2Context";
import { ResetPasswordKeys, resetPasswordSchema } from "./resetPassword.schema";

import "./ResetPassword.scss";

const ResetPassword = ({ oobCode }: AuthActionProps) => {
  const [userEmail, setUserEmail] = useState<string>("");
  const [error, setError] = useState<string>("");

  const { fields, validate, isValid } = useForm<ResetPasswordKeys>(resetPasswordSchema);
  const { password } = fields;

  const { verifyPasswordResetCode, resetPassword } = useAuth();
  const { t } = useTranslation("auth");
  const { startToast } = useGlobalToastsV2();
  const navigate = useNavigate();

  const handlePasswordChange = () => {
    validate();
    if (!isValid) return;

    resetPassword(oobCode!, password.value);
    navigate(paths.authLogin);
    const taostMessage = isMobile ? "resetPassword.success.mobile" : "resetPassword.success.desktop";
    startToast({ message: t(taostMessage), variant: "success", withLoading: false, duration: 5000 });
  };

  useEffect(() => {
    if (oobCode) {
      verifyPasswordResetCode(oobCode)
        .then((email) => setUserEmail(email))
        .catch((e) => setError(e.message));
    }
  }, [oobCode]);

  if (error) return <AuthError title={t("resetPassword.error")} error={error} className={"reset-password"} />;

  if (userEmail) {
    return (
      <Stack alignItems="center" justifyContent="center" className="reset-password" rowGap={48}>
        <Stack rowGap={16} alignItems="center">
          <h2 className="black-0">{t("resetPassword.title")}</h2>
          <p className="p2">
            {t("resetPassword.description")} {userEmail}
          </p>
        </Stack>
        <Stack className="password-label" alignItems="center" rowGap={24}>
          <Input
            label={t("resetPassword.inputs.newPassword.label")}
            placeholder={t("resetPassword.inputs.newPassword.placeholder")}
            type="password"
            required
            value={password.value}
            onChange={(e) => password.update(e.currentTarget.value)}
            fullSize
            error={password.toShowError ? t("resetPassword.inputs.newPassword.error") : ""}
            className="password-input"
          />
          <G2nButton action={handlePasswordChange} className="" freeWidth>
            {t("resetPassword.buttons.send")}
          </G2nButton>
        </Stack>
      </Stack>
    );
  }

  return <></>;
};

export default ResetPassword;
