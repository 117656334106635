import React from "react";

import { IChildren } from "@components/interfaces/IChildren";
import { useSetBrandsDetails } from "./SetBrandsDetails.context";
import { useHocCropper } from "@app/routing/Generator/contexts/HocCropperContext/HocCropper.context";

interface IRulesBrandsDetailsContext {
  isBrandsSectionTouched: boolean;

  areBgImageErrors: boolean;
  areBgImagesErrorsAndVisible: boolean;

  isCropperError: boolean;
  isCropperErrorAndVisible: boolean;

  isBrandLogoError: boolean;
  isBrandLogoErrorAndVisible: boolean;

  userHasSetOwnAvatar: boolean;
}

const RulesBrandsDetailsContext = React.createContext(null as any);

export const RulesBrandsDetailsProvider = ({ children }: IChildren) => {
  const { colorPicker, brandLogoDropzone, bgImageDropzone } = useSetBrandsDetails();
  const { cropper, validationFields } = useHocCropper();
  const { errors, toShowError } = bgImageDropzone.imageValidation;

  const isBrandsSectionTouched =
    colorPicker.isColorChanged ||
    brandLogoDropzone.isImageChanged ||
    (bgImageDropzone.isImageChanged && !!cropper.croppedImage);

  const areBgImageErrors = errors.length > 0;
  const isCropperError = validationFields.errors.length > 0;

  const areBgImagesErrorsAndVisible = areBgImageErrors && toShowError;
  const isCropperErrorAndVisible = isCropperError && validationFields.toShowError;

  const isBrandLogoError = brandLogoDropzone.localValidation.validationFields.errors.length > 0;
  const isBrandLogoErrorAndVisible = isBrandLogoError && brandLogoDropzone.localValidation.validationFields.toShowError;

  const userHasSetOwnAvatar = !!brandLogoDropzone.file;

  return (
    <RulesBrandsDetailsContext.Provider
      value={{
        isBrandsSectionTouched,

        areBgImageErrors,
        areBgImagesErrorsAndVisible,

        isCropperError,
        isCropperErrorAndVisible,

        isBrandLogoError,
        isBrandLogoErrorAndVisible,

        userHasSetOwnAvatar
      }}
    >
      {children}
    </RulesBrandsDetailsContext.Provider>
  );
};

export const useRulesBrandsDetails = (): IRulesBrandsDetailsContext => React.useContext(RulesBrandsDetailsContext);
