import { paths } from "@app/paths";

export default {
  path: paths.orderGetStarted,
  children: [],
  lazy: async () => {
    const { GetStarted } = await import("./GetStarted");

    return {
      element: <GetStarted />
    };
  }
};
