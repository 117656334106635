import React from "react";

import { GetCollectionsProvider } from "./GetCollections.context";
import { UpdateCollectionsProvider } from "./UpdateCollections.context";
import { RulesCollectionsContextProvider } from "./RulesCollections.context";
import { HandlersCollectionsProvider } from "./HandlersCollections.context";

interface Props {
  children: React.ReactNode;
}

const CollectionsProviderComp = ({ children }: Props) => {
  return (
    <GetCollectionsProvider>
      <RulesCollectionsContextProvider>
        <UpdateCollectionsProvider>
          <HandlersCollectionsProvider>{children}</HandlersCollectionsProvider>
        </UpdateCollectionsProvider>
      </RulesCollectionsContextProvider>
    </GetCollectionsProvider>
  );
};

export default CollectionsProviderComp;
