import React from "react";

import { IChildren } from "@components/interfaces/IChildren";
import useRequest from "@hooks/useRequest";
import { CollectionDTO, PaginatedCollectionsDTO, PaginationMeta } from "@api/hubApi/dtos.types";
import { getCollections } from "@api/hubApi/methods";
import { useLocation } from "react-router-dom";
import { paths } from "@app/paths";

interface IGetCollectionsContext {
  collections: CollectionDTO[];
  isFetchingCollections: boolean;
  refetchCollections: (queryParams?: PaginationMeta) => void;
}

const GetCollectionsContext = React.createContext(null as any);

export const GetCollectionsProvider = ({ children }: IChildren) => {
  const { pathname } = useLocation();

  const [collections, setCollections] = React.useState<CollectionDTO[]>([]);
  const {
    requestTrigger: apiGetCollections,
    isLoading: isFetchingCollections,
    response: responseCollections,
    refetch,
    axiosError
  } = useRequest<PaginatedCollectionsDTO>({ requestFn: getCollections });

  React.useLayoutEffect(() => {
    if (!responseCollections) return;

    setCollections(responseCollections.data);
  }, [responseCollections]);

  React.useLayoutEffect(() => {
    if (pathname !== paths.generatorCollectionsView) return;

    apiGetCollections();
  }, [pathname]);

  return (
    <GetCollectionsContext.Provider value={{ collections, isFetchingCollections, refetchCollections: refetch }}>
      {children}
    </GetCollectionsContext.Provider>
  );
};

export const useGetCollections = (): IGetCollectionsContext => React.useContext(GetCollectionsContext);
