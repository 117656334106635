import { paths } from "@app/paths";

export default {
  path: paths.orderCategory,
  children: [],
  lazy: async () => {
    const { Categories } = await import("./Categories");

    return {
      element: <Categories />
    };
  }
};
