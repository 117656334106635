import React from "react";
import { useTranslation } from "react-i18next";

import { FormFieldsWithMethods, IFormField, IStorage, UseFormFields, UseFormResponse } from "./types/useForm.types";

import { ValidationPattern, validateInput } from "@utils/validateInput";
import { deepCopy } from "@utils/deepCopy";
import { readLocalStorage, readSessionStorage, writeLocalStorage, writeSessionStorage } from "@utils/storage";

export interface InitialFormField {
  initialValue: string;
  initialError: string;
  initialToShowError: boolean;
}

function useForm<K extends string>(fields: UseFormFields<K>, storage?: IStorage): UseFormResponse<K> {
  const { t } = useTranslation("validations");

  const storageInitialFields = React.useMemo(
    () =>
      storage?.doStore
        ? storage.storageType === "local-storage"
          ? JSON.parse(readLocalStorage(storage?.storageKey || "") || "null")
          : JSON.parse(readSessionStorage(storage?.storageKey || "") || "null")
        : null,
    []
  );

  type IForm = Record<K, IFormField>;

  const initialFields: IForm = React.useMemo(() => {
    const fieldsObj: any = {};
    for (const key in fields) {
      fieldsObj[key] = {
        value: fields[key].initialValue,
        error: fields[key].initialError ?? "",
        touched: false,
        toShowError: fields[key].initialToShowError ?? false,
        required: fields[key].validationPattern.isRequired ?? false
      };
    }
    return fieldsObj;
  }, []);

  const initialFieldsToReset: IForm = React.useMemo(() => {
    const fieldsObj: any = {};
    for (const key in fields) {
      fieldsObj[key] = {
        value: fields[key].initialValue,
        error: "",
        touched: false,
        toShowError: false,
        required: fields[key].validationPattern.isRequired ?? false
      };
    }
    return fieldsObj;
  }, []);

  const [form, setForm] = React.useState<IForm>(storageInitialFields || initialFields);

  const validator = (value: string, validationPattern: ValidationPattern) => validateInput(value, validationPattern, t);

  const updateFieldValue = (key: K, value: string, doValidate?: boolean) => {
    const localDoValidate = doValidate === undefined ? true : doValidate;
    const errors = localDoValidate ? validator(value, fields[key].validationPattern) : "";

    setForm((prev) => ({
      ...deepCopy(prev),
      [key]: {
        error: errors,
        touched: true,
        toShowError:
          errors.length === 0 || (prev[key].error.length === 0 && prev[key].toShowError)
            ? false
            : prev[key].toShowError,
        value
      }
    }));
  };
  const validateField = (key: K) => {
    setForm((prev) => ({
      ...deepCopy(prev),
      [key]: {
        ...prev[key],
        error: validator(prev[key].value, fields[key].validationPattern),
        toShowError: true
      }
    }));
  };
  const resetField = (key: K) => {
    setForm((prev) => ({ ...deepCopy(prev), [key]: initialFields[key] }));
  };

  const setFieldError = (key: K, error: string) => {
    setForm((prev) => ({
      ...deepCopy(prev),
      [key]: {
        ...prev[key],
        toShowError: true,
        error
      }
    }));
  };

  const validateForm = () => {
    const formCopy = deepCopy<IForm>(form);
    for (const key in formCopy) {
      const errors = validator(formCopy[key].value, fields[key].validationPattern);
      formCopy[key].error = errors;
      formCopy[key].toShowError = true;
    }

    setForm(formCopy);
  };

  const clearShowingError = (key: K) => {
    setForm((prev) => ({
      ...deepCopy(prev),
      [key]: {
        ...prev[key],
        toShowError: false
      }
    }));
  };

  const resetForm = () => setForm(initialFieldsToReset);

  const _getFinalForm = (): FormFieldsWithMethods<K> => {
    const toReturn: any = {};

    for (const key in form) {
      toReturn[key] = {
        ...form[key],
        update: (value: string, doValidate?: boolean) => updateFieldValue(key, value, doValidate),
        validate: () => validateField(key),
        reset: () => resetField(key),
        setError: (value: string) => setFieldError(key, value),
        clearShowingError: () => clearShowingError(key)
      };
    }
    return toReturn;
  };

  const isValid = () => {
    const formAsArray: IFormField[] = Object.values(form);
    return (
      formAsArray.filter(({ error, touched, value, required }) => {
        if (required && value.length) return true;
        if (!required && !error.length) return true;
        if (touched && !error.length) return true;
        return false;
      }).length === formAsArray.length
    );
  };

  React.useEffect(() => {
    if (!!form && storage?.doStore) {
      storage.storageType === "local-storage"
        ? writeLocalStorage(storage?.storageKey || "", form)
        : writeSessionStorage(storage?.storageKey || "", form);
    }
  }, [storage, form]);

  return { fields: _getFinalForm(), validate: validateForm, reset: resetForm, isValid: isValid() };
}

export default useForm;
