import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import Group from "@components/arrangement/Group/Group";
import { navLinks } from "src/constants/navigation";

import "./NavLinks.scss";

const navLinksAsArray = Object.values(navLinks);

const NavLinks = () => {
  const { t } = useTranslation("layout");
  const { pathname } = useLocation();

  return (
    <Group className="nav-links">
      {navLinksAsArray.map((navLink, index) => {
        const isHighlighted = pathname.includes(navLink.baseUrl);

        return (
          <Link
            to={`${navLink.baseUrl}${navLink.extension ? `/${navLink.extension}` : ""}`}
            className={`nav-link p3${isHighlighted ? " active" : ""}`}
            key={index}
          >
            {t(navLink.label as any)}
          </Link>
        );
      })}
    </Group>
  );
};

export default NavLinks;
