import React, { useCallback, useEffect, useState } from "react";
import { AxiosError } from "axios";

import useRequest from "@hooks/useRequest";
import { ItemsWithPaginations, OrderDTO, PaginationMeta } from "@api/hubApi/dtos.types";
import { getOrdersHistory } from "@api/hubApi/methods";

import { IChildren } from "@components/interfaces/IChildren";

interface ContextValue {
  orderHistory: ItemsWithPaginations<OrderDTO> | null;
  orderHistoryLoading: boolean;
  orderHistoryErrorMessage: AxiosError | null;
  refetchAllOrders: (queryParams?: PaginationMeta) => Promise<void>;
}

const OrderHistoryContext = React.createContext(null as any);

export const OrderHistoryProvider = ({ children }: IChildren) => {
  const [orderHistory, setOrderHistory] = useState<ItemsWithPaginations<OrderDTO> | null>(null);

  const {
    requestTrigger: fetchAllOrders,
    isLoading,
    response,
    axiosError
  } = useRequest<ItemsWithPaginations<OrderDTO>>({ requestFn: getOrdersHistory });

  useEffect(() => {
    if (!response) return;

    setOrderHistory(response);
  }, [response]);

  const refetchAllOrders = async (queryParams?: PaginationMeta) => {
    return await fetchAllOrders(queryParams);
  };

  const contextValue: ContextValue = {
    orderHistory,
    orderHistoryLoading: isLoading,
    orderHistoryErrorMessage: axiosError,
    refetchAllOrders
  };

  return <OrderHistoryContext.Provider value={contextValue}>{children}</OrderHistoryContext.Provider>;
};

export const useOrderHistory = (): ContextValue => React.useContext(OrderHistoryContext);
