import { paths } from "@app/paths";

export default {
  path: paths.generatorEditCollectionView,
  children: [],
  lazy: async () => {
    const { EditCollectionView } = await import("./EditCollectionView");

    return {
      element: <EditCollectionView />
    };
  }
};
