export type AppStage = "dev" | "prod" | null;

const appVersion = require("../package.json").version;
export const APP_VERSION = `v${appVersion}`;

const APP_STAGE_ENV: AppStage = (process.env.REACT_APP_STAGE as AppStage) || null;
const FIREBASE_API_KEY_ENV = process.env.REACT_APP_FIREBASE_API_KEY || null;
const FIREBASE_AUTH_DOMAIN_ENV = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || null;
const FIREBASE_PROJECT_ID_ENV = process.env.REACT_APP_FIREBASE_PROJECT_ID || null;
// const FIREBASE_STORAGE_BUCKET_ENV = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || null;
// const FIREBASE_FIREBASE_MESSAGING_SENDER_ID_ENV = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || null;
const FIREBASE_APP_ID_ENV = process.env.REACT_APP_FIREBASE_APP_ID || null;
const I18N_DEBUG_MODE_ENV = process.env.REACT_APP_I18N_DEBUG_MODE || null;
const REACT_APP_DISABLE_CHECK_UNSAVED_CHANGES_ENV = process.env.REACT_APP_DISABLE_CHECK_UNSAVED_CHANGES || null;

const GO2NFT_API_URL_ENV = process.env.REACT_APP_GO2NFT_API_URL || null;

const getAppStage = () => {
  if (!APP_STAGE_ENV) {
    throw new Error(`There is not APP_STAGE provided in env`);
  }
  return APP_STAGE_ENV;
};
const getFirebaseApiKey = () => {
  if (!FIREBASE_API_KEY_ENV) {
    throw new Error(`There is not FIREBASE_API_KEY_ENV provided in env`);
  }
  return FIREBASE_API_KEY_ENV;
};
const getFirebaseAuthDomain = () => {
  if (!FIREBASE_AUTH_DOMAIN_ENV) {
    throw new Error(`There is not FIREBASE_AUTH_DOMAIN_ENV provided in env`);
  }
  return FIREBASE_AUTH_DOMAIN_ENV;
};
const getFirebaseProjectId = () => {
  if (!FIREBASE_PROJECT_ID_ENV) {
    throw new Error(`There is not FIREBASE_PROJECT_ID_ENV provided in env`);
  }
  return FIREBASE_PROJECT_ID_ENV;
};
// const getFirebaseStorageBucket = () => {
//   if (!FIREBASE_STORAGE_BUCKET_ENV) {
//     throw new Error(`There is not FIREBASE_STORAGE_BUCKET_ENV provided in env`);
//   }
//   return FIREBASE_STORAGE_BUCKET_ENV;
// };
// const getFirebaseMessagingSenderId = () => {
//   if (!FIREBASE_FIREBASE_MESSAGING_SENDER_ID_ENV) {
//     throw new Error(`There is not FIREBASE_FIREBASE_MESSAGING_SENDER_ID_ENV provided in env`);
//   }
//   return FIREBASE_FIREBASE_MESSAGING_SENDER_ID_ENV;
// };
const getFirebaseAppId = () => {
  if (!FIREBASE_APP_ID_ENV) {
    throw new Error(`There is not FIREBASE_APP_ID_ENV provided in env`);
  }
  return FIREBASE_APP_ID_ENV;
};
const getGo2NFTApiUrl = () => {
  if (!GO2NFT_API_URL_ENV) {
    throw new Error(`There is not GO2NFT_API_URL_ENV provided in env`);
  }
  return GO2NFT_API_URL_ENV;
};

const getI18NDebugMode = () => {
  if (!I18N_DEBUG_MODE_ENV) {
    throw new Error(`There is not I18N_DEBUG_MODE_ENV provided in env`);
  }
  return I18N_DEBUG_MODE_ENV === "true";
};

const getDisableCheckUnsavedChanges = () => {
  if (!REACT_APP_DISABLE_CHECK_UNSAVED_CHANGES_ENV) {
    throw new Error("There is not REACT_APP_DISABLE_CHECK_UNSAVED_CHANGES_ENV provided in env");
  }

  return REACT_APP_DISABLE_CHECK_UNSAVED_CHANGES_ENV === "true";
};

export const APP_STAGE = getAppStage();
export const FIREBASE_API_KEY = getFirebaseApiKey();
export const FIREBASE_AUTH_DOMAIN = getFirebaseAuthDomain();
export const FIREBASE_PROJECT_ID = getFirebaseProjectId();
// export const FIREBASE_STORAGE_BUCKET = getFirebaseStorageBucket();
// export const FIREBASE_MESSAGIGN_SENDER_ID = getFirebaseMessagingSenderId();
export const FIREBASE_APP_ID = getFirebaseAppId();
export const GO2NFT_API_URL = getGo2NFTApiUrl();
export const I18N_DEBUG_MODE = getI18NDebugMode();
export const DISABLE_CHECK_UNSAVED_CHANGES = getDisableCheckUnsavedChanges();
