import Avatar from "@design-system/Avatar/Avatar";
import createImgUrl from "@utils/createImgUrl";

interface Props {
  sizePx: number;
  bgColorHex: string;
  file: File | string;
}

const CustomAvatar = ({ sizePx, bgColorHex, file }: Props) => {
  const isImgFile = file instanceof File;
  const isImgUrl = typeof file === "string";

  return (
    <div style={{ backgroundColor: bgColorHex, borderRadius: "100%", height: sizePx }}>
      {isImgUrl && <Avatar img={file as string} sizePx={sizePx} />}
      {isImgFile && <Avatar img={createImgUrl(file as File)} sizePx={sizePx} />}
    </div>
  );
};

export default CustomAvatar;
