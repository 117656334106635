import React from "react";

import { IChildren } from "@components/interfaces/IChildren";
import { useGetCompanyDetails } from "../CompanyDetailsContext/GetCompanyDetails.context";
import { useSetBrandsDetails } from "./SetBrandsDetails.context";

interface IEffectsBrandDetailsContext {}

const EffectsBrandDetailsContext = React.createContext(null as any);

export const EffectsBrandDetailsProvider = ({ children }: IChildren) => {
  const { companyUserResponse } = useGetCompanyDetails();

  const { colorPicker, brandLogoDropzone, bgImageDropzone } = useSetBrandsDetails();

  React.useEffect(() => {
    if (!companyUserResponse) return;

    const { partner } = companyUserResponse.client;

    colorPicker.uploadInitialColorHex(partner.logoBgColor || "");
    brandLogoDropzone.uploadInitialImage(partner?.logoTransparentUrl || null);

    bgImageDropzone.uplaodInitialMainImage({
      file: null,
      fileUrl: partner?.logoBackgroundUrl || "",
      fileId: null,
      fileName: null
    });
  }, [companyUserResponse]);

  return <EffectsBrandDetailsContext.Provider value={{}}>{children}</EffectsBrandDetailsContext.Provider>;
};

export const useEffectsBrandDetails = (): IEffectsBrandDetailsContext => React.useContext(EffectsBrandDetailsContext);
