import React, { ChangeEvent, FocusEvent } from "react";
import Stack from "@components/arrangement/Stack/Stack";
import Group from "@components/arrangement/Group/Group";
import { useTranslation } from "react-i18next";

import "./Input.scss";

export type Orientation = "ltr" | "rtl";

export interface IInput {
  name?: string;
  label: string;
  value: string;
  onChange(e: ChangeEvent<HTMLInputElement>): void;
  onBlur?(e: FocusEvent<HTMLInputElement>): void;
  onEnter?: () => void;
  error: string;
  placeholder?: string;
  type?: React.HTMLInputTypeAttribute;
  className?: string;
  directInputClassName?: string;
  inputRequiremenets?: string;
  autoFocus?: boolean;
  required?: boolean;
  fullSize?: boolean;
  variant?: "primary" | "secondary";
  orientation?: Orientation;
  externalSourceFocus?: boolean;
  maxLenght?: number;
  minLenght?: number;
  min?: number;
  max?: number;
  canPaste?: boolean;
  cutOverflow?: boolean;
  testid?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  withCounter?: boolean;
  Tag?: "input" | "textarea";
}

export type Seal = "correct" | "wrong" | "default";

const Input = ({
  name,
  label,
  value,
  onChange,
  onBlur,
  onEnter = () => {},
  error,
  placeholder = "",
  type = "text",
  className,
  directInputClassName = "",
  inputRequiremenets,
  autoFocus = false,
  required = false,
  fullSize = false,
  variant = "primary",
  orientation = "ltr",
  externalSourceFocus = false,
  maxLenght,
  minLenght,
  min,
  max,
  canPaste = true,
  cutOverflow = false,
  testid = "",
  children,
  disabled = false,
  withCounter,
  Tag = "input"
}: IInput) => {
  const [focused, setFocused] = React.useState(externalSourceFocus);
  const [hover, setHover] = React.useState(false);
  const inputRef = React.useRef<HTMLInputElement>(null as any);
  const id = React.useId();

  const inputState = (): Seal => {
    if (!!error) return "wrong";
    return "default";
  };

  const cutOverflowFn = (e: ChangeEvent<HTMLInputElement>) => {
    if (typeof cutOverflow === "boolean" && cutOverflow === false) return onChange(e);
    if (typeof max !== "number" && type === "number") return onChange(e);
    if (typeof maxLenght !== "number" && type === "number") return onChange(e);

    const asNumber = Number(e.currentTarget.value);
    if (isNaN(asNumber)) return onChange(e);
    if (asNumber <= max! && e.currentTarget.value.length <= maxLenght!) return onChange(e);

    const incomingValue = e.currentTarget.value;
    const howMuchOverflow = incomingValue.length - (maxLenght ? maxLenght : incomingValue.length - 1);
    e.currentTarget.value = incomingValue.slice(0, incomingValue.length - howMuchOverflow);

    return onChange(e);
  };

  const focusInput = () => setFocused(true);
  const blurInput = () => setFocused(false);
  const handleHover = () => setHover(true);
  const handleUnhover = () => setHover(false);

  const inputClick = () => {
    if (!inputRef.current) return;
    focusInput();

    inputRef.current.focus();
  };

  const inputBlur = () => {
    if (!inputRef.current) return;
    blurInput();
    onBlur && onBlur;

    inputRef.current.blur();
  };

  React.useEffect(() => {
    if (!inputRef.current) return;

    if (externalSourceFocus) return inputRef.current.focus();

    return inputRef.current.blur();
  }, [externalSourceFocus]);

  return (
    <Stack
      rowGap={4}
      className={`total-input ${variant} ${inputState()}${className ? ` ${className}` : ""}${
        required ? " required" : ""
      } ${orientation}${disabled ? " disabled" : ""}`}
      onClick={inputClick}
      onBlur={inputBlur}
      fullSize={fullSize}
      alignItems={orientation === "ltr" ? "flex-start" : "flex-end"}
    >
      <Group fullWidth justifyContent="space-between" alignItems="center">
        {label && (
          <label className="p3 semiBold label" htmlFor={id}>
            {label}
          </label>
        )}
        {withCounter && (
          <label className="p3 semibBold black-3">
            {value.length}
            {"/"}
            {maxLenght}
          </label>
        )}
      </Group>
      <div
        className={`input-wrapper${hover ? " hover" : ""}${focused ? " focus" : ""} ${inputState()}`}
        onMouseEnter={handleHover}
        onMouseLeave={handleUnhover}
      >
        <Tag
          id={id}
          name={name}
          className={`input-itself ${directInputClassName}`}
          value={value}
          onChange={cutOverflowFn as any}
          placeholder={placeholder}
          type={type}
          ref={inputRef as any}
          disabled={disabled}
          title={inputRequiremenets}
          autoFocus={autoFocus}
          autoComplete="off"
          minLength={minLenght}
          maxLength={maxLenght}
          min={min}
          max={max}
          data-testid={testid}
          onKeyDown={(e) => {
            e.key === "Enter" && onEnter();
          }}
          onPaste={(e) => {
            if (!canPaste) {
              e.preventDefault();
            }
          }}
        />
        {/* {inputState() !== "default" && <img src={`/images/${inputState()}.svg`} height={20} width={20} />} */}
        {children}
      </div>
      {!!error && <p className="p3 error">{error}</p>}
    </Stack>
  );
};

export default Input;
