import React from "react";

import { IChildren } from "@components/interfaces/IChildren";
import { useLocation } from "react-router-dom";
import { generatorEditBasePath, paths } from "@app/paths";

interface IColorsContext {}

const ColorsContext = React.createContext(null as any);

const baseBgColor = "#f6f6f6";
const accentColor = `#d9d9d8`;

export const ColorsProvider = ({ children }: IChildren) => {
  const { pathname } = useLocation();

  React.useEffect(() => {
    if (pathname.includes(generatorEditBasePath) || pathname.includes(paths.generatorCreateCollectionView)) {
      document.body.style.background = accentColor;
    } else {
      document.body.style.background = baseBgColor;
    }
  }, [pathname]);

  return <ColorsContext.Provider value={{}}>{children}</ColorsContext.Provider>;
};

export const useColorsContext = (): IColorsContext => React.useContext(ColorsContext);
