import React from "react";

import { IChildren } from "@components/interfaces/IChildren";

const GlobalToastsV2 = React.createContext(null as any);

type ToastActivityState = "starting" | "present" | "stopping";
type ToastVariant = "default" | "success" | "error" | "info" | "warning";
type ToastDuration = number | null;

export interface StartToastArguments {
  message: string;
  withLoading: boolean;
  variant: ToastVariant;
  duration: ToastDuration;
}

export interface ToastState {
  id: string;
  toastState: ToastActivityState;
  message: string;
  withLoading: boolean;
  variant: ToastVariant;
  duration: ToastDuration;
  active: boolean;
}

const STARTING_TOAST_MS = 500;
const STOPPING_TOAST_MS = 800;
const MAX_TOASTS_NUMBER = 3;

interface IGlobalToastsV2 {
  startToast: (args: StartToastArguments) => string;
  hideToast: (id: string) => void;
  toasts: ToastState[];
  activeToastId: string;
  hideActiveToast: () => void;
}

export const GlobalToastsV2Provider = ({ children }: IChildren) => {
  const [toasts, setToasts] = React.useState<ToastState[]>([]);
  const [stopQueue, setStopQueue] = React.useState<string[]>([]);

  const startToast = ({ duration, message, variant, withLoading }: StartToastArguments) => {
    const _random = Math.random().toString(16).slice(2);
    const id = `${variant}-${_random}-${Date.now()}`;

    const toastDetails: ToastState = {
      duration,
      id,
      message,
      toastState: "starting",
      variant,
      withLoading,
      active: false
    };
    _addToQueue(toastDetails);
    return id;
  };

  const _processToast = (id: string) => {
    const toastById = toasts.find((toast) => toast.id === id);
    const isToastPresent = !!toastById;
    if (!isToastPresent) return;

    updateToastById(id, { toastState: "present" });

    const toastDuration = toastById.duration;

    if ((typeof toastDuration === "number" && toastDuration <= 0) || !toastDuration) {
      return;
    }

    const timer = setTimeout(() => {
      _stopToast(id);
      clearTimeout(timer);
    }, toastDuration);
  };

  const _stopToast = (id: string) => {
    const toastById = toasts.find((toast) => toast.id === id);

    if (!toastById) return;

    updateToastById(id, { toastState: "stopping" });

    const timer = setTimeout(() => {
      _removeFromQueue(id);
      clearTimeout(timer);
    }, STOPPING_TOAST_MS);
  };

  const _addToStopQueue = (id: string) => {
    setStopQueue((prev) => [...prev, id]);
  };

  const hideToast = (id: string) => {
    _addToStopQueue(id);
  };

  const _addToQueue = (toast: ToastState) => {
    if (toasts.length >= MAX_TOASTS_NUMBER) return;

    setToasts((prev) => [...prev, toast]);
  };

  const _removeFromQueue = (id: string) => {
    setToasts((prev) => prev.filter((toast) => toast.id !== id));
  };

  const updateToastById = (id: string, payload: Partial<ToastState>) => {
    setToasts((prev) => {
      if (prev.length === 0) return prev;
      else
        return prev.map((prevToast) => {
          if (prevToast.id !== id) return prevToast;
          else
            return {
              ...prevToast,
              ...payload
            };
        });
    });
  };

  React.useEffect(() => {
    const isAnyToast = toasts.length > 0;
    if (!isAnyToast) return;

    const isAnyActive = toasts.some((toast) => toast.active);

    if (!isAnyActive) {
      return setToasts((prev) => {
        if (prev.length === 0) return prev;
        else
          return prev.map((toast, index) => {
            if (index === 0) {
              return { ...toast, active: true };
            } else return toast;
          });
      });
    }
  }, [toasts]);

  React.useEffect(() => {
    const activeToast = toasts.find((toast) => toast.active);

    if (!activeToast) return;

    if (activeToast.toastState === "starting") {
      const timer = setTimeout(() => {
        _processToast(activeToast!.id);
        clearTimeout(timer);
      }, STARTING_TOAST_MS);
    }
  }, [toasts]);

  React.useEffect(() => {
    if (toasts.some((toast) => stopQueue.includes(toast.id))) {
      const toast = toasts.find((toast) => {
        return stopQueue.some((stopId) => stopId === toast.id);
      });

      const timer = setTimeout(() => {
        _stopToast(toast!.id);
        setStopQueue((prev) => prev.filter((el) => el !== toast!.id));
        clearTimeout(timer);
      }, STOPPING_TOAST_MS);
    }
  }, [toasts, stopQueue]);

  const activeToastId = toasts.find((toast) => toast.active)?.id || "-1";

  const hideActiveToast = () => {
    hideToast(activeToastId);
  };

  return (
    <GlobalToastsV2.Provider
      value={{
        startToast,
        hideToast,
        toasts,
        activeToastId,
        hideActiveToast
      }}
    >
      {children}
    </GlobalToastsV2.Provider>
  );
};

export const useGlobalToastsV2 = (): IGlobalToastsV2 => React.useContext(GlobalToastsV2);
