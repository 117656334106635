import React from "react";

import { IChildren } from "@components/interfaces/IChildren";
import { clearSessionStorageItems, readSessionStorage } from "@utils/storage";
import useCheckbox from "@hooks/useCheckbox";
import useWriteSessionStorage from "@hooks/useWriteSessionStorage";

interface IPaymentAgreements {
  isTermsChecked: boolean;
  isPersonalDataChecked: boolean;
  toggleTerms: () => void;
  togglePersonalData: () => void;
  isTermsValid: boolean;
  isPersonalDataValid: boolean;
  termsError: string;
  personalDataError: string;
  toShowTermsError: boolean;
  toShowPersonalDataError: boolean;
  validateAgreements: () => void;
  resetAgreements: () => void;
  areAgreementsValid: boolean;
}

const PaymentAgreements = React.createContext(null as any);

export const AGREEMENTS_KEY = "agreements_key";

interface IAgreements {
  isTermsChecked: boolean;
  personalDataError: string;
  isPersonalDataChecked: boolean;
  termsError: string;
  toShowTermsError: boolean;
  toShowPersonalDataError: boolean;
}

const initialAgreements: IAgreements = {
  isPersonalDataChecked: false,
  personalDataError: "",
  isTermsChecked: false,
  termsError: "",
  toShowTermsError: false,
  toShowPersonalDataError: false
};

export const PaymentAgreementsProvider = ({ children }: IChildren) => {
  const initialAgreementsStorage = (JSON.parse(readSessionStorage(AGREEMENTS_KEY) ?? "null") ??
    initialAgreements) as IAgreements;

  const {
    isChecked: isTermsChecked,
    check: checkTerms,
    uncheck: uncheckTerms,
    toggle: toggleTerms,
    validate: validateTerms,
    error: termsError,
    toShowError: toShowTermsError,
    isValid: isTermsValid,
    reset: resetTerms
  } = useCheckbox(true, initialAgreementsStorage.isTermsChecked, {
    initialError: initialAgreementsStorage.termsError,
    initialToShowError: initialAgreementsStorage.toShowTermsError
  });
  const {
    isChecked: isPersonalDataChecked,
    check: checkPersonalData,
    uncheck: uncheckPersonalData,
    toggle: togglePersonalData,
    validate: validatePersonalData,
    error: personalDataError,
    toShowError: toShowPersonalDataError,
    isValid: isPersonalDataValid,
    reset: resetPersonalData
  } = useCheckbox(true, initialAgreementsStorage.isPersonalDataChecked, {
    initialError: initialAgreementsStorage.personalDataError,
    initialToShowError: initialAgreementsStorage.toShowPersonalDataError
  });

  const validateAgreements = () => {
    validateTerms();
    validatePersonalData();
  };

  const resetAgreements = () => {
    clearSessionStorageItems([AGREEMENTS_KEY]);
    resetTerms();
    resetPersonalData();
  };

  const areAgreementsValid = isTermsChecked && isPersonalDataChecked;

  useWriteSessionStorage(AGREEMENTS_KEY, {
    isTermsChecked,
    isPersonalDataChecked,
    termsError,
    personalDataError,
    toShowTermsError,
    toShowPersonalDataError
  });

  return (
    <PaymentAgreements.Provider
      value={{
        isTermsChecked,
        isPersonalDataChecked,
        toggleTerms,
        togglePersonalData,
        isTermsValid,
        isPersonalDataValid,
        termsError,
        personalDataError,
        toShowTermsError,
        toShowPersonalDataError,
        validateAgreements,
        resetAgreements,
        areAgreementsValid
      }}
    >
      {children}
    </PaymentAgreements.Provider>
  );
};

export const usePaymentAgreements = (): IPaymentAgreements => React.useContext(PaymentAgreements);
