import React from "react";

import { CountryDTO, PaginationMeta } from "@api/hubApi/dtos.types";
import { getSupportedCountries } from "@api/hubApi/methods";

import useRequest from "@hooks/useRequest";

import { IChildren } from "@components/interfaces/IChildren";
import useWriteSessionStorage from "@hooks/useWriteSessionStorage";
import { readSessionStorage } from "@utils/storage";

interface ICountriesContext {
  countries: CountryDTO[] | null;
  fetchSupportedCountries: (queryParams?: PaginationMeta | undefined) => Promise<void>;
}

const COUNTRIES_STORAGE_KEY = "countries_key";

const CountriesContext = React.createContext(null as any);

export const CountriesProvider = ({ children }: IChildren) => {
  const initialCountries = JSON.parse(readSessionStorage(COUNTRIES_STORAGE_KEY) || "null") as CountryDTO[] | null;

  const [countries, setCountries] = React.useState<CountryDTO[] | null>(initialCountries);

  const onSuccess = (data: CountryDTO[]) => setCountries(data);
  const { requestTrigger: fetchSupportedCountries } = useRequest<CountryDTO[]>({
    requestFn: getSupportedCountries,
    setDataOnSuccess: onSuccess
  });

  React.useEffect(() => {
    if (!!initialCountries) return;

    fetchSupportedCountries();
  }, [initialCountries]);

  useWriteSessionStorage(COUNTRIES_STORAGE_KEY, countries);

  return (
    <CountriesContext.Provider value={{ countries, fetchSupportedCountries }}>{children}</CountriesContext.Provider>
  );
};

export const useCountries = (): ICountriesContext => React.useContext(CountriesContext);
