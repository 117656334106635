import { AxiosError } from "axios";

import { useModals } from "@contexts/GlobalModalsContext";

import { parseAxiosError } from "@utils/axiosErrorParser";

import Portal from "@components/overlay/Portal/Portal";
import Modal from "@components/overlay/Modal/Modal";
import Backdrop from "@components/ux/Backdrop/Backdrop";
import G2nButton from "@components/common/Buttons/Button";
import Stack from "@components/arrangement/Stack/Stack";

import "./BrokenRequest.scss";
import { useTranslation } from "react-i18next";

interface IBrokenRequest {
  error?: AxiosError;
}

const BrokenRequest = ({ error }: IBrokenRequest) => {
  const { t: commonT } = useTranslation("common");
  const { closeModal, activeModal } = useModals();

  const repeatAndClose = () => {
    if (activeModal?.action) {
      activeModal.action();
    }
    closeModal();
  };

  const isError = !!error;

  return (
    <Portal>
      <Backdrop isOpen>
        <Modal closeAction={closeModal} closeOnClickOutside>
          <Stack alignItems="center" className="broken-request">
            <img src="/images/common/exclamation-mark.svg" className="mb-xs-5 icon" />
            <h2 className="title t-xs-c mb-xs-1">{error?.message || commonT("errorMessage")}</h2>
            {isError && <p className="description t-xs-c p3 mb-xs-5">{parseAxiosError(error)}</p>}
            <G2nButton action={repeatAndClose} variant="secondary-btn" uppercase>
              Try again and close
            </G2nButton>
          </Stack>
        </Modal>
      </Backdrop>
    </Portal>
  );
};

export default BrokenRequest;
