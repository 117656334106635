import React from "react";

import { IChildren } from "@components/interfaces/IChildren";
import useForm from "@hooks/useForm";
import { ProfilePartnerKeys, partnerFormSchema } from "@constants/profile/userProfileDataFormSchema";
import useBoolean from "@hooks/useBoolean";
import { VerificationStatus } from "@api/hubApi/dtos.types";
import { useCompanyProfile } from "../CompanyProfileProvider";
import { useTranslation } from "react-i18next";
import { UseFormResponse } from "@hooks/types/useForm.types";

interface ISetPartnerDetailsContext {
  partnerForm: UseFormResponse<ProfilePartnerKeys>;
  isPublic: boolean;
  setPublic: () => void;
  defaultIsPublic: VerificationStatus | null;
  handleDefaultIsPublic: (verStatus: VerificationStatus | null) => void;
  partnerTags: string[];
  addPartnerTag: () => void;
  removePartnerTag: () => void;
  uploadPartnerTags: (tags: string[]) => void;
  toggleIsPublicCheckbox: () => void;
}

const SetPartnerDetailsContext = React.createContext(null as any);

export const SetPartnerDetailsProvider = ({ children }: IChildren) => {
  const { t } = useTranslation("profile");
  const { companyPartnerInputChanged } = useCompanyProfile();
  const partnerForm = useForm<ProfilePartnerKeys>(partnerFormSchema);
  const { tag } = partnerForm.fields;

  const [partnerTags, setPartnerTags] = React.useState<string[]>([]);
  const [isPublic, setPublic, setNotPublic, togglePublic] = useBoolean(false);
  const [defaultIsPublic, setDefaultIsPublic] = React.useState<VerificationStatus | null>(null);
  const handleDefaultIsPublic = (verStatus: VerificationStatus | null) => setDefaultIsPublic(verStatus);

  const addPartnerTag = () => {
    if ((tag.error && tag.error !== t("partnerProfile.inputs.tags.error")) || !tag.value.length) return tag.validate();
    const trimmedLowercased = tag.value.trim().toLowerCase();
    if (!partnerTags.includes(trimmedLowercased)) {
      setPartnerTags((prevState) => [...prevState, trimmedLowercased]);
    }
    companyPartnerInputChanged.onInputChange("", tag.update);
  };

  const removePartnerTag = (value: string) => {
    const trimmedLowercased = value.trim().toLowerCase();
    setPartnerTags((prevState) => prevState.filter((tag) => tag !== trimmedLowercased));
    companyPartnerInputChanged.setInputChanged();
  };

  const uploadPartnerTags = (tags: string[]) => setPartnerTags(tags);

  const toggleIsPublicCheckbox = () => {
    togglePublic();
    companyPartnerInputChanged.setInputChanged();
  };

  return (
    <SetPartnerDetailsContext.Provider
      value={{
        partnerForm,
        isPublic,
        setPublic,
        defaultIsPublic,
        handleDefaultIsPublic,

        partnerTags,
        addPartnerTag,
        removePartnerTag,
        uploadPartnerTags,
        toggleIsPublicCheckbox
      }}
    >
      {children}
    </SetPartnerDetailsContext.Provider>
  );
};

export const useSetPartnerDetails = (): ISetPartnerDetailsContext => React.useContext(SetPartnerDetailsContext);
