import React from "react";

import { IChildren } from "@components/interfaces/IChildren";
import useColorPicker, { ColorPickerReturn } from "@app/routing/ProfileSettings/hooks/useColorPicker";
import { ACCEPTED_PNG_MIME, MAX_SIZE_BRAND_LOGO_FILE } from "@constants/constants";
import useDropzoneV4, { UseDropzoneV4Return } from "@design-system/Dropzone/hooks/useDropzoneV4";
import { IDropzoneV3, useDropzoneV3 } from "@design-system/Dropzone/DropzoneV3.context";

interface ISetBrandsDetailsContext {
  colorPicker: ColorPickerReturn;
  brandLogoDropzone: UseDropzoneV4Return;
  bgImageDropzone: IDropzoneV3;
}

const SetBrandsDetailsContext = React.createContext(null as any);

export const SetBrandsDetailsProvider = ({ children }: IChildren) => {
  const colorPicker = useColorPicker();
  const brandLogoDropzone = useDropzoneV4({
    validation: { maxFileSizeB: MAX_SIZE_BRAND_LOGO_FILE, expectedMimeTypes: [ACCEPTED_PNG_MIME] }
  });
  const bgImageDropzone = useDropzoneV3();

  return (
    <SetBrandsDetailsContext.Provider value={{ colorPicker, brandLogoDropzone, bgImageDropzone }}>
      {children}
    </SetBrandsDetailsContext.Provider>
  );
};

export const useSetBrandsDetails = (): ISetBrandsDetailsContext => React.useContext(SetBrandsDetailsContext);
