import React from "react";

import { IChildren } from "@components/interfaces/IChildren";
import { useGetCompanyDetails } from "../CompanyDetailsContext/GetCompanyDetails.context";
import { useSetUserDetails } from "./SetUserDetails.context";

interface IEffectsUserDetailsContext {}

const EffectsUserDetailsContext = React.createContext(null as any);

export const EffectsUserDetailsProvider = ({ children }: IChildren) => {
  const { companyUserResponse } = useGetCompanyDetails();
  const { shippingDataForm } = useSetUserDetails();

  React.useEffect(() => {
    if (!companyUserResponse) return;

    const { fullName: newFullName, phoneNumber: newPhoneNumber } = companyUserResponse;

    const { fullName, phoneNumber } = shippingDataForm.fields;
    fullName.update(newFullName ?? "");
    phoneNumber.update(newPhoneNumber ?? "");
  }, [companyUserResponse]);

  return <EffectsUserDetailsContext.Provider value={{}}>{children}</EffectsUserDetailsContext.Provider>;
};

export const useEffectsUserDetails = (): IEffectsUserDetailsContext => React.useContext(EffectsUserDetailsContext);
