import { AccordionTabProps } from "@app/routing/Help/components/Accordion/Accordion.types";

const baseFaqQuestions: Omit<AccordionTabProps, "id">[] = [
  {
    question: "questions.q1.question",
    answer: "questions.q1.answer"
  },
  {
    question: "questions.q2.question",
    answer: "questions.q2.answer"
  },
  {
    question: "questions.q3.question",
    answer: "questions.q3.answer"
  }
];

export const faqQuestions: AccordionTabProps[] = baseFaqQuestions.map((item, index) => ({
  ...item,
  id: `q-${index + 1}`
}));

export const faqQuestionIds = faqQuestions.map((item) => item.id);
