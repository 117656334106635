import React from "react";

import { IChildren } from "@components/interfaces/IChildren";
import { UpdateBrandDetailsProvider } from "./UpdateBrandDetails.context";
import { SetBrandsDetailsProvider } from "./SetBrandsDetails.context";
import { RulesBrandsDetailsProvider } from "./RulesBrandsDetails.context";
import { EffectsBrandDetailsProvider } from "./EffectsBrandDetails.context";

import { DropzoneProviderV3 } from "@design-system/Dropzone/DropzoneV3.context";
import EffectsImagesBrands from "./EffectsImagesBrands";
import { useTranslation } from "react-i18next";
import { ACCEPTED_MIME_TYPES, ACCEPTED_MIME_TYPES_FOR_HUMAN, MAX_SIZE_IMAGE_FILE } from "@constants/constants";
import HocCropperComp from "@app/routing/Generator/contexts/HocCropperContext/HocCropperComp";

interface IBrandsDetailsContext {}

const BrandsDetailsContext = React.createContext(null as any);

export const BrandsDetailsProvider = ({ children }: IChildren) => {
  const { t } = useTranslation("validations");

  const dropzoneValidation = React.useMemo(() => {
    return {
      acceptedMimeTypes: {
        value: ACCEPTED_MIME_TYPES,
        error: t("invalidMimeType", { mimeTypes: ACCEPTED_MIME_TYPES_FOR_HUMAN.join(", ") })
      },
      eachImageMaxSize: {
        value: MAX_SIZE_IMAGE_FILE,
        error: t("imageTooBig", { maxSize: MAX_SIZE_IMAGE_FILE })
      }
    };
  }, []);

  return (
    <BrandsDetailsContext.Provider value={{}}>
      <HocCropperComp>
        <DropzoneProviderV3
          validation={dropzoneValidation}
          initialCanUploadMultiple={false}
          initialImage={null}
          initialRestImages={null}
        >
          <SetBrandsDetailsProvider>
            <RulesBrandsDetailsProvider>
              <UpdateBrandDetailsProvider>
                <EffectsBrandDetailsProvider>
                  <EffectsImagesBrands>{children}</EffectsImagesBrands>
                </EffectsBrandDetailsProvider>
              </UpdateBrandDetailsProvider>
            </RulesBrandsDetailsProvider>
          </SetBrandsDetailsProvider>
        </DropzoneProviderV3>
      </HocCropperComp>
    </BrandsDetailsContext.Provider>
  );
};

export const useBrandsDetails = (): IBrandsDetailsContext => React.useContext(BrandsDetailsContext);
