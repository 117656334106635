interface ISpinner {
  spinnerImgSrc: string;
  sizePx: number;
}

const Spinner = ({ sizePx, spinnerImgSrc }: ISpinner) => {
  return <img src={spinnerImgSrc} alt="spinner" height={sizePx} width={sizePx} className="ds-spinner" />;
};

export default Spinner;
