import React from "react";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";

import { paths } from "@app/paths";
import { IChildren } from "@components/interfaces/IChildren";
import { useWindowDimensions } from "../hooks/useWindowDimensions";

import Stack from "@components/arrangement/Stack/Stack";

import "./MobileGuards.scss";

const MobileCommunicate = () => {
  const { t } = useTranslation("layout");
  return (
    <Stack fullSize alignItems="center" justifyContent="center" rowGap={40} className="mobile-guard">
      <img src="/images/desktop.svg" />

      <Stack alignItems="center" rowGap={24}>
        <p className="p4 fs-3 black-2 t-xs-c" style={{ whiteSpace: "pre-wrap" }}>
          {t("mobile.title")}
        </p>

        <p className="fs-6 black-2 text-center description">{t("mobile.description")}</p>
      </Stack>
    </Stack>
  );
};

const UNPROTECTED_PATHS = [paths.authLogin, paths.authAction] as string[];

const MobileGuard = ({ children }: IChildren) => {
  const { width } = useWindowDimensions();
  const isDeviceMobile = (isMobile || width < 1000) && !UNPROTECTED_PATHS.includes(window.location.pathname);

  React.useEffect(() => {
    if (isDeviceMobile) {
      document.body.style["overflow"] = "hidden";
    }
    return () => {
      document.body.style["overflow"] = "unset";
    };
  }, [width]);

  return (
    <>
      {children}
      {isDeviceMobile && <MobileCommunicate />}
    </>
  );
};

export default MobileGuard;
