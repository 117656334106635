import React from "react";

import { IChildren } from "@components/interfaces/IChildren";

export interface IViewImagesContext {
  viewState: ViewState;
  modifyViewState: (state: ViewState) => void;
  resetViewState: () => void;
}

export type ViewState = "cropper" | "image" | "placeholder" | "loading";

const ViewImagesContext = React.createContext(null as any);

export const ViewImagesProvider = ({ children }: IChildren) => {
  const [viewState, setViewState] = React.useState<ViewState>("placeholder");

  const modifyViewState = (state: ViewState) => {
    setViewState(state);
  };

  const resetViewState = () => setViewState("placeholder");

  return (
    <ViewImagesContext.Provider value={{ viewState, modifyViewState, resetViewState }}>
      {children}
    </ViewImagesContext.Provider>
  );
};

export const useViewImages = (): IViewImagesContext => React.useContext(ViewImagesContext);
