import React from "react";
import { CompanyDetailsProvider } from "./CompanyDetailsContext/CompanyDetails.context";
import { PartnerDetailsProvider } from "./PartnerDetailsContext/PartnerDetails.context";
import { UserDetailsProvider } from "./UserDetailsContext/UserDetails.context";
import { PasswordDetailsProvider } from "./PasswordDetailsContext/PasswordDetails.context";
import { useGlobalToastV3 } from "@contexts/GlobalToastV3.context";
import { GetCompanyDetailsProvider } from "./CompanyDetailsContext/GetCompanyDetails.context";
import { IInputChange, useInputChange } from "@hooks/useInputChange";
import { BrandsDetailsProvider } from "./BrandsDetailsContext/BrandsDetailsProvider";
import { ViewCompanyProfileProvider } from "./ViewCompanyProfile.context";
import { useTranslation } from "react-i18next";

interface ICompanyProfileContext {
  runToastOnStart: (msg?: string) => void;
  runToastOnSuccess: (msg?: string) => void;
  runToastOnError: (msg?: string) => void;
  companyPartnerInputChanged: IInputChange;
}

interface Props {
  children: React.ReactNode;
}

const CompanyProfileContext = React.createContext(null as any);

const CompanyProfileProvider = ({ children }: Props) => {
  const { updateToast } = useGlobalToastV3();
  const { t } = useTranslation("common");

  const onBeforeStart = (msg?: string) =>
    updateToast({ bg: "black", isLoading: true, message: msg || t("processing") });
  const onRequestSuccess = (msg?: string) =>
    updateToast({ bg: "success", isLoading: false, message: msg || t("dataHasBeenSaved"), closeAfterMs: 2500 });
  const onRequestError = (msg?: string) =>
    updateToast({ bg: "danger", isLoading: false, message: msg || t("somethingWenWrong"), closeAfterMs: 2500 });

  const companyPartnerInputChanged = useInputChange();

  return (
    <CompanyProfileContext.Provider
      value={{
        runToastOnStart: onBeforeStart,
        runToastOnSuccess: onRequestSuccess,
        runToastOnError: onRequestError,
        companyPartnerInputChanged
      }}
    >
      <GetCompanyDetailsProvider>
        <PartnerDetailsProvider>
          <UserDetailsProvider>
            <CompanyDetailsProvider>
              <BrandsDetailsProvider>
                <PasswordDetailsProvider>
                  <ViewCompanyProfileProvider>{children}</ViewCompanyProfileProvider>
                </PasswordDetailsProvider>
              </BrandsDetailsProvider>
            </CompanyDetailsProvider>
          </UserDetailsProvider>
        </PartnerDetailsProvider>
      </GetCompanyDetailsProvider>
    </CompanyProfileContext.Provider>
  );
};

export const useCompanyProfile = (): ICompanyProfileContext => React.useContext(CompanyProfileContext);

export default CompanyProfileProvider;
