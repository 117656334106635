import React from "react";

import { IChildren } from "@components/interfaces/IChildren";
import { AddressDTO, CountryDTO } from "@api/hubApi/dtos.types";
import { deepCopy } from "src/utils/deepCopy";
import { clearSessionStorageItems, readSessionStorage, writeSessionStorage } from "@utils/storage";
import {
  COMPANY_ADDRESS_LENGTH,
  COMPANY_CITY_LENGTH,
  COMPANY_NAME_LENGTH,
  COMPANY_POST_CODE_LENGTH,
  COMPANY_TAX_NUMBER_LENGTH
} from "@constants/inputsMaxLength";
import { validateInput } from "@utils/validateInput";
import { useTranslation } from "react-i18next";
import { useCountries } from "../../../../contexts/CountriesContext";
import { useSetCompanyDetails } from "@app/routing/ProfileSettings/contexts/CompanyDetailsContext/SetCompanyDetails.context";

interface IPaymentDeliveryContext {
  isDeliveryDifferentThanCompany: boolean;
  toggleDeliveryDifferent: () => void;
  countries: CountryDTO[] | null;
  companyDetails: ICompanyDetails;
  finalCompanyDetails: AddressDTO;
  handleChangeCompanyDetails: (e: React.ChangeEvent<HTMLInputElement>) => void;
  updateCompanyDetail: (key: keyof ICompanyDetails, payload: Partial<IFormField>) => void;
  updateCompanyCountry: (value: string) => void;
  validateCompanyDetails: () => void;
  resetCompanyErrors: () => void;
  areCompanyDetailsValid: boolean;
  resetCompanyDetails: () => void;
}
interface IFormField {
  value: string;
  error: string;
  toShowError: boolean;
}

const FORM_FIELDS = [
  "companyName",
  "companyTaxNumber",
  "companyCountry",
  "companyCity",
  "companyPostCode",
  "companyAddress"
] as const;

type FieldsKey = (typeof FORM_FIELDS)[number];
type MakeFormFields<T extends string, U, Z> = Record<keyof Record<T, U>, Z>;

export type ICompanyDetails = MakeFormFields<FieldsKey, {}, IFormField>;

const PaymentDeliveryContext = React.createContext(null as any);

const enhancedFormField = (value: string, emptyError: string): IFormField => {
  return {
    error: value.trim().length === 0 ? emptyError : "",
    toShowError: false,
    value
  };
};

const validateField = (key: keyof ICompanyDetails, value: string, t: Function) => {
  const validationSchemas: Record<keyof ICompanyDetails, string> = {
    companyName: validateInput(value, { isRequired: true, maxLength: COMPANY_NAME_LENGTH }, t),
    companyTaxNumber: validateInput(value, { isRequired: true, maxLength: COMPANY_TAX_NUMBER_LENGTH }, t),
    companyCountry: validateInput(value, { isRequired: true }, t),
    companyCity: validateInput(value, { isRequired: true, maxLength: COMPANY_CITY_LENGTH }, t),
    companyPostCode: validateInput(value, { isRequired: true, maxLength: COMPANY_POST_CODE_LENGTH }, t),
    companyAddress: validateInput(value, { isRequired: true, maxLength: COMPANY_ADDRESS_LENGTH }, t)
  };
  const error = validationSchemas[key];
  return error;
};

export const COMPANY_DETAILS_STORAGE_KEY = "company_details_key";
export const DELIVERY_DIFFERENT_THAN_COMPANY_KEY = "delivery_different_than_company_key";

const getInitialCompanyDetails = (
  initialValues: Record<keyof ICompanyDetails, string>,
  emptyError: string
): ICompanyDetails => {
  const init = {} as ICompanyDetails;

  for (const [key, value] of Object.entries(initialValues)) {
    init[key as keyof ICompanyDetails] = { ...enhancedFormField(value, emptyError) };
  }

  return init;
};

export const CompanyDetailsProvider = ({ children }: IChildren) => {
  const { t } = useTranslation("validations");

  const { countries } = useCountries();
  const { companyDataForm } = useSetCompanyDetails();

  const { address, city, country, name, nip, postCode } = companyDataForm.fields;

  const initialCompanyDetails = getInitialCompanyDetails(
    {
      companyName: name.value ?? "",
      companyTaxNumber: nip.value ?? "",
      companyCountry: country.value ?? "",
      companyCity: city.value ?? "",
      companyPostCode: postCode.value ?? "",
      companyAddress: address.value ?? ""
    },
    t("required")
  );

  const initialCompanyDetailsStorage =
    JSON.parse(readSessionStorage(COMPANY_DETAILS_STORAGE_KEY) ?? "null") || (initialCompanyDetails as ICompanyDetails);

  const [companyDetails, setCompanyDetails] = React.useState<ICompanyDetails>(initialCompanyDetailsStorage);

  const updateCompanyDetail = (key: keyof ICompanyDetails, payload: Partial<IFormField>) => {
    setCompanyDetails((prev) => ({ ...prev, [key]: { ...prev[key], ...payload } }));
  };

  const updateCompanyDatailsValue = (value: string, key: keyof ICompanyDetails) => {
    const prevDetailState = companyDetails[key];
    const error = validateField(key, value, t);
    const payload = { value, error, toShowError: error.length === 0 ? false : prevDetailState.toShowError };
    updateCompanyDetail(key, payload);
  };

  const handleChangeCompanyDetails = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    updateCompanyDatailsValue(value, name as keyof ICompanyDetails);
  };

  const updateCompanyCountry = (value: string) => updateCompanyDatailsValue(value, "companyCountry");

  const validateCompanyDetails = () => {
    const copy = deepCopy<ICompanyDetails>(companyDetails);
    const objectKeys = Object.keys(companyDetails);

    objectKeys.forEach((key) => (copy[key as keyof ICompanyDetails].toShowError = true));
    setCompanyDetails(copy);
  };

  const resetCompanyErrors = () => {
    const copy = deepCopy<ICompanyDetails>(companyDetails);
    const objectKeys = Object.keys(companyDetails);

    objectKeys.forEach((key) => (copy[key as keyof ICompanyDetails].toShowError = false));
    setCompanyDetails(copy);
  };

  const initialDeliveryDifferentThanCompany =
    JSON.parse(readSessionStorage(DELIVERY_DIFFERENT_THAN_COMPANY_KEY) || "null") || false;
  const [isDeliveryDifferentThanCompany, setIsDeliveryDifferentThanCompany] = React.useState<boolean>(
    initialDeliveryDifferentThanCompany
  );
  const toggleDeliveryDifferent = () => setIsDeliveryDifferentThanCompany((prev) => !prev);

  const areCompanyDetailsValid =
    Object.values(companyDetails).filter((detail: IFormField) => detail.error.length > 0).length === 0;

  const resetCompanyDetails = () => {
    // setCompanyDetails(initialCompanyDetails);
    clearSessionStorageItems([COMPANY_DETAILS_STORAGE_KEY, DELIVERY_DIFFERENT_THAN_COMPANY_KEY]);
  };

  const companyCountry = countries?.find((country) => country.name === companyDetails.companyCountry?.value);

  const finalCompanyDetails: AddressDTO = {
    companyName: companyDetails.companyName.value || name.value || "",
    country: companyCountry || countries?.find((c) => c.name === country.value),
    city: companyDetails.companyCity.value || city.value || "",
    postCode: companyDetails.companyPostCode.value || postCode.value || "",
    addressLine: companyDetails.companyAddress.value || address.value || ""
  };

  React.useEffect(() => {
    writeSessionStorage(COMPANY_DETAILS_STORAGE_KEY, companyDetails);
  }, [companyDetails]);

  React.useEffect(() => {
    if (isDeliveryDifferentThanCompany !== null) {
      writeSessionStorage(DELIVERY_DIFFERENT_THAN_COMPANY_KEY, isDeliveryDifferentThanCompany);
    }
  }, [isDeliveryDifferentThanCompany]);

  return (
    <PaymentDeliveryContext.Provider
      value={{
        isDeliveryDifferentThanCompany,
        finalCompanyDetails,
        toggleDeliveryDifferent,
        countries,
        companyDetails,
        handleChangeCompanyDetails,
        updateCompanyDetail,
        updateCompanyCountry,
        validateCompanyDetails,
        resetCompanyErrors,
        areCompanyDetailsValid,
        resetCompanyDetails
      }}
    >
      {children}
    </PaymentDeliveryContext.Provider>
  );
};

export const useCompanyDetails = (): IPaymentDeliveryContext => React.useContext(PaymentDeliveryContext);
