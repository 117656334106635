export const scrollToId = (idSelector: string) => {
  const tag = document.getElementById(idSelector);

  if (!tag) return;

  tag.scrollIntoView(true);
};

export const scrollToIdSmooth = (idSelector: string) => {
  const tag = document.getElementById(idSelector);

  if (!tag) return;

  tag.scrollIntoView({ block: "start", behavior: "smooth" });
};
export const scrollToIdCenter = (idSelector: string) => {
  const tag = document.getElementById(idSelector);

  if (!tag) return;

  tag.scrollIntoView({ block: "center", behavior: "smooth" });
};

export const scrollToClassName = (className: string) => {
  const tag = document.getElementsByClassName(className)[0];

  if (!tag) return;

  tag.scrollIntoView({ block: "center", behavior: "smooth" });
};
