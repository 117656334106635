import { useModals } from "@contexts/GlobalModalsContext";

import CancelOrder from "./OrderHistory/CancelOrder/CancelOrder";
import BrokenRequest from "@app/routing/Ordering/PaymentDelivery/components/modals/BrokenRequest";

const GlobalModals = () => {
  const { activeModal } = useModals();

  if (!activeModal) return <></>;

  const { type } = activeModal;
  if (type === "cancelOrder") return <CancelOrder order={activeModal.object} />;
  if (type === "brokenRequest") return <BrokenRequest error={activeModal.object} />;

  return <></>;
};

export default GlobalModals;
