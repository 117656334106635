import Navbar from "@components/layoutElements/Navbar/Navbar";
import Footer from "@components/layoutElements/Footer/Footer";

import "./AppLayout.scss";

interface AppLayoutProps {
  children: React.ReactNode;
  withoutLinks?: boolean;
  withoutProfile?: boolean;
  additionalClass?: string;
}

const AppLayout = ({
  children,
  withoutLinks = false,
  withoutProfile = false,
  additionalClass = ""
}: AppLayoutProps) => {
  return (
    <div className={`app-layout ${additionalClass}`}>
      <header className="app-navbar">
        <Navbar withoutLinks={withoutLinks} withoutProfile={withoutProfile} />
      </header>
      <main className="content">{children}</main>
      <footer className="app-footer">
        <Footer />
      </footer>
    </div>
  );
};

export default AppLayout;
