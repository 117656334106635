import React from "react";

import Group from "@components/arrangement/Group/Group";
import AccordionPlusIcon from "../AccordionPlusIcon/AccordionPlusIcon";
import AccordionTabContent from "../AccordionTabContent/AccordionTabContent";

import { AccordionTabProps, AccordionActiveState } from "../Accordion.types";

import "./AccordionTab.scss";

const AccordionTab = ({ question, answer, links, initialVariant = "default", id }: AccordionTabProps) => {
  const [variant, setVariant] = React.useState<AccordionActiveState>(initialVariant);

  const turnDefault = () => setVariant("default");
  const turnHover = () => setVariant("hover");
  const turnActive = () => setVariant("active");

  const handleHover = () => {
    if (variant === "default") {
      return turnHover();
    }
    return;
  };

  const handleClick = () => {
    if (variant === "active") {
      return turnHover();
    }
    return turnActive();
  };

  const handleBlur = () => {
    if (variant === "hover") {
      return turnDefault();
    }
    return;
  };

  const dividerClasses = `accordion-tab-divider accordion-tab-divider-${variant}`;

  return (
    <div className="accordion-tab" id={id}>
      <div
        role="button"
        onClick={handleClick}
        onMouseOver={handleHover}
        onMouseLeave={handleBlur}
        className="accordion-tab-button"
      >
        <Group justifyContent="space-between" fullWidth>
          <h3 className="medium">{question}</h3>
          <AccordionPlusIcon variant={variant} />
        </Group>
      </div>

      <AccordionTabContent answer={answer} links={links} variant={variant} />
      <hr className={dividerClasses} />
    </div>
  );
};

export default AccordionTab;
