import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

import i18n from "@translations/i18n";
import { GLOBAL_TIMEOUT } from "src/constants/constants";
import { auth } from "src/firebase-config";
import { getTimezonOffset } from "src/utils/getTimezonOffset";

export interface ApiClientOptions {
  baseUrl: string;
}

export class ApiClient {
  private axios: AxiosInstance;

  constructor(private options: ApiClientOptions) {
    this.axios = this.createAxiosInstance();
  }

  private createAxiosInstance() {
    const g2nLocaleHeader = {
      lang: i18n.language,
      timezoneOffset: getTimezonOffset()
    };

    const instance = axios.create({
      baseURL: this.options.baseUrl,
      headers: {
        "Content-Type": "application/json",
        "g2n-locale": JSON.stringify(g2nLocaleHeader)
      }
    });

    instance.interceptors.request.use(async (config) => {
      const accessToken = await auth.currentUser?.getIdToken();
      config.headers.Authorization = accessToken;

      return config;
    });

    return instance;
  }

  async sendRequest<T>(config: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), GLOBAL_TIMEOUT);

    const response = await this.axios.request({
      ...config,
      signal: controller.signal
    });

    clearTimeout(timeoutId);
    return response;
  }
}
