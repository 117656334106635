import React from "react";

import ModalCloseButton from "@components/dataEntry/Buttons/ModalCloseButton/ModalCloseButton";

import { useClickOutside } from "@hooks/useClickOutside";

import "./Modal.scss";

type ButtonAction = () => void;

interface ModalProps {
  children: React.ReactNode;
  closeAction?: ButtonAction;
  closeOnClickOutside?: boolean;
}

const Modal = ({ children, closeAction = () => {}, closeOnClickOutside = false }: ModalProps) => {
  const modalRef = React.useRef(null);

  useClickOutside(modalRef, () => {
    if (closeOnClickOutside && !!closeAction) {
      closeAction();
    }
  });

  return (
    <div className="modal" ref={modalRef}>
      {closeAction && <ModalCloseButton onClick={closeAction} className="modal-close-btn" />}
      {children}
    </div>
  );
};

export default Modal;
