// import { collections } from "@app/Generator/data/collectionPages";
import { collectionsViewExtensions, paths } from "src/app/paths";

export const ORDER_HISTORY = "Order history";
export const OFFER = "Offer";
export const CONTACT = "Contact";
export const MY_NFTS = "My nfts";

type NavLinkPartialKeys =
  | "dashboard"
  // "myNfts"
  | "generator"
  | "orderHistory"
  // | "offer"
  // | "contact"
  | "help";
type NavLinkKeys = keyof typeof paths;

interface NavLinkValues {
  baseUrl: (typeof paths)[NavLinkKeys];
  extension: string;
  label: string;
}

type NavLinks = Record<NavLinkPartialKeys, NavLinkValues>;

export const navLinks: Readonly<NavLinks> = {
  dashboard: {
    baseUrl: paths.dashboard,
    extension: "",
    label: "navLinks.dashboard"
  },
  generator: {
    baseUrl: paths.generator,
    extension: `${collectionsViewExtensions}`,
    label: "navLinks.generator"
  },
  orderHistory: {
    baseUrl: paths.orderHistory,
    extension: "",
    label: "navLinks.orderHistory"
  },
  // offer: {
  //   url: paths.offer,
  //   label: "navLinks.offer"
  // },
  // contact: {
  //   url: paths.contact,
  //   label: "navLinks.contact"
  // },
  help: {
    baseUrl: paths.help,
    extension: "",
    label: "navLinks.help"
  }
};
