import React from "react";
import { AxiosError } from "axios";
// import { useLocation } from "react-router-dom";

import { useDeliveryDetailsV2 } from "./DeliveryDetailsContextV2";
import { useDeliveries } from "../../../../contexts/DeliveriesContext";
import { useCountries } from "../../../../contexts/CountriesContext";

import useBoolean from "@hooks/useBoolean";
import useWriteSessionStorage from "@hooks/useWriteSessionStorage";

import { DeliveryDTO } from "@api/hubApi/dtos.types";
import { clearSessionStorageItems, readSessionStorage, writeSessionStorage } from "@utils/storage";

import { IChildren } from "@components/interfaces/IChildren";

export type RadioGroupGeneric = DeliveryDTO & {
  isActive: boolean;
};

interface IDeliveryOptionsContext {
  deliveryOptions: DeliveryDTO[] | null;
  currentlyActive: DeliveryDTO | null;
  areAnyDeliveriesForThisCountry: boolean;
  pickOption: (id: string) => void;
  isValid: boolean;
  validate: () => void;
  resetOption: () => void;
  resetDeliveryOptions: () => void;
  countryCode: string | null;
  isLoading: boolean;
  axiosError: AxiosError;
  userError: string;
  toShowError: boolean;
}

const DeliveryOptionsContext = React.createContext(null as any);

export const PICKED_DELIVERY_OPTION = "picked_delivery_option";
export const DELIVERY_RADIO_META_KEY = "delivery_radio_meta_key";

interface DeliveryRadioMeta {
  storedToShowError: boolean;
  storedError: string;
}

export const DeliveryOptionsProvider = ({ children }: IChildren) => {
  const { finalDeliveryDetails } = useDeliveryDetailsV2();

  const { deliveries, isDeliveriesLoading, requestError } = useDeliveries();

  const countryIdByCountry = finalDeliveryDetails?.country?.id || null;

  const deliveriesByCountryId = deliveries?.filter((delivery) => delivery.country.id === countryIdByCountry) || null;

  const areAnyDeliveriesForThisCountry = (deliveriesByCountryId || []).length > 0;

  const savedDeliveryOption = JSON.parse(readSessionStorage(PICKED_DELIVERY_OPTION) || "null") as DeliveryDTO | null;

  const [pickedDeliveryOption, setPickedDeliveryOption] = React.useState<DeliveryDTO | null>(savedDeliveryOption);

  const selectDeliveryOption = (deliveryId: string) => {
    const selectedDeliveryOption = deliveries?.find((delivery) => delivery.id === deliveryId) || null;
    setPickedDeliveryOption(selectedDeliveryOption);
  };

  const resetDeliveryOption = () => {
    clearSessionStorageItems([PICKED_DELIVERY_OPTION]);
    setPickedDeliveryOption(null);
  };

  const resetDeliveryOptions = () => {
    resetDeliveryOption();
    clearSessionStorageItems([DELIVERY_RADIO_META_KEY]);
    // setError("");
    resetToShowError();
  };

  React.useEffect(() => {
    if (deliveriesByCountryId?.length === 1) {
      const firstOption = deliveriesByCountryId[0];
      selectDeliveryOption(firstOption.id);
    }
  }, [deliveriesByCountryId]);

  React.useEffect(() => {
    writeSessionStorage(PICKED_DELIVERY_OPTION, pickedDeliveryOption);
  }, [pickedDeliveryOption]);

  React.useEffect(() => {
    if (!!countryIdByCountry) return;

    resetDeliveryOption();
  }, [countryIdByCountry]);

  const storedDeliveryOptionsMeta = JSON.parse(
    readSessionStorage(DELIVERY_RADIO_META_KEY) || "null"
  ) as DeliveryRadioMeta | null;

  const initialToShowError = !!storedDeliveryOptionsMeta?.storedToShowError;
  const initialError = storedDeliveryOptionsMeta?.storedError || "";

  const [toShowError, setToShowError, resetToShowError] = useBoolean(initialToShowError);
  const [error, setError] = React.useState(initialError);

  const deliveryOptionsError = () => {
    if (!pickedDeliveryOption && areAnyDeliveriesForThisCountry) {
      return "You need to specify delivery method";
    }
    if (!!pickedDeliveryOption && !areAnyDeliveriesForThisCountry) {
      return "There are no delivery options available for this country yet";
    }
    if (!pickedDeliveryOption) {
      return "Pick delivery country";
    } else return "";
  };

  const isDeliveryOptionsValid = deliveryOptionsError().length === 0;

  const validateDeliveryOptions = () => setToShowError();

  React.useEffect(() => {
    if (!!pickedDeliveryOption) {
      resetToShowError();
    }
  }, [pickedDeliveryOption]);

  React.useEffect(() => {
    setError(deliveryOptionsError());
  }, [pickedDeliveryOption, areAnyDeliveriesForThisCountry, countryIdByCountry]);

  useWriteSessionStorage<DeliveryRadioMeta>(DELIVERY_RADIO_META_KEY, {
    storedToShowError: toShowError,
    storedError: error
  });

  return (
    <DeliveryOptionsContext.Provider
      value={{
        deliveryOptions: deliveriesByCountryId,
        currentlyActive: pickedDeliveryOption,
        areAnyDeliveriesForThisCountry,
        pickOption: selectDeliveryOption,
        isValid: isDeliveryOptionsValid,
        validate: validateDeliveryOptions,
        resetOptions: resetDeliveryOption,
        resetDeliveryOptions,
        countryCode: countryIdByCountry,
        isLoading: isDeliveriesLoading,
        axiosError: requestError,
        userError: error,
        toShowError
      }}
    >
      {children}
    </DeliveryOptionsContext.Provider>
  );
};

export const useDeliveryOptions = (): IDeliveryOptionsContext => React.useContext(DeliveryOptionsContext);
