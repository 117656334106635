import React from "react";
import { useSearchParams } from "react-router-dom";

import { paths } from "@app/paths";

import AppLayout from "@components/layoutElements/Layouts/AppLayout/AppLayout";
import VerifyEmail from "./VerifyEmail/VerifyEmail";
import ResetPassword from "./ResetPassword/ResetPassword";

const AuthAction = () => {
  const [params] = useSearchParams();

  const mode = params.get("mode");
  const oobCode = params.get("oobCode");

  switch (mode) {
    case "verifyEmail": {
      return <VerifyEmail oobCode={oobCode!} />;
    }
    case "resetPassword": {
      return <ResetPassword oobCode={oobCode!} />;
    }
    default:
      return <></>;
  }
};

export default {
  path: paths.authAction,
  element: (
    <AppLayout withoutLinks withoutProfile>
      <AuthAction />
    </AppLayout>
  ),
  children: []
};
