import { uploadImage, uploadMultipleImages } from "../methods";
import { FeImageDTO, FileCategories, ImageWithCrop, UpsertImageV2 } from "../dtos.types";

export const getImageId = async (category: FileCategories, imageWithCrop: ImageWithCrop): Promise<null | string> => {
  if (!!imageWithCrop.original.fileId) return null;

  const imageId = (await uploadImage(imageWithCrop, category)).data.id;
  return imageId;
};

export const getUpsertImages = async (
  category: FileCategories,
  mainFileWithCrop?: ImageWithCrop,
  restImagesWithoutCrop?: FeImageDTO[] | null
): Promise<UpsertImageV2[]> => {
  const localImages = [];

  if (!!mainFileWithCrop) {
    if (!mainFileWithCrop?.original.fileId) {
      const imageId = (await uploadImage(mainFileWithCrop, category)).data.id;
      localImages.push({ main: true, id: imageId });
    } else {
      localImages.push({ main: true, id: mainFileWithCrop.original.fileId });
    }
  }

  if (!!restImagesWithoutCrop && (restImagesWithoutCrop || []).length > 0) {
    const notUploadedYet = restImagesWithoutCrop.filter((rImg) => !rImg.fileId);
    const alreadyUploaded = restImagesWithoutCrop.filter((rImg) => !!rImg.fileId);

    const freshUploadedPhotos = (await uploadMultipleImages(notUploadedYet, category)).data;

    for (const uploadedPhoto of alreadyUploaded) {
      localImages.push({ id: uploadedPhoto.fileId!, main: false });
    }

    for (const freshPhoto of freshUploadedPhotos) {
      localImages.push({ id: freshPhoto.id, main: false });
    }
  }

  return localImages;
};
