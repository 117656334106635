import { useTranslation } from "react-i18next";

import Group from "@components/arrangement/Group/Group";
import SocialButtons from "@components/project-specific/SocialButtons/SocialButtons";

import { APP_VERSION } from "src/config";
import { TERMS_AND_CONDITIONS_URL, PRIVACY_POLICY_URL } from "src/constants/constants";

import "./Footer.scss";

const Footer = () => {
  const { t } = useTranslation("layout");

  return (
    <div className="footer">
      <div className="footer-content">
        <div className="footer-content-pdfs">
          <a
            className="p3 underline black-4"
            href={TERMS_AND_CONDITIONS_URL}
            target="_blank"
            rel="noreferrer noopener nofollow"
          >
            {t("footer.termsAndConditions")}
          </a>
          <span className="pdf-separator">&</span>
          <a
            className="p3 underline black-4"
            href={PRIVACY_POLICY_URL}
            target="_blank"
            rel="noreferrer noopener nofollow"
          >
            {t("footer.privacyPolicy")}
          </a>
        </div>

        <div className="footer-content-socials">
          <SocialButtons />

          <p className="p3 black-4">{t("footer.copyright", { year: new Date().getFullYear() })}</p>
          <p className="p3 black-4">{APP_VERSION}</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
