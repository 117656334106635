export const TERMS_AND_CONDITIONS_URL = "https://go2nft.io/pdfs/Go2NFT-Terms-and-Conditions-of-the-hub.pdf";
export const PRIVACY_POLICY_URL = "https://go2nft.io/pdfs/Go2NFT-Privacy-Policy.pdf";

export const GO2NFT_MOBILE_APP_APP_STORE = "https://apps.apple.com/us/app/go2nft/id1631507369";
export const GO2NFT_MOBILE_APP_GOOGLE_PLAY = "https://play.google.com/store/apps/details?id=io.go2nft.mobile&pli=1";

export const TUTORIAL_LINK = "";

export const GLOBAL_TIMEOUT = 8000; //[ms]

const EURO_SIGN = "€";
const DOLLAR_SIGN = "$";

export const CURRENCY = EURO_SIGN;

export const ACCEPTED_JPEG_MIME_FOR_HUMAN = "jpeg";
export const ACCEPTED_PNG_MIME_FOR_HUMAN = "png";

export const ACCEPTED_MIME_TYPES_FOR_HUMAN = [ACCEPTED_JPEG_MIME_FOR_HUMAN, ACCEPTED_PNG_MIME_FOR_HUMAN];

export const ACCEPTED_JPEG_MIME = `image/${ACCEPTED_JPEG_MIME_FOR_HUMAN}`;
export const ACCEPTED_PNG_MIME = `image/${ACCEPTED_PNG_MIME_FOR_HUMAN}`;

export const ACCEPTED_MIME_TYPES = [ACCEPTED_JPEG_MIME, ACCEPTED_PNG_MIME];

export const MAX_SIZE_IMAGE_FILE = 10485760; // 10MB
export const MAX_SIZE_BRAND_LOGO_FILE = 2097152; // 2MB
