import React from "react";

import { IChildren } from "@components/interfaces/IChildren";
import { CropperRef, FixedCropperRef } from "react-advanced-cropper";
import useBoolean from "@hooks/useBoolean";
import { createFileFromBlob, createImgUrlFromFile, dataURLtoBlob } from "@utils/createFileFromBlob";
import useCleanup from "@hooks/useCleanup";

export interface ICropperContext {
  uploadCropperImage: (image: File) => void;
  resetCropper: () => void;
  isCropping: boolean;

  cropperRef: React.RefObject<CropperRef>;
  croppedImage: File | null;
  croppedImageUrl: string;
  rawCropperImage: File | null;
  rawCropperImageUrl: string;
  onCropChange: (cropper: CropperRef) => void;
  onCrop: () => void;
}

const CropperContext = React.createContext(null as any);

export const CropperProvider = ({ children }: IChildren) => {
  const cropperRef = React.useRef<FixedCropperRef>(null);
  const [rawCropperImage, setRawCropperImage] = React.useState<File | null>(null);
  const [rawCropperImageUrl, setRawCropperImageUrl] = React.useState("");
  const [croppedImage, setCroppedImage] = React.useState<File | null>(null);
  const [croppedImageUrl, setCroppedImageUrl] = React.useState("");
  const [isCropping, startCropping, stopCropping] = useBoolean(false);

  const uploadCropperImage = (image: File) => {
    setRawCropperImage(image);
    setRawCropperImageUrl(createImgUrlFromFile(image));
  };

  const onCropChange = (cropper: FixedCropperRef) => {
    cropper.getCoordinates();
    cropper.getCanvas();
  };

  const unmountRawImgURL = () => URL.revokeObjectURL(rawCropperImageUrl);

  const onCrop = () => {
    const current = cropperRef?.current;
    if (!current) return;

    startCropping();
    const croppedImageUrl = current.getCanvas()?.toDataURL(rawCropperImage?.type);
    setCroppedImageUrl(croppedImageUrl!);

    const blob = dataURLtoBlob(croppedImageUrl!);
    const file = createFileFromBlob(blob, rawCropperImage?.name || "");
    setCroppedImage(file);
    setRawCropperImage(null);
    setRawCropperImageUrl("");
    unmountRawImgURL();
    stopCropping();
  };

  const resetCropper = () => {
    setRawCropperImage(null);
    setRawCropperImageUrl("");
    setCroppedImage(null);
    setCroppedImageUrl("");
  };

  useCleanup(unmountRawImgURL);

  return (
    <CropperContext.Provider
      value={{
        cropperRef,
        croppedImage,
        croppedImageUrl,
        onCropChange,
        onCrop,
        uploadCropperImage,
        isCropping,
        rawCropperImage,
        rawCropperImageUrl,
        resetCropper
      }}
    >
      {children}
    </CropperContext.Provider>
  );
};

export const useCropper = (): ICropperContext => React.useContext(CropperContext);
