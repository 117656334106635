import { UseFormFields } from "@hooks/types/useForm.types";

export type CompanyDataKeys = "name" | "nip" | "country" | "city" | "postCode" | "address" | "discount";

export const companyFormSchema: Readonly<UseFormFields<CompanyDataKeys>> = {
  name: {
    initialValue: "",
    validationPattern: {
      isRequired: true,
      minLength: 2
    }
  },
  nip: {
    initialValue: "",
    validationPattern: {
      minLength: 2,
      isRequired: false
    }
  },
  country: {
    initialValue: "",
    validationPattern: {
      minLength: 2,
      isRequired: false
    }
  },
  city: {
    initialValue: "",
    validationPattern: {
      minLength: 2,
      isRequired: false
    }
  },
  postCode: {
    initialValue: "",
    validationPattern: {
      minLength: 2,
      isRequired: false
    }
  },
  address: {
    initialValue: "",
    validationPattern: {
      minLength: 2,
      isRequired: false
    }
  },
  discount: {
    initialValue: "",
    validationPattern: {
      isRequired: false
    }
  }
};
