import { UseFormFields } from "@hooks/types/useForm.types";

export type RegisterFieldsKeys = "email" | "password" | "confirmPassword";

export const registerFormSchema: Readonly<UseFormFields<RegisterFieldsKeys>> = {
  email: {
    initialValue: "",
    validationPattern: {
      isEmail: true,
      isRequired: true
    }
  },
  password: {
    initialValue: "",
    validationPattern: {
      minLength: 6,
      isRequired: true
    }
  },
  confirmPassword: {
    initialValue: "",
    validationPattern: {
      minLength: 6,
      isRequired: true
    }
  }
};
