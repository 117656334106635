import {
  COMPANY_ADDRESS_LENGTH,
  COMPANY_CITY_LENGTH,
  COMPANY_NAME_LENGTH,
  COMPANY_POST_CODE_LENGTH
} from "@constants/inputsMaxLength";
import { UseFormFields } from "@hooks/types/useForm.types";

export type IDeliveryDetails = "companyName" | "country" | "city" | "postCode" | "address";
type InitialDeliveryDetails = Record<IDeliveryDetails, InitialFormField>;

interface InitialFormField {
  initialValue: string;
  initialError: string;
  initialToShowError: boolean;
}

export const deliveryDetailsSchema = ({
  address,
  city,
  companyName,
  country,
  postCode
}: InitialDeliveryDetails): UseFormFields<IDeliveryDetails> => {
  return {
    companyName: {
      initialValue: companyName.initialValue,
      initialError: companyName.initialError,
      initialToShowError: companyName.initialToShowError,
      validationPattern: { maxLength: COMPANY_NAME_LENGTH, isRequired: false }
    },
    country: {
      initialValue: country.initialValue,
      initialError: country.initialError,
      initialToShowError: country.initialToShowError,
      validationPattern: { isRequired: true }
    },
    city: {
      initialValue: city.initialValue,
      initialError: city.initialError,
      initialToShowError: country.initialToShowError,
      validationPattern: {
        isRequired: true,
        maxLength: COMPANY_CITY_LENGTH,
        isPureString: true
      }
    },
    postCode: {
      initialValue: postCode.initialValue,
      initialError: country.initialError,
      initialToShowError: country.initialToShowError,
      validationPattern: {
        isRequired: true,
        maxLength: COMPANY_POST_CODE_LENGTH
      }
    },
    address: {
      initialValue: address.initialValue,
      initialError: address.initialError,
      initialToShowError: address.initialToShowError,
      validationPattern: {
        isRequired: true,
        maxLength: COMPANY_ADDRESS_LENGTH
      }
    }
  };
};
