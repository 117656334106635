import React from "react";

import { IChildren } from "@components/interfaces/IChildren";
import { useGlobalToastV3 } from "./GlobalToastV3.context";

interface IGlobalToastsV3ShortcutContext {
  startToast: (msg: string) => void;
  stopToastSuccess: (msg: string, duration?: number) => void;
  stopToastError: (msg: string, duration?: number) => void;
}

const GlobalToastsV3ShortcutContext = React.createContext(null as any);

export const GlobalToastsV3ShortcutProvider = ({ children }: IChildren) => {
  const { updateToast } = useGlobalToastV3();

  const startToast = (msg: string) => updateToast({ isLoading: true, bg: "black", message: msg });
  const stopToastSuccess = (msg: string, duration?: number) =>
    updateToast({ isLoading: false, bg: "success", message: msg, closeAfterMs: duration || 2000 });
  const stopToastError = (msg: string, duration?: number) =>
    updateToast({ isLoading: false, bg: "danger", message: msg, closeAfterMs: duration || 2000 });

  return (
    <GlobalToastsV3ShortcutContext.Provider value={{ startToast, stopToastSuccess, stopToastError }}>
      {children}
    </GlobalToastsV3ShortcutContext.Provider>
  );
};

export const useGlobalToastsShortcut = (): IGlobalToastsV3ShortcutContext =>
  React.useContext(GlobalToastsV3ShortcutContext);
