import React from "react";

import { IChildren } from "@components/interfaces/IChildren";
import { SetPasswordDetailsProvider } from "./SetPasswordDetails.context";
import { RulesPasswordDetailsProvider } from "./RulesPasswordDetails.context";
import { UpdatePasswordDetailsProvider } from "./UpdatePasswordDetails.context";

interface IPasswordDetailsContext {}

const PasswordDetailsContext = React.createContext(null as any);

export const PasswordDetailsProvider = ({ children }: IChildren) => {
  return (
    <PasswordDetailsContext.Provider value={{}}>
      <SetPasswordDetailsProvider>
        <RulesPasswordDetailsProvider>
          <UpdatePasswordDetailsProvider>{children}</UpdatePasswordDetailsProvider>
        </RulesPasswordDetailsProvider>
      </SetPasswordDetailsProvider>
    </PasswordDetailsContext.Provider>
  );
};

export const usePasswordDetails = (): IPasswordDetailsContext => React.useContext(PasswordDetailsContext);
