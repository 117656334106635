import React from "react";
import useBoolean from "./useBoolean";
import { useTranslation } from "react-i18next";

interface UseCheckboxValue {
  isChecked: boolean;
  check: () => void;
  uncheck: () => void;
  toggle: () => void;
  validate: () => void;
  error: string;
  toShowError: boolean;
  isValid: boolean;
  reset: () => void;
}

const useCheckbox = (
  desiredState: boolean,
  initialState: boolean,
  options?: {
    initialError?: string;
    initialToShowError?: boolean;
  }
): UseCheckboxValue => {
  const { t } = useTranslation("validations");
  const [isChecked, check, uncheck, toggle, reset] = useBoolean(initialState);
  const [error, setError] = React.useState(options?.initialError || "");
  const [toShowError, setToShowError] = React.useState(options?.initialToShowError || false);

  const validate = () => {
    if (isChecked !== desiredState) {
      setError(t("required"));
      setToShowError(true);
    }
  };

  const _resetMeta = () => {
    setError("");
    setToShowError(false);
  };

  const resetCheckboxes = () => {
    _resetMeta();
    reset();
  };

  const isValid = error.length === 0 && isChecked === desiredState;

  React.useEffect(() => {
    if (isChecked === desiredState) {
      _resetMeta();
    }
  }, [isChecked]);

  return { isChecked, check, uncheck, toggle, validate, error, toShowError, isValid, reset: resetCheckboxes };
};

export default useCheckbox;
