import { UseFormFields } from "@hooks/types/useForm.types";

export type LoginFieldsKeys = "email" | "password";

export const loginFormSchema: Readonly<UseFormFields<LoginFieldsKeys>> = {
  email: {
    initialValue: "",
    validationPattern: {
      isRequired: true,
      isEmail: true
    }
  },
  password: {
    initialValue: "",
    validationPattern: {
      isRequired: false,
      minLength: 6
    }
  }
};
