import { paths } from "@app/paths";

export default {
  path: paths.profile,
  lazy: async () => {
    const { ProfileSettings } = await import("./ProfileSettings");

    return {
      element: <ProfileSettings />
    };
  },
  children: []
};
