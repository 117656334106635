import React from "react";

import { IChildren } from "@components/interfaces/IChildren";
import useRequest from "@hooks/useRequest";
import { UserDTO } from "@api/hubApi/dtos.types";
import { getUser } from "@api/hubApi/methods";
import { useLocation } from "react-router-dom";
import { paths } from "@app/paths";

interface IGetCompanyDetailsContext {
  fetchCompanyUser: () => void;
  companyUserResponse: UserDTO | null;
  isCompanyUserLoading: boolean;
}

const GetCompanyDetailsContext = React.createContext(null as any);

export const GetCompanyDetailsProvider = ({ children }: IChildren) => {
  const { pathname } = useLocation();
  const {
    requestTrigger: fetchCompanyUser,
    response: companyUserResponse,
    isLoading: isCompanyUserLoading
  } = useRequest<UserDTO>({
    requestFn: getUser
  });

  React.useLayoutEffect(() => {
    if (pathname.includes(paths.auth)) return;

    fetchCompanyUser();
  }, [pathname]);

  return (
    <GetCompanyDetailsContext.Provider value={{ fetchCompanyUser, companyUserResponse, isCompanyUserLoading }}>
      {children}
    </GetCompanyDetailsContext.Provider>
  );
};

export const useGetCompanyDetails = (): IGetCompanyDetailsContext => React.useContext(GetCompanyDetailsContext);
