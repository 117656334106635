import React from "react";
import { useNavigate } from "react-router-dom";

import { pathsGenerator } from "@app/paths";

import { useUpdateCollections } from "./UpdateCollections.context";

import { IChildren } from "@components/interfaces/IChildren";

interface IHandlersCollectionsContext {
  onEditCollection: (collectionId: string) => void;
  onGenerateReportCollection: (collectionId: string, setIds: string[]) => void;
  onDeleteCollection: (collectionId: string) => void;
}

const HandlersCollectionsContext = React.createContext(null as any);

export const HandlersCollectionsProvider = ({ children }: IChildren) => {
  const navigate = useNavigate();
  const { triggerGenerateRaport, triggerDeleteCollection } = useUpdateCollections();

  const onEditCollection = (collectionId: string) => navigate(`/${pathsGenerator}/edit/${collectionId}`);
  const onGenerateReportCollection = (collectionId: string, setIds: string[]) =>
    triggerGenerateRaport({ collectionId, setIds });
  const onDeleteCollection = (collectionId: string) => triggerDeleteCollection(collectionId);

  return (
    <HandlersCollectionsContext.Provider value={{ onEditCollection, onGenerateReportCollection, onDeleteCollection }}>
      {children}
    </HandlersCollectionsContext.Provider>
  );
};

export const useHandlersCollections = (): IHandlersCollectionsContext => React.useContext(HandlersCollectionsContext);
