import React from "react";

import { IChildren } from "@components/interfaces/IChildren";
import useRequest from "@hooks/useRequest";
import { ItemsWithPaginations, ProductWithImagesDTO } from "@api/hubApi/dtos.types";
import { getListOfAllNFCs } from "@api/hubApi/methods";
import { AxiosError } from "axios";
import { readSessionStorage, writeSessionStorage } from "@utils/storage";

interface IGetNfcTagsContext {
  fetchAllNfcs: () => void;
  isFetchingAllNfcs: boolean;
  beNfcTags: ProductWithImagesDTO[] | null;
  axiosError: AxiosError | null;
}

const GetNfcTagsContext = React.createContext(null as any);

const BE_NFC_TAGS_CACHE = "be_nfc_tags_cache";

export const GetNfcTagsProvider = ({ children }: IChildren) => {
  const initialTags =
    // null;
    JSON.parse(readSessionStorage(BE_NFC_TAGS_CACHE) || "null") as ProductWithImagesDTO[] | null;
  const [beNfcTags, setBeNfcTags] = React.useState<ProductWithImagesDTO[] | null>(initialTags);

  const {
    requestTrigger: fetchAllNfcs,
    isLoading,
    response,
    axiosError
  } = useRequest<ItemsWithPaginations<ProductWithImagesDTO>>({ requestFn: getListOfAllNFCs });

  React.useEffect(() => {
    if (!!beNfcTags) return;

    fetchAllNfcs();
  }, [beNfcTags]);

  React.useEffect(() => {
    if (!response) return;

    setBeNfcTags(response.data);
    writeSessionStorage(BE_NFC_TAGS_CACHE, response.data);
  }, [response]);

  return (
    <GetNfcTagsContext.Provider value={{ fetchAllNfcs, isFetchingAllNFCs: isLoading, beNfcTags, axiosError }}>
      {children}
    </GetNfcTagsContext.Provider>
  );
};

export const useGetNfcTags = (): IGetNfcTagsContext => React.useContext(GetNfcTagsContext);
