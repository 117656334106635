import React, { HTMLAttributeAnchorTarget } from "react";

import "./Link.scss";

type LinkVariant = "primary-link" | "secondary-link" | "trietary-link" | "link";

export interface LinkProps {
  children: React.ReactNode;
  variant?: LinkVariant;
  target: HTMLAttributeAnchorTarget;
  url: string;
  className?: string;
  follow?: boolean;
  rel?: string;
  title?: string;
  externalControlHovered?: boolean;
  disabled?: boolean;
  fullSize?: boolean;
  uppercase?: boolean;
  action?(): void;
  onMouseEnter?(): void;
  onMouseLeave?(): void;
}

const Link = ({
  children,
  variant = "primary-link",
  url,
  target,
  follow,
  rel: externalRel,
  title,
  action = () => {},
  onMouseEnter = () => {},
  onMouseLeave = () => {},
  className,
  externalControlHovered,
  disabled,
  fullSize = false,
  uppercase
}: LinkProps) => {
  const rel = follow ? "dofollow" : `noopener noreferrer nofollow`;
  const classes = `g2n-link${className ? ` ${className}` : ""} ${variant}${externalControlHovered ? " hover" : ""}${
    uppercase ? " uppercase" : ""
  }${fullSize ? " full-size" : ""}${disabled ? ` disabled` : ""}`;

  return (
    <a
      className={classes}
      target={target}
      hrefLang="en"
      rel={externalRel ? externalRel : rel}
      href={url}
      onClick={action}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      title={title}
    >
      {children}
    </a>
  );
};

export default Link;
