import AuthLogging from "@components/common/LoadingPages/AuthLogging";
import { IChildren } from "@components/interfaces/IChildren";
import { useAuth } from "@contexts/AuthContext";
import { useInitialConfig } from "@contexts/InitialConfigContext";
import { useUser } from "@contexts/UserContext";
import { Navigate, useLocation } from "react-router-dom";
import { paths } from "src/app/paths";
import { APP_STAGE } from "src/config";

const AuthGuard = ({ children }: IChildren) => {
  const location = useLocation();

  const { user } = useUser();
  const { authState } = useAuth();
  const { defaultMainPath } = useInitialConfig();

  const entryPoints = [
    paths.authLogin,
    paths.authRegister,
    paths.authRecover,
    paths.authAction,
    paths.authRecoverConfirm
  ] as string[];
  const entryPointsWithDefault = [...entryPoints, paths.app];
  const entryPointsWithRegisterConfirm = [...entryPoints, paths.authRegisterConfirm];

  const isOnDefault = paths.app === location.pathname;
  const isOnAuthRoutes = entryPoints.includes(location.pathname);
  const isOnDefaultOrAuth = entryPointsWithDefault.includes(location.pathname);
  const isOnAuthWithRegisterConfirm = entryPointsWithRegisterConfirm.includes(location.pathname);

  const isOnRegisterConfirm = location.pathname === paths.authRegisterConfirm || location.pathname === paths.authAction;

  if (APP_STAGE === "dev" && isOnDefaultOrAuth) {
    return <Navigate to={defaultMainPath} />;
  }

  if (APP_STAGE === "dev" && !isOnDefaultOrAuth) {
    return <>{children}</>;
  }

  if (isOnDefault) {
    return <Navigate to={paths.authLogin} />;
  }

  if (authState === "logging") {
    return <AuthLogging />;
  }

  if (authState === "firstLogIn" && !isOnAuthRoutes) {
    return <Navigate to={paths.authLogin} />;
  }

  if (!!user && !user?.emailVerified && !isOnRegisterConfirm) {
    return <Navigate to={paths.authRegisterConfirm} />;
  }

  if (authState === "loggedIn" && isOnAuthWithRegisterConfirm && user?.emailVerified) {
    return <Navigate to={defaultMainPath} />;
  }

  return <>{children}</>;
};

export default AuthGuard;
