import React from "react";

import { IChildren } from "@components/interfaces/IChildren";
import { useGetCollections } from "./GetCollections.context";

interface IRulesCollectionsContext {
  isCollectionsEmpty: boolean;
}

const RulesCollectionsContext = React.createContext(null as any);

export const RulesCollectionsContextProvider = ({ children }: IChildren) => {
  const { collections } = useGetCollections();
  const isCollectionsEmpty = collections.length === 0;

  return <RulesCollectionsContext.Provider value={{ isCollectionsEmpty }}>{children}</RulesCollectionsContext.Provider>;
};

export const useRulesCollections = (): IRulesCollectionsContext => React.useContext(RulesCollectionsContext);
