import { paths, pathsHelpSlug } from "@app/paths";
import { faqQuestionIds } from "@constants/help/faqQuestions";
import { scrollToId } from "@utils/scrollToId";
import React from "react";
import { useParams } from "react-router-dom";

const QuestionSlug = () => {
  const slug = useParams();

  const questionSlugValue = slug[`${pathsHelpSlug}`];
  const isSlug = !!questionSlugValue;
  console.log("QuestionSlug ~ questionSlugValue:", questionSlugValue);
  const doSlugMatchesPreparedOnes = faqQuestionIds.includes(questionSlugValue as (typeof faqQuestionIds)[number]);

  React.useEffect(() => {
    if (!isSlug) return;

    if (!doSlugMatchesPreparedOnes) return;

    setTimeout(() => {
      scrollToId(questionSlugValue);
    }, 700);
  }, []);

  return <></>;
};

export default {
  path: paths.helpQuestionSlug,
  element: <QuestionSlug />,
  children: []
};
