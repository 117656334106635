import React from "react";
import { IChildren } from "@components/interfaces/IChildren";
import { GetNfcTagsProvider } from "./GetNfcTags.context";
import { SetNfcTagsProvider } from "./SetNfcTags.context";
import { RulesNfcTagsProvider } from "./RulesNfcTags.context";

const NfcTagsV2Provider = ({ children }: IChildren) => {
  return (
    <GetNfcTagsProvider>
      <SetNfcTagsProvider>
        <RulesNfcTagsProvider>{children}</RulesNfcTagsProvider>
      </SetNfcTagsProvider>
    </GetNfcTagsProvider>
  );
};

export default NfcTagsV2Provider;
