import React from "react";

import { IChildren } from "@components/interfaces/IChildren";
import { ProductWithImagesDTO } from "@api/hubApi/dtos.types";
import { useGetNfcTags } from "./GetNfcTags.context";
import { ValidationPattern, validateInput } from "@utils/validateInput";
import { useTranslation } from "react-i18next";
import { clearSessionStorageKey, readSessionStorage, writeSessionStorage } from "@utils/storage";

interface InitialNfcTag {
  amount: string;
  errorMsg: string;
  toShowError: boolean;
  touched: boolean;
  min: number;
  max: number;
  netPrice: string;
  name: string;
}

export type IFeNfcTag = {
  id: string;
} & InitialNfcTag;

interface ISetNfcTagsContext {
  feNfcTags: IFeNfcTag[] | null;
  updateTagAmount: (tagId: string, value: string) => void;
  showTagErrors: (tagId: string) => void;
  resetFeCache: () => void;
  resetFeNfcTags: () => void;
}

const initialNfcTag: InitialNfcTag = {
  amount: "",
  errorMsg: "",
  toShowError: false,
  touched: false,
  min: 0,
  max: 1000000,
  netPrice: "",
  name: ""
};

const validateTag = (value: string, min: number, max: number, t: any): string => {
  const validationSchema: ValidationPattern = {
    isInteger: true,
    isRequired: false,
    isPositive: true,
    maxNumber: max,
    minNumber: min,
    regexp: /^[0-9]+$/
  };

  const errors = validateInput(value, validationSchema, t);
  return errors;
};

const SetNfcTagsContext = React.createContext(null as any);

const FE_NFC_TAGS_CACHE = "fe_nfc_tags_cache";

const translateBeTagsToFe = (tags: ProductWithImagesDTO[]): IFeNfcTag[] => {
  return tags.map((tag) => ({
    id: tag.id,
    ...initialNfcTag,
    min: tag.minQuantity,
    max: tag.availableItems,
    name: tag.name,
    netPrice: tag.netPrice
  }));
};

export const SetNfcTagsProvider = ({ children }: IChildren) => {
  const { beNfcTags } = useGetNfcTags();

  const initialTags = JSON.parse(readSessionStorage(FE_NFC_TAGS_CACHE) || "null") as IFeNfcTag[] | null;
  const [feNfcTags, setFeNfcTags] = React.useState(initialTags);
  const { t: tValidation } = useTranslation("validations");

  const updateTagAmount = (tagId: string, value: string) => {
    const { min, max } = feNfcTags!.find((tag) => tag.id === tagId)!;

    const errors = validateTag(value, min, max, tValidation);

    setFeNfcTags((prev) =>
      prev!.map((prevTag) => {
        if (prevTag.id !== tagId) return prevTag;

        return {
          ...prevTag,
          amount: value,
          errorMsg: errors,
          toShowError: errors.length === 0 ? false : prevTag.toShowError,
          touched: true
        };
      })
    );
  };

  const showTagErrors = (tagId: string) => {
    setFeNfcTags((prev) =>
      prev!.map((prevTag) => {
        if (prevTag.id !== tagId) return prevTag;

        return {
          ...prevTag,
          toShowError: prevTag.errorMsg.length > 0
        };
      })
    );
  };

  const resetFeCache = () => clearSessionStorageKey(FE_NFC_TAGS_CACHE);

  const resetFeNfcTags = () => {
    setFeNfcTags(null);
    resetFeCache();
  };

  React.useEffect(() => {
    if (!!beNfcTags && !feNfcTags) {
      setFeNfcTags(translateBeTagsToFe(beNfcTags));
      writeSessionStorage(FE_NFC_TAGS_CACHE, translateBeTagsToFe(beNfcTags));
    }
  }, [beNfcTags, feNfcTags]);

  React.useEffect(() => {
    if (!feNfcTags || feNfcTags?.length === 0) return;

    writeSessionStorage(FE_NFC_TAGS_CACHE, feNfcTags);
  }, [feNfcTags]);

  return (
    <SetNfcTagsContext.Provider value={{ feNfcTags, updateTagAmount, showTagErrors, resetFeNfcTags, resetFeCache }}>
      {children}
    </SetNfcTagsContext.Provider>
  );
};

export const useSetNfcTags = (): ISetNfcTagsContext => React.useContext(SetNfcTagsContext);
