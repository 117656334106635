import { AxiosError } from "axios";

export const parseAxiosError = (e: AxiosError): string => {
  const status: number = e.response?.status || 1000;
  switch (status) {
    case 400:
      return "Could not send request. Try again later";
    case 401:
      return "User not authorized";
    case 403:
      return "This action is forbidden";
    case 500:
      return "Cannot perform an action due to data migration on the server. Try again later.";
    default:
      return "Try again later";
  }
};
