export const COLLECTION_NAME_MIN_LENGTH = 2;
export const COLLECTION_NAME_MAX_LENGTH = 64;

export const COLLECTION_DESCRIPTION_MIN_LENGTH = 1;
export const COLLECTION_DESCRIPTION_MAX_LENGTH = 1000;

export const COLLECTION_SHORT_DESCRIPTION_MIN_LENGTH = 1;
export const COLLECTION_SHORT_DESCRIPTION_MAX_LENGTH = 64;

export const PRODUCT_NAME_MIN_LENGTH = 2;
export const PRODUCT_NAME_MAX_LENGTH = 64;

export const PRODUCT_AMOUNT_MIN_NUMBER = 1;
export const DEFAULT_PRODUCT_AMOUNT_MAX_NUMBER = 9999999999999999999;

export const PRODUCT_URL_MIN_LENGTH = 1;
export const PRODUCT_URL_MAX_LENGTH = 512;

export const PRODUCT_DESCRIPTION_MIN_LENGTH = 2;
export const PRODUCT_DESCRIPTION_MAX_LENGTH = 512;

export const PARTNER_DESCRIPTION_MAX_LEHNGTH = 1000;

export const MIN_IMG_WIDTH = 560;
export const MIN_IMG_HEIGHT = 936;

export const BRAND_LOGO_IMG_MIN_WIDTH = 335;
export const BRAND_LOGO_IMG_MIN_HEIGHT = 335;
