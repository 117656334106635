import React from "react";
import { useTranslation } from "react-i18next";

import useTagHeight from "@hooks/useTagHeight";
import Group from "@components/arrangement/Group/Group";
import Link from "@components/navigation/Link/Link";

import { AccordionActiveState, AccordionTabContentProps } from "../Accordion.types";

import "./AccordionTabContent.scss";

const AccordionTabContent = ({ answer, links, variant = "default" }: AccordionTabContentProps) => {
  const { t } = useTranslation("faq");
  const { tagHeight, tagHeightRef } = useTagHeight();

  const getHeight = (variant: AccordionActiveState) => {
    if (variant === "default") {
      return 0;
    }
    if (variant === "hover") {
      return 0;
    }
    if (variant === "active") {
      return tagHeight;
    }
    throw new Error("Wrong variant");
  };

  const initialHeight = getHeight(variant);
  const [contentHeight, setContentHeight] = React.useState<number>(initialHeight);

  React.useEffect(() => {
    setContentHeight(getHeight(variant));
  }, [variant, tagHeight]);

  const classes = `accordion-tab-content accordion-tab-content-${variant}`;

  return (
    <div className={classes} style={{ height: contentHeight }}>
      <div ref={tagHeightRef}>
        <p className="p2 pb-xs-4 answer">{answer}</p>

        {(links || []).length > 0 && (
          <Group colGap={30} className="pb-xs-4">
            {links!.map(({ label, href }, index) => {
              const linkLabel = t(label as any);
              return (
                <Link
                  key={index}
                  url={href}
                  target="_blank"
                  variant="link"
                  className="underline black-1 link-hoverable"
                >
                  {linkLabel}
                </Link>
              );
            })}
          </Group>
        )}
      </div>
    </div>
  );
};

export default AccordionTabContent;
