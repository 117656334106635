import React from "react";

import { IChildren } from "@components/interfaces/IChildren";
import useRequest from "@hooks/useRequest";
import { DeliveryDTO, PaginatedDeliveryDTO, PaginationMeta } from "@api/hubApi/dtos.types";
import { getDeliveries } from "@api/hubApi/methods";
import { AxiosError } from "axios";
import useReadSessionStorage from "@hooks/useReadSessionStorage";
import useWriteSessionStorage from "@hooks/useWriteSessionStorage";
import { useUser } from "./UserContext";

interface IDeliveriesContext {
  isDeliveriesLoading: boolean;
  requestError: AxiosError;
  refetch: (queryParams?: PaginationMeta) => Promise<void>;
  deliveries: DeliveryDTO[] | null;
}

const DELIVERIES_SESSION_STORAGE_KEY = "deliveries_key";

const DeliveriesContext = React.createContext(null as any);

export const DeliveriesProvider = ({ children }: IChildren) => {
  const { user } = useUser();
  const {
    isLoading,
    axiosError,
    requestTrigger: fetchDeliveries,
    response: _response
  } = useRequest<PaginatedDeliveryDTO>({ requestFn: () => getDeliveries() });
  const deliveries = _response?.data || null;

  const deliveriesFromSessionStorage = useReadSessionStorage(DELIVERIES_SESSION_STORAGE_KEY) as DeliveryDTO[] | null;

  const storedDeliveries = deliveriesFromSessionStorage || deliveries;

  React.useEffect(() => {
    if (!!storedDeliveries) return;
    if (!user) return;

    fetchDeliveries();
  }, [storedDeliveries, user]);

  useWriteSessionStorage<DeliveryDTO[] | null>(DELIVERIES_SESSION_STORAGE_KEY, storedDeliveries);

  return (
    <DeliveriesContext.Provider
      value={{
        isDeliveriesLoading: isLoading,
        requestError: axiosError,
        refetch: fetchDeliveries,
        deliveries: storedDeliveries
      }}
    >
      {children}
    </DeliveriesContext.Provider>
  );
};

export const useDeliveries = (): IDeliveriesContext => React.useContext(DeliveriesContext);
