import React from "react";

import { IChildren } from "@components/interfaces/IChildren";
import { ICropperContext, useCropper } from "@contexts/ImageWithCropper/Cropper.context";
import useValidation, { ValidationFields } from "@hooks/useValidation";
import { useTranslation } from "react-i18next";

export interface IHocCropperContext {
  cropper: ICropperContext;
  validationFields: ValidationFields;
  onlyShowErrors: () => void;
}

const HocCropperContext = React.createContext(null as any);

export const HocCropperProvider = ({ children }: IChildren) => {
  const { t } = useTranslation("validations");
  const cropper = useCropper();

  const { validateOnlyErrors, validationFields, onlyShowErrors, resetToInitial } = useValidation({
    persistPolicy: "always-fresh-state"
  });

  const hocUploadCropperImage = (file: File) => {
    cropper.uploadCropperImage(file);
    validateOnlyErrors([t("finishCroppingFirst")]);
  };
  const hocCropImage = () => {
    cropper.onCrop();
    resetToInitial();
  };
  const hocResetCropper = () => {
    cropper.resetCropper();
    resetToInitial();
  };

  return (
    <HocCropperContext.Provider
      value={{
        cropper: {
          ...cropper,
          uploadCropperImage: hocUploadCropperImage,
          onCrop: hocCropImage,
          resetCropper: hocResetCropper
        },
        validationFields,
        onlyShowErrors
      }}
    >
      {children}
    </HocCropperContext.Provider>
  );
};

export const useHocCropper = (): IHocCropperContext => React.useContext(HocCropperContext);
