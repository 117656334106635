import React from "react";

import "./ModalCloseButton.scss";

interface ButtonProps {
  title?: string;
  disabled?: boolean;
  className?: string;
  onClick?(e: React.MouseEvent<HTMLButtonElement>): void;
}

const ModalCloseButton = ({ title = "", disabled = false, className = "", onClick = () => {} }: ButtonProps) => {
  const buildClass = `modal-close-button ${className || ""}`;

  return (
    <button title={title} disabled={disabled} onClick={onClick} className={buildClass}>
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_466_8009)">
          <path
            d="M26.5997 13.4L20 19.9997M20 19.9997L13.4004 26.5993M20 19.9997L13.4004 13.4M20 19.9997L26.5997 26.5993M20 38.6663C17.5487 38.6663 15.1214 38.1835 12.8566 37.2454C10.5919 36.3073 8.53407 34.9324 6.80071 33.199C5.06735 31.4656 3.69238 29.4078 2.75429 27.1431C1.8162 24.8784 1.33337 22.451 1.33337 19.9997C1.33337 17.5483 1.8162 15.121 2.75429 12.8563C3.69238 10.5915 5.06735 8.53371 6.80071 6.80035C8.53407 5.06699 10.5919 3.69201 12.8566 2.75392C15.1214 1.81584 17.5487 1.33301 20 1.33301C24.9507 1.33301 29.6987 3.29967 33.1994 6.80035C36.7 10.301 38.6667 15.049 38.6667 19.9997C38.6667 24.9504 36.7 29.6983 33.1994 33.199C29.6987 36.6997 24.9507 38.6663 20 38.6663Z"
            stroke="#494845"
          />
        </g>
        <defs>
          <clipPath id="clip0_466_8009">
            <rect width="40" height="40" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </button>
  );
};

export default ModalCloseButton;
