import React from "react";
import { useEffect } from "react";

const useReadSessionStorage = <T,>(key: string, options?: { deps: any[] }): T | null => {
  const [stored, setStored] = React.useState<T | null>(null);

  useEffect(() => {
    try {
      const item = sessionStorage.getItem(key) || "null";
      setStored(JSON.parse(item));
    } catch (error) {
      console.log(`Error saving value for key ${key} to sessionStorage`);
      setStored(null);
    }
  }, [key, ...(options?.deps || [])]);

  return stored;
};

export default useReadSessionStorage;
